<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <!-- Activator -->
    <template #activator="bind">
      <slot
        name="activator"
        v-bind="{
          ...bind,
          loading: $apollo.queries.me.loading,
          total: me.notificationsCount,
          totalUnread: me.unreadNotificationsCount,
          types: types
        }"
      />
    </template>

    <notifications-card ref="card" dialog draggable @close="dialog = false" />
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag'
import NotificationUtils from '@/utils/notification.js'

import { NOTIFICATION_COUNTS as FRAGMENT_NOTIFICATION_COUNTS } from '@/graphql/fragments.js'

import NotificationsCard from './NotificationsCard'

export default {
  name: 'NotificationsDialog',

  components: {
    NotificationsCard
  },

  apollo: {
    me: {
      query: gql`
        query me {
          me {
            id

            ...NotificationCounts
          }
        }
        ${FRAGMENT_NOTIFICATION_COUNTS}
      `,
      // fetchPolicy: 'network-only',
      pollInterval: 15000
    }
  },

  data() {
    return {
      dialog: false,
      me: {}
    }
  },

  computed: {
    types() {
      return NotificationUtils.buildNotificationTypes(this.me)
    }
  },

  watch: {
    dialog(value) {
      if (value) {
        if (this.$refs.card) {
          this.$refs.card.refetch()
        }
      }
    }
  }
}
</script>
