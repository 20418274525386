<template>
  <v-avatar>
    <img v-if="gravatar" :src="src" alt="Avatar" />
    <v-icon v-else>$user</v-icon>
  </v-avatar>
</template>

<script>
import _ from 'lodash'
import md5 from 'md5'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileAvatar',

  props: {
    email: {
      type: String,
      required: false,
      default: null
    },

    gravatar: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    internalEmail() {
      return this.email || _.get(this.user, 'email', '')
    },

    hash() {
      // create hash from user-email. See: https://de.gravatar.com/site/implement/hash/
      return md5(this.internalEmail.trim().toLowerCase())
    },

    src() {
      return `https://gravatar.com/avatar/${this.hash}?d=mp`
    }
  }
}
</script>
