import Vue from 'vue'

Vue.filter('tradeInProcessStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'IN_PROGRESS':
      return 'In Arbeit'
    case 'OK':
      return 'Abgeschlossen'
    case 'CANCELED':
      return 'Abgebrochen'
    default:
      return value
  }
})

Vue.filter('tradeInProcessHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    // case 'CREATED':
    //   return 'Vorgang erstellt'
    // case 'ISSUE_CLOSED':
    //   return 'Vorgang geschlossen'
    // case 'ISSUE_OPEN':
    //   return 'Vorgang wieder geöffnet'
    // case 'ISSUE_RECEIVED_EMAIL':
    //   return 'Nachricht eingegangen'
    // case 'NEW_ISSUE_RECEIVED_EMAIL':
    //   return 'Neue Nachricht eingegangen'
    // case 'ISSUE_CONTACT_EMAIL':
    //   return 'Nachricht versendet'

    default:
      return undefined // return default event text
  }
})
