import moment from 'moment'

const validator = {
  message(field) {
    return field + ' muss in der Vergangenheit liegen.'
  },
  validate(value) {
    const today = moment()
    return moment(value).isBefore(today)
  }
}
export default validator
