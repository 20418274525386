<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <!-- Activator -->
    <template #activator="bind">
      <slot
        name="activator"
        v-bind="{
          ...bind,
          loading: $apollo.queries.todosCount.loading,
          total: total
        }"
      />
    </template>

    <card-base
      dialog
      draggable
      title="Aufgaben"
      :loading="$apollo.queries.todos.loading"
      :disabled="$apollo.queries.todos.loading"
      @close="dialog = false"
    >
      <template #toolbarIconActions>
        <todo-save-dialog @submit:success="refetch">
          <template #activator="{ on }">
            <button-tooltip
              tooltip-text="Aufgabe erstellen"
              icon="$action-add"
              v-on="on"
            />
          </template>
        </todo-save-dialog>
      </template>

      <todos-list
        :todos="todos"
        :loading="$apollo.queries.todos.loading"
        @reload="refetch"
      />
    </card-base>
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag'
import { mapGetters } from 'vuex'

import { CardBase } from '@freshsolutions/fresh-vue-cards'
import { ButtonTooltip } from '@freshsolutions/fresh-vue'
import TodoSaveDialog from './TodoSaveDialog'
import TodosList from './TodosList'

export default {
  name: 'TodosDialog',

  components: {
    CardBase,
    ButtonTooltip,
    TodoSaveDialog,
    TodosList
  },

  apollo: {
    todosCount: {
      query: gql`
        query todosCount {
          todosCount(open: true)
        }
      `,
      fetchPolicy: 'network-only'
    },

    todos: {
      query: gql`
        query todos {
          todos(open: true, orderBy: [{ column: CREATED_AT, order: DESC }]) {
            id

            title
            description

            created_at
            created_by {
              id
              name
            }
            updated_at
          }
        }
      `,
      skip() {
        return !this.dialog
      },
      fetchPolicy: 'network-only'
    }
  },

  data() {
    return {
      todos: [],
      todosCount: null,
      dialog: false
    }
  },

  computed: {
    ...mapGetters('auth', {
      authUser: 'user'
    }),

    total() {
      return this.todosCount
    }
  },

  methods: {
    refetch() {
      this.$apollo.queries.todosCount.refetch()
      this.$apollo.queries.todos.refetch()
    }
  }
}
</script>
