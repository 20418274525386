<template>
  <v-select :items="items" :label="label" v-bind="$attrs" v-on="$listeners">
    <!-- Selected Item -->
    <template #selection="{ item }">
      <v-row align="center" no-gutters>
        <v-col class="shrink">
          <user-status small :status="item.value" icon-only />
        </v-col>
        <v-col class="grow ml-4">
          {{ item.text }}
        </v-col>
      </v-row>
    </template>

    <!-- items -->
    <template #item="{ item }">
      <v-row align="center" no-gutters>
        <v-col class="shrink">
          <user-status small :status="item.value" />
        </v-col>
        <v-col class="grow ml-4">
          {{ item.text }}
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>

<script>
import UserStatus from '@/components/UserStatus'

export default {
  name: 'SelectUserstatus',

  components: {
    UserStatus
  },

  props: {
    label: {
      type: String,
      default: 'Status'
    }
  },

  data() {
    const filterStatus = this.$options.filters.userStatus

    return {
      items: [
        { text: filterStatus('ONLINE'), value: 'ONLINE' },
        { text: filterStatus('BUSY'), value: 'BUSY' },
        { text: filterStatus('OFFLINE'), value: 'OFFLINE' }
      ]
    }
  }
}
</script>
