import colors from 'vuetify/lib/util/colors'
import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'freshcarflat-icon-fahrzeug': {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },

  // Status
  'freshcarflat-status-ANGEBOT': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature',
      color: colors.grey.lighten1
    }
  },
  'freshcarflat-status-AUFHEBUNG': {
    component: IconFontawesome,
    props: {
      icon: 'xmark',
      color: 'error'
    }
  },
  'freshcarflat-status-AUFHEBUNG_PLANNED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'xmark',
      transform: 'down-8 right-8',
      color: 'error',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-8 down-7 right-7',
          color: 'info'
        }
      ]
    }
  },
  'freshcarflat-status-WARTEN_EVB': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      color: 'primary',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-8 down-7 right-7',
          color: 'info'
        }
      ]
    }
  },
  'freshcarflat-status-WARTEN_EVB_VERLAENGERUNG': {
    // TODO add mask for left icon ('forward')
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      color: 'primary',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'forward',
          transform: 'shrink-8 down-7 left-7',
          color: 'info'
        },
        {
          icon: 'clock',
          transform: 'shrink-8 down-7 right-7',
          color: 'info'
        }
      ]
    }
  },
  'freshcarflat-status-ANTRAG_VERSENDET_VERLAENGERUNG': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'check',
      color: 'success',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'forward',
          transform: 'shrink-8 down-8 right-8',
          color: 'info'
        }
      ]
    }
  },
  'freshcarflat-status-ANTRAG_VERSENDET': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'freshcarflat-status-ERROR': {
    component: IconFontawesome,
    props: {
      icon: 'triangle-exclamation',
      color: 'error'
    }
  },
  'freshcarflat-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question',
      color: 'warning'
    }
  },

  // Actions
  'freshcarflat-action-evb-eintragen': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  'freshcarflat-action-fahrzeug-verlaengern': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6',
          color: 'info'
        }
      ]
    }
  },
  'freshcarflat-action-fahrzeug-aufheben': {
    component: IconFontawesome,
    props: {
      icon: 'xmark',
      color: 'error'
    }
  },
  'freshcarflat-action-fahrzeug-aufhebung-planen': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'xmark',
      transform: 'down-6 right-6',
      color: 'error',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6',
          color: 'info'
        }
      ]
    }
  },
  'freshcarflat-action-fahrzeug-aufhebung-planen-cancel': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'xmark',
      transform: 'down-6 right-6',
      color: 'error',
      iconChildren: [
        {
          icon: 'ban',
          transform: 'shrink-6 down-6 right-6',
          color: 'info'
        }
      ]
    }
  }
}
