import _ from 'lodash'
import Vue from 'vue'
import FilecenterApi from '@/api/filecenter.js'
import ExceptionUtils from '@/utils/exceptions.js'

export default {
  namespaced: true,

  state: {
    files: [],
    groups: [],
    filecenter: [],

    // states
    initializing: false
  },

  mutations: {
    setFilecenter(state, filecenter) {
      state.filecenter = filecenter
    },

    setInitializing(state, initializing) {
      state.initializing = initializing
    },

    // Files
    setFiles(state, files) {
      state.files = files
    },

    addFile(state, file) {
      state.files.push(file)
    },

    addFileToFilecenter(state, { file, groups }) {
      const targetGroups = groups

      state.filecenter.forEach((group) => {
        // Add this file to the group, if the file should be in this group
        if (targetGroups.includes(group.id)) {
          group.files.push(file.id)
        }
      })
    },

    updateFile(state, file) {
      const index = state.files.findIndex((item) => item.id === file.id)
      Vue.set(state.files, index, file)
    },

    updateFileInFilecenter(state, { file, groups }) {
      const targetGroups = groups

      state.filecenter.forEach((group) => {
        // Remove this file from the group, if the file shouldn't be in this group
        if (!targetGroups.includes(group.id)) {
          Vue.set(
            group,
            'files',
            group.files.filter((fileId) => fileId !== file.id)
          )
        }
        // Add this file to the group, if the file should be in this group (and is not already)
        else if (!group.files.some((fileId) => fileId === file.id)) {
          group.files.push(file.id)
        }
      })
    },

    removeFile(state, fileId) {
      const index = state.files.findIndex((item) => item.id === fileId)
      state.files.splice(index, 1)
    },

    removeFileFromFilecenter(state, fileId) {
      state.filecenter.forEach((group) => {
        _.remove(group.files, (item) => item === fileId)
      })
    },

    // Groups
    setGroups(state, groups) {
      state.groups = groups
    },

    addGroup(state, group) {
      state.groups.push(group)
    },

    addGroupToFilecenter(state, group) {
      state.filecenter.push({
        id: group.id,
        files: []
      })
    },

    updateGroup(state, group) {
      const index = state.groups.findIndex((item) => item.id === group.id)
      Vue.set(state.groups, index, group)
    },

    removeGroup(state, groupId) {
      const index = state.groups.findIndex((item) => item.id === groupId)
      state.groups.splice(index, 1)
    },

    removeGroupFromFilecenter(state, groupId) {
      const index = state.filecenter.findIndex((item) => item.id === groupId)
      state.filecenter.splice(index, 1)
    }
  },

  actions: {
    loadFilecenter({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setInitializing', true)

        FilecenterApi.files
          .index()
          .then((files) => {
            commit('setFiles', files)
            return FilecenterApi.groups.index()
          })
          .then((groups) => {
            commit('setGroups', groups)
            return FilecenterApi.index()
          })
          .then((filecenter) => {
            commit('setFilecenter', filecenter)
            commit('setInitializing', false)
            resolve(true)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('setFiles', [])
            commit('setGroups', [])
            commit('setFilecenter', [])
            commit('setInitializing', false)
            reject(err)
          })
      })
    },

    // Groups

    storeGroup({ commit }, { name, description, partner, roles }) {
      return new Promise((resolve, reject) => {
        FilecenterApi.groups
          .store({ name, description, partner, roles })
          .then((group) => {
            commit('addGroup', group)
            commit('addGroupToFilecenter', group)
            resolve(group)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    updateGroup({ commit }, { id, name, description, partner, roles }) {
      return new Promise((resolve, reject) => {
        FilecenterApi.groups
          .update({ id, name, description, partner, roles })
          .then((group) => {
            commit('updateGroup', group)
            resolve(group)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    moveGroup({ commit, getters }, { id, order }) {
      return new Promise((resolve, reject) => {
        const group = getters.groupById(id)

        FilecenterApi.groups
          .update({
            id: group.id,
            name: group.name,
            description: group.description,
            order: order,
            partner: group.partner,
            roles: group.roles
          })
          .then((group) => {
            commit('updateGroup', group)
            resolve(group)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    destroyGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        FilecenterApi.groups
          .destroy(id)
          .then(() => {
            commit('removeGroup', id)
            commit('removeGroupFromFilecenter', id)
            resolve()
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    // Files

    storeFile({ commit }, { name, description, partner, roles, groups, file }) {
      return new Promise((resolve, reject) => {
        FilecenterApi.files
          .store({ name, description, partner, roles, groups, file })
          .then((file) => {
            commit('addFile', file)
            commit('addFileToFilecenter', { file, groups })
            resolve(file)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    updateFile({ commit }, { id, name, description, partner, roles, groups }) {
      return new Promise((resolve, reject) => {
        FilecenterApi.files
          .update({ id, name, description, partner, roles, groups })
          .then((file) => {
            commit('updateFile', file)
            commit('updateFileInFilecenter', { file, groups })
            resolve(file)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    destroyFile({ commit }, id) {
      return new Promise((resolve, reject) => {
        FilecenterApi.files
          .destroy(id)
          .then(() => {
            commit('removeFile', id)
            commit('removeFileFromFilecenter', id)
            resolve()
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    }
  },

  getters: {
    filecenter: (state, getters) => {
      // Loop through the filecenter array (where the relationships between groups and files are stored) and replace the ids with the values
      // This has the advantage, that we don't need to manually update the data in this array (except for adding / removing a new group or file). We just need to manipulate them in the files or groups arrays

      return state.filecenter.map((group) => {
        return {
          ...getters.groupById(group.id),
          files: group.files
            .map((fileId) => getters.fileById(fileId))
            .filter((file) => !!file)
        }
      })
    },
    groupById: (state) => (id) => state.groups.find((group) => group.id === id),
    fileById: (state) => (id) => state.files.find((file) => file.id === id),
    groupsOfFile: (state) => (id) =>
      state.filecenter
        .filter((group) => group.files.some((fileId) => fileId === id))
        .map((item) => item.id)
  }
}
