function toDate(value) {
  if (value) {
    const split = value.split(':')
    const hours = split[0]
    const minutes = split[1]

    const date = new Date()
    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
  } else {
    return null
  }
}

const validator = {
  message: '{_field_} muss mindestens {_min_} sein.',
  params: ['min'],
  validate(value, { min }) {
    const time = toDate(value)
    const timeMin = toDate(min)

    if (time && timeMin) {
      return time >= timeMin
    } else {
      return true
    }
  }
}
export default validator
