import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import FreshcarflatApi from '@/api/freshcarflat.js'
import ExceptionUtils from '@/utils/exceptions.js'

export default {
  namespaced: true,

  state: {
    status: 'OK',

    // Antragsdaten
    id: null,
    autohaus: null,
    verkaeufer: null,
    verkaeuferOriginal: null,
    versicherungsnehmer: null,
    fahrzeuge: [],

    // Other stuff
    fakeIdCounter: -1
  },

  mutations: {
    updateField,

    startLoading(state) {
      state.status = 'LOADING'
    },

    finishLoading(state, ok) {
      if (ok) {
        state.status = 'OK'
      } else {
        state.status = 'ERROR'
      }
    },

    addFahrzeug(state, fahrzeug) {
      state.fahrzeuge.push(fahrzeug)
    },

    updateFahrzeug(state, { index, fahrzeug }) {
      Vue.set(state.fahrzeuge, index, fahrzeug)
    },

    deleteFahrzeug(state, index) {
      state.fahrzeuge.splice(index, 1)
    },

    setId(state, id) {
      state.id = id
    },
    setAutohaus(state, autohaus) {
      state.autohaus = autohaus
    },
    setVerkaeufer(state, verkaeufer) {
      state.verkaeufer = verkaeufer
    },
    setVerkaeuferOriginal(state, verkaeufer) {
      state.verkaeuferOriginal = verkaeufer
    },
    setVersicherungsnehmer(state, versicherungsnehmer) {
      state.versicherungsnehmer = versicherungsnehmer
    },
    setFahrzeuge(state, fahrzeuge) {
      state.fahrzeuge = fahrzeuge
    },

    nextFakeIdCounter(state) {
      state.fakeIdCounter--
    }
  },

  actions: {
    startLoading({ commit }) {
      commit('startLoading')
    },

    finishLoading({ commit }, ok) {
      commit('finishLoading', ok)
    },

    loadAntrag({ commit, dispatch, rootState, rootGetters }, id) {
      commit('startLoading')

      return new Promise((resolve, reject) => {
        dispatch('initAutohausAndVerkaeufer')
          .then(() => {
            FreshcarflatApi.loadAntrag(id)
              .then((antrag) => {
                commit('setId', antrag.id)
                commit('setAutohaus', antrag.autohaus)
                // We will override the verkaeufer from the antrag to the currently logged in one (if there is one and if it isn't a user with the permission ('freshcarflat.create.antrag.all'))
                if (
                  rootState.auth.verkaeufer &&
                  !rootGetters['auth/hasPermission'](
                    'freshcarflat.create.antrag.all'
                  )
                ) {
                  commit('setVerkaeufer', rootState.auth.verkaeufer)
                } else {
                  commit('setVerkaeufer', antrag.verkaeufer)
                }
                commit('setVerkaeuferOriginal', antrag.verkaeufer)
                commit(
                  'setVersicherungsnehmer',
                  Object.assign({}, antrag.versicherungsnehmer, {
                    type:
                      antrag.versicherungsnehmer.anrede === '3'
                        ? 'FIRMA'
                        : 'PRIVAT'
                  })
                )
                commit('setFahrzeuge', antrag.fahrzeuge)
                commit('finishLoading', true)
                resolve(id)
              })
              .catch((err) => {
                // Google Analytics
                ExceptionUtils.logToAnalytics(err)

                commit('setId', null)
                commit('setAutohaus', null)
                commit('setVerkaeufer', null)
                commit('setVerkaeuferOriginal', null)
                commit('setVersicherungsnehmer', null)
                commit('setFahrzeuge', [])
                commit('finishLoading', false)
                reject()
              })
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('setId', null)
            commit('setAutohaus', null)
            commit('setVerkaeufer', null)
            commit('setVerkaeuferOriginal', null)
            commit('setVersicherungsnehmer', null)
            commit('setFahrzeuge', [])
            commit('finishLoading', false)
            reject()
          })
      })
    },

    handleAntrag(
      { commit, dispatch, state },
      { fahrzeug, versicherungsnehmer }
    ) {
      if (versicherungsnehmer) {
        commit('setVersicherungsnehmer', versicherungsnehmer)
      }

      if (!state.id) {
        // Antrag wasn't saved yet -> Save it
        return dispatch('storeAntrag')
      } else {
        // Antrag already saved -> Just update it
        return dispatch('updateAntrag', (fahrzeug || {}).id)
      }
    },

    storeAntrag({ commit, state }) {
      commit('startLoading')

      return new Promise((resolve, reject) => {
        FreshcarflatApi.storeAntrag({
          autohaus: state.autohaus,
          verkaeufer: state.verkaeufer,
          versicherungsnehmer: state.versicherungsnehmer,
          fahrzeug: state.fahrzeuge
        })
          .then((id) => {
            commit('setId', id)
            commit('finishLoading', true)
            resolve(id)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('setId', null)
            commit('finishLoading', false)
            reject()
          })
      })
    },

    updateAntrag({ commit, state }, fahrzeugId) {
      commit('startLoading')

      return new Promise((resolve, reject) => {
        FreshcarflatApi.updateAntrag({
          antragId: state.id,
          fahrzeugId: fahrzeugId,
          autohaus: state.autohaus,
          verkaeufer: state.verkaeufer,
          versicherungsnehmer: state.versicherungsnehmer
        })
          .then(() => {
            commit('finishLoading', true)
            resolve()
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('finishLoading', false)
            reject()
          })
      })
    },

    handleFahrzeug({ dispatch }, { fahrzeug, prozess }) {
      if (!fahrzeug.id || fahrzeug.id < 0) {
        // Fahrzeug wasn't saved yet -> Save it
        return dispatch('storeFahrzeug', { fahrzeug })
      } else {
        // Fahrzeug already saved -> Just update it
        return dispatch('updateFahrzeug', { fahrzeug, prozess })
      }
    },

    storeFahrzeug({ commit, state }, { fahrzeug }) {
      return new Promise((resolve, reject) => {
        let index = 0

        // check if the fahrzeug doesn't have an id (can happen if it already was saved but with errors)
        if (!fahrzeug.id) {
          // add fahrzeug to list (so we can show current state)
          index = state.fahrzeuge.length

          commit('nextFakeIdCounter')
          commit(
            'addFahrzeug',
            Object.assign({}, fahrzeug, {
              /* Random negative index, to indicate that it wasn't saved yet */
              id: state.fakeIdCounter,
              status: 'LOADING'
            })
          )
        } else {
          // update fahrzeug in the list (so we can show current state)
          index = state.fahrzeuge.findIndex((item) => item.id === fahrzeug.id)

          commit('updateFahrzeug', {
            index: index,
            fahrzeug: Object.assign({}, fahrzeug, {
              status: 'LOADING'
            })
          })
        }

        FreshcarflatApi.storeFahrzeug(state.id, fahrzeug)
          .then((fahrzeug) => {
            // Fahrzeug was successfully added, so we update it with the data from api.
            commit('updateFahrzeug', {
              index: index,
              fahrzeug: Object.assign({}, state.fahrzeuge[index], fahrzeug)
            })
            commit('finishLoading', true)
            resolve(fahrzeug)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            // There was an error while adding the Fahrzeug, so we will add them to the status of the Fahrzeug.
            commit('updateFahrzeug', {
              index: index,
              fahrzeug: Object.assign({}, state.fahrzeuge[index], {
                status: 'ERROR'
              })
            })
            commit('finishLoading', false)
            reject()
          })
      })
    },

    updateFahrzeug({ commit, state }, { fahrzeug, prozess }) {
      return new Promise((resolve, reject) => {
        // update fahrzeug in the list (so we can show current state)
        const index = state.fahrzeuge.findIndex(
          (item) => item.id === fahrzeug.id
        )

        commit('updateFahrzeug', {
          index: index,
          fahrzeug: Object.assign({}, state.fahrzeuge[index], {
            status: 'LOADING'
          })
        })

        FreshcarflatApi.updateFahrzeug({ fahrzeug, prozess })
          .then((fahrzeug) => {
            // Fahrzeug was successfully updated, so we update it with the data from api.
            commit('updateFahrzeug', {
              index: index,
              fahrzeug: Object.assign({}, state.fahrzeuge[index], fahrzeug)
            })
            commit('finishLoading', true)
            resolve(fahrzeug)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            // There was an error while adding the Fahrzeug, so we will add them to the status of the Fahrzeug.
            commit('updateFahrzeug', {
              index: index,
              fahrzeug: Object.assign({}, state.fahrzeuge[index], {
                status: 'ERROR'
              })
            })
            commit('finishLoading', false)
            reject()
          })
      })
    },

    abschliessenFahrzeug({ commit, state }, { id }) {
      // update fahrzeug in the list (so we can show current state)
      const index = state.fahrzeuge.findIndex((item) => item.id === id)

      commit('updateFahrzeug', {
        index: index,
        fahrzeug: Object.assign({}, state.fahrzeuge[index], {
          status: 'LOADING'
        })
      })

      return new Promise((resolve, reject) => {
        FreshcarflatApi.abschliessenFahrzeug(id)
          .then((fahrzeug) => {
            // Fahrzeug was successfully updated, so we update it with the data from api.
            commit('updateFahrzeug', {
              index: index,
              fahrzeug: Object.assign({}, state.fahrzeuge[index], fahrzeug)
            })
            commit('finishLoading', true)

            resolve(fahrzeug)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            // There was an error while adding the Fahrzeug, so we will add them to the status of the Fahrzeug.
            commit('updateFahrzeug', {
              index: index,
              fahrzeug: Object.assign({}, state.fahrzeuge[index], {
                status: 'ERROR'
                // errors: err.errors
              })
            })
            commit('finishLoading', false)
            reject()
          })
      })
    },

    verlaengernFahrzeug(
      { commit, state },
      {
        id,
        laufzeit,
        vertragsablauf,
        partnernummer,
        vertragsnummer,
        kfzkennzeichen
      }
    ) {
      // update fahrzeug in the list (so we can show current state)
      const index = state.fahrzeuge.findIndex((item) => item.id === id)

      commit('updateFahrzeug', {
        index: index,
        fahrzeug: Object.assign({}, state.fahrzeuge[index], {
          status: 'LOADING'
        })
      })

      return new Promise((resolve, reject) => {
        FreshcarflatApi.verlaengernFahrzeug({
          id,
          laufzeit,
          vertragsablauf,
          partnernummer,
          vertragsnummer,
          kfzkennzeichen
        })
          .then((fahrzeug) => {
            // Fahrzeug was successfully updated, so we update it with the data from api.
            commit('updateFahrzeug', {
              index: index,
              fahrzeug: Object.assign({}, state.fahrzeuge[index], fahrzeug)
            })
            commit('finishLoading', true)

            resolve(fahrzeug)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            // There was an error while adding the Fahrzeug, so we will add them to the status of the Fahrzeug.
            commit('updateFahrzeug', {
              index: index,
              fahrzeug: Object.assign({}, state.fahrzeuge[index], {
                status: 'ERROR'
                // errors: err.errors
              })
            })
            commit('finishLoading', false)
            reject()
          })
      })
    },

    initAutohausAndVerkaeufer({ commit, rootState }) {
      return new Promise((resolve) => {
        commit('setAutohaus', rootState.auth.autohaus)
        commit('setVerkaeufer', rootState.auth.verkaeufer)

        resolve()
      })
    },

    reset({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('setId', null)
        commit('setAutohaus', null)
        commit('setVerkaeufer', null)
        commit('setVerkaeuferOriginal', null)
        commit('setVersicherungsnehmer', null)
        commit('setFahrzeuge', [])

        dispatch('initAutohausAndVerkaeufer')
          .then(() => {
            commit('finishLoading', true)
            resolve()
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('finishLoading', false)
            reject()
          })
      })
    },

    resetFahrzeuge({ commit }) {
      commit('setFahrzeuge', [])
    }
  },

  getters: {
    getField,
    status: (state) => state.status,
    isLoading: (state) => state.status === 'LOADING',
    isError: (state) => state.status === 'ERROR',
    isFirma: (state) => (state.versicherungsnehmer || {}).type === 'FIRMA',
    isPrivat: (state) => (state.versicherungsnehmer || {}).type === 'PRIVAT'
  }
}
