<template>
  <v-list-item>
    <v-list-item-icon>
      <note-severity-icon :severity="note.severity" show-tooltip />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-if="note.title">
        {{ note.title }}
      </v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        {{ note.description | emptyString }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { NoteSeverityIcon } from '@freshsolutions/fresh-vue-notes'

export default {
  name: 'DataListItemNote',

  components: {
    NoteSeverityIcon
  },

  props: {
    note: {
      type: Object,
      required: true
    }
  }
}
</script>
