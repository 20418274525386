import Vue from 'vue'

Vue.filter('feedbackStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'WAIT':
      return 'Warten auf Rückmeldung'
    case 'RECEIVED':
      return 'Rückmeldung erhalten'

    default:
      return value
  }
})

Vue.filter('feedbackTypename', function (value) {
  if (!value) return ''

  switch (value) {
    // selfdisclosure
    case 'Selfdisclosure':
    case 'App\\Models\\fahrzeugmarkt\\Selfdisclosure':
      return 'Selbstauskunft'

    // leadfeedback
    case 'Leadfeedback':
    case 'App\\Models\\leads\\Leadfeedback':
      return 'Lead-Bewertung'

    // claimstatement
    case 'Claimstatement':
    case 'App\\Models\\schadenmeldung\\Claimstatement':
      return 'Stellungnahme'

    // formrequest
    case 'Formrequest':
    case 'App\\Models\\Formrequest':
      return 'Formular'

    default:
      return value
  }
})
