import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  editor: {
    component: IconFontawesome,
    props: {
      icon: 'indent'
    }
  },

  // Formats
  'format-bold': {
    component: IconFontawesome,
    props: {
      icon: 'bold'
    }
  },
  'format-italic': {
    component: IconFontawesome,
    props: {
      icon: 'italic'
    }
  },
  'format-strikethrough': {
    component: IconFontawesome,
    props: {
      icon: 'strikethrough'
    }
  },
  'format-underline': {
    component: IconFontawesome,
    props: {
      icon: 'underline'
    }
  },
  'format-list-bulleted': {
    component: IconFontawesome,
    props: {
      icon: 'list-ul'
    }
  },
  'format-link': {
    component: IconFontawesome,
    props: {
      icon: 'link'
    }
  },
  'format-horizontal-rule': {
    component: IconFontawesome,
    props: {
      icon: 'minus'
    }
  }
}
