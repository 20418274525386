<template>
  <v-list nav dense>
    <!-- Hilfe -->
    <v-list-item
      v-if="knowledgeBaseUrl"
      :href="knowledgeBaseUrl"
      target="_blank"
    >
      <v-list-item-action>
        <v-icon small>$action-help</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title> Hilfe aufrufen </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon x-small>$external-link</v-icon>
      </v-list-item-action>
    </v-list-item>

    <!-- Feedback -->
    <v-list-item v-if="feedbackUrl" @click="openFeedback">
      <v-list-item-action>
        <v-icon small>$action-feedback</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title> Feedback geben </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon x-small>$external-link</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import RouterUtils from '@/utils/router.js'

export default {
  name: 'AppNavigationListQuickactions',

  computed: {
    ...mapState(['client']),

    knowledgeBaseUrl() {
      return RouterUtils.getKnowledgeBaseUrl(this.$route)
    },

    feedbackUrl() {
      switch (this.client) {
        case 'freshforfinance':
          return 'https://forms.office.com/r/DW3hT9VdHf'
        case 'fahrzeugmarkt.online':
          return 'https://forms.office.com/r/pNzeVisxAN'
        default:
          return null
      }
    }
  },

  methods: {
    openFeedback() {
      if (this.feedbackUrl) {
        window.open(
          this.feedbackUrl,
          '_blank',
          'width=500,height=900,top=200,left=200'
        )
      }
    }
  }
}
</script>
