<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>$meeting</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ meeting.type | emptyString }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ meeting.period | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ meeting.location | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text-wrap">
        {{ meeting.message | emptyString }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemMeeting',

  props: {
    meeting: {
      type: Object,
      required: true
    }
  }
}
</script>
