import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', function (value) {
  if (!value) return ''

  const filter = Vue.filter('moment')
  return filter(value, 'DD.MM.YYYY')
})

Vue.filter('time', function (value) {
  if (!value) return ''

  const filter = Vue.filter('moment')
  return filter(value, 'HH:mm') + ' Uhr'
})

Vue.filter('dateAndTime', function (value) {
  if (!value) return ''

  const filterDate = Vue.filter('date')
  const filterTime = Vue.filter('time')

  return filterDate(value) + ' - ' + filterTime(value)
})

Vue.filter('deDateMY', function (value) {
  if (value) {
    return moment(String(value)).format('MM/YYYY')
  } else {
    return '-'
  }
})

Vue.filter('daterange', function (range) {
  if (!(range || {}).start || !(range || {}).end) return ''

  const filterDate = Vue.filter('date')

  return filterDate(range.start) + ' - ' + filterDate(range.end)
})

Vue.filter('day', function (value) {
  if (!value) return ''

  switch (value) {
    case 0:
    case 'MONDAY':
      return 'Montag'
    case 1:
    case 'TUESDAY':
      return 'Dienstag'
    case 2:
    case 'WEDNESDAY':
      return 'Mittwoch'
    case 3:
    case 'THURSDAY':
      return 'Donnerstag'
    case 4:
    case 'FRIDAY':
      return 'Freitag'
    case 5:
    case 'SATURDAY':
      return 'Samstag'
    case 6:
    case 'SUNDAY':
      return 'Sonntag'
    default:
      return 'Unbekannt'
  }
})

Vue.filter('eventPeriod', function (value, mode = 'DATE_AND_TIME') {
  if (!value) return ''

  const filterDate = Vue.filter('date')
  const filterTime = Vue.filter('time')

  const start = value.start
  const end = value.end

  switch (mode) {
    case 'TIME':
      if (value.timed) {
        return filterTime(start) + ' - ' + filterTime(end)
      } else {
        return 'ganztägig'
      }
    case 'DATE_AND_TIME':
    default:
      if (value.timed) {
        return (
          filterDate(start) +
          ' ' +
          filterTime(start) +
          ' - ' +
          filterDate(end) +
          ' ' +
          filterTime(end)
        )
      } else {
        return filterDate(start) + ' - ' + filterDate(end)
      }
  }
})

Vue.filter('eventStart', function (value, mode = 'DATE_AND_TIME') {
  if (!value) return ''

  const filterDate = Vue.filter('date')
  const filterTime = Vue.filter('time')

  const start = value.start

  switch (mode) {
    case 'TIME':
      return filterTime(start)
    case 'DATE_AND_TIME':
    default:
      return filterDate(start) + ' ' + filterTime(start)
  }
})
