import moment from 'moment'

const validator = {
  message(field, { _min_, min }) {
    const value = _min_ || min
    const formatted = moment(value).format('DD.MM.YYYY')
    return field + ' muss nach dem ' + formatted + ' liegen.'
  },
  params: ['min'],
  validate(value, { min }) {
    return min ? moment(value).isAfter(min) : true
  }
}
export default validator
