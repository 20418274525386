import Vue from 'vue'

Vue.filter('carsharingEvbType', function (value) {
  if (!value) return ''

  switch (value) {
    case 'PERMANENT':
      return 'Dauer-eVB'

    case 'INDIVIDUAL':
      return 'Einzel-eVB'

    default:
      return value
  }
})

Vue.filter('carsharingEvbHolder', function (value) {
  if (!value) return ''

  switch (value) {
    case 'OPEN':
      return 'Halterschaft offen'

    case 'POLICYHOLDER':
      return 'Halterschaft VN'

    default:
      return value
  }
})

Vue.filter('carsharingPraemienType', function (value) {
  if (!value) return ''

  switch (value) {
    case 'GROUP':
      return 'Fahrzeuggruppe'

    case 'PIECE':
      return 'Stückprämie'

    default:
      return value
  }
})

Vue.filter('carsharingAbrechnungsregelung', function (value) {
  if (!value) return ''

  switch (value) {
    case 'STICHTAG':
      return 'Stichtagsregelung'

    case 'TAGGENAU':
      return 'Taggenaue Abrechnung (p.r.t)'

    default:
      return value
  }
})

Vue.filter('carsharingZahlungsweise', function (value) {
  if (!value) return ''

  switch (value) {
    case 'MONTHLY':
      return 'monatlich'

    case 'QUARTERLY':
      return 'vierteljährlich'

    case 'HALF_YEARLY':
      return 'halbjährlich'

    case 'YEARLY':
      return 'jährlich'

    default:
      return value
  }
})

Vue.filter('lessorCertificateStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'SENT':
      return 'An VU übermittelt (Warten auf Bestätigung)'
    case 'CLOSED':
      return 'Bestätigung von VU erhalten'
    default:
      return value
  }
})
