<template>
  <personalmarkt-online-link>
    <template #activator="{ on, disabled, loading }">
      <v-list-item dense :disabled="disabled" v-on="on">
        <v-list-item-icon>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            size="24"
          />
          <v-icon v-else :color="disabled ? 'grey' : null">
            $personalmarkt-online
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>personalmarkt.online</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </personalmarkt-online-link>
</template>

<script>
import PersonalmarktOnlineLink from '@/components/PersonalmarktOnlineLink'

export default {
  name: 'AppNavigationPmo',

  components: {
    PersonalmarktOnlineLink
  }
}
</script>
