<template>
  <v-dialog v-model="show" persistent width="300">
    <v-card>
      <v-card-text>
        <div class="text-subtitle-1 text-center">Bitte warten...</div>
        <v-progress-linear
          :indeterminate="indeterminate"
          :value="progress"
          color="primary"
        ></v-progress-linear>
        <div v-if="text" class="text-caption">
          {{ text }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProgressDialog',

  computed: {
    ...mapState('progress', [
      'loading',
      'progress',
      'indeterminate',
      'block',
      'text'
    ]),

    show() {
      return this.loading && this.block
    }
  }
}
</script>
