import Vue from 'vue'
import freshVue from '@freshsolutions/fresh-vue'
import vuetify from '@/plugins/vuetify/vuetify.js'
import { apolloClient } from '@/plugins/vue-apollo'
import i18n from '@/plugins/vue-i18n'

Vue.use(freshVue, {
  i18n: i18n,
  vuetify: vuetify,
  apollo: apolloClient
})
