const validator = {
  computesRequired: true,
  message: 'Bitte bestätigen Sie die Checkbox.',
  validate(value) {
    return {
      valid: !!value,
      required: true
    }
  }
}

export default validator
