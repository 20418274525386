<template>
  <todos-dialog>
    <template #activator="{ on, total }">
      <v-list-item dense v-on="on">
        <v-list-item-icon>
          <v-icon>$todos</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Aufgaben</v-list-item-title>
        </v-list-item-content>

        <v-chip v-if="total > 0" x-small color="info">
          {{ total }}
        </v-chip>
      </v-list-item>
    </template>
  </todos-dialog>
</template>

<script>
import TodosDialog from '@/components/todos/TodosDialog'

export default {
  name: 'AppNavigationTodos',

  components: {
    TodosDialog
  }
}
</script>
