import 'whatwg-fetch'

import Vue from 'vue'
import '@/plugins/index.js'
import App from './App.vue'
import store from '@/store/index.js'
import bootstrap from '@/bootstrap.js'
import vuetify from '@/plugins/vuetify/vuetify.js'
import i18n from '@/plugins/vue-i18n'
import { apolloProvider } from '@/plugins/vue-apollo'
import log from 'loglevel'
import router from './router'
import '@/filter/index'

import 'roboto-fontface/css/roboto/roboto-fontface.css'

// start initializing the app
bootstrap
  .then(() => {
    log.debug('bootstrap finished')
  })
  .catch((e) => {
    log.debug('bootstrap failed', e)
  })

new Vue({
  vuetify,
  router,
  store,
  i18n,
  apolloProvider,
  render: (h) => h(App)
}).$mount('#app')
