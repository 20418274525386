<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>$lead</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title> Lead </v-list-item-title>
      <v-list-item-subtitle>
        <leadquelle-avatar
          :id="String($_.get(lead, 'marketplace.id'))"
          size="15"
        />
        {{ $_.get(lead, 'marketplace.description') | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ lead.interne_nummer | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="lead.name">
        {{ lead.name | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <span v-if="lead.previousBearbeiter">
          {{ lead.previousBearbeiter }} ➔
        </span>
        {{ lead.bearbeiter | emptyString }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            icon
            class="ma-0"
            :to="{
              name: 'lead',
              params: { id: String(lead.id) },
              query: { mail: mailId ? String(mailId) : undefined }
            }"
            v-on="on"
          >
            <v-icon small>$action-show</v-icon>
          </v-btn>
        </template>
        <span>Lead anzeigen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import LeadquelleAvatar from '@/components/leads/LeadquelleAvatar'

export default {
  name: 'DataListItemLead',

  components: {
    LeadquelleAvatar
  },

  props: {
    lead: {
      type: Object,
      required: true
    },
    mailId: {
      type: [Number, String],
      required: false,
      default: null
    }
  }
}
</script>
