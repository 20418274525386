import Vue from 'vue'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    navigationDrawer: null,
    sideSheet: false,
    sideSheetData: {}
  },

  mutations: {
    setNavigationDrawer(state, navigationDrawer) {
      state.navigationDrawer = navigationDrawer
    },

    setSideSheet(state, sideSheet) {
      state.sideSheet = sideSheet
    },

    setSideSheetData(state, { routeName, data } = {}) {
      const r = routeName ?? router.currentRoute.name
      Vue.set(state.sideSheetData, r, data)
    }
  },

  actions: {
    toggleNavigationDrawer({ state, commit }) {
      commit('setNavigationDrawer', !state.navigationDrawer)
    },

    toggleSideSheet({ state, commit }) {
      commit('setSideSheet', !state.sideSheet)
    },

    resetSideSheetData({ commit }, { routeName } = {}) {
      commit('setSideSheetData', {
        routeName: routeName,
        data: null
      })
    }
  },

  getters: {
    sideSheetData: (state, getters, rootState) => {
      const r = rootState.route.name
      return state.sideSheetData[r]
    }
  }
}
