<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>$schaden</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title> Schadenmeldung </v-list-item-title>
      <v-list-item-subtitle>
        {{ claim.internal_id | emptyString }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            icon
            class="ma-0"
            :to="{
              name: 'schadenmeldung-cardamage-details',
              params: { id: String(claim.id) }
            }"
            v-on="on"
          >
            <v-icon small>$action-show</v-icon>
          </v-btn>
        </template>
        <span>Schadenmeldung anzeigen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemClaim',

  props: {
    claim: {
      type: Object,
      required: true
    }
  }
}
</script>
