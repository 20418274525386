import Vue from 'vue'
import router from '@/router'
import VueGtag from 'vue-gtag'

const trackingId = process.env.VUE_APP_GOOGLE_ANALYTICS_ID

if (trackingId) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: trackingId,
        params: {
          cookie_domain: 'none' // this will set the cookie-domain to the full domain (with subdomain). This is necessary for vanilla-cookieconsent to work (autoclean cookies)
        }
      },
      bootstrap: false,
      enabled: false
    },
    router
  )
}
