<template>
  <card-base
    title="Benachrichtigungen"
    :loading="internalLoading"
    :disabled="internalLoading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #toolbarIconActions>
      <button-mark-all-as-read />
      <button-reload @click="onClickRefetch" />
    </template>

    <template #expandPrepend>
      <!-- show read notifications -->
      <v-chip
        filter
        small
        outlined
        :input-value="filter.showRead"
        @click="filter.showRead = !filter.showRead"
      >
        <v-icon small left>$notification-read</v-icon>
        gelesene Benachrichtigungen anzeigen
      </v-chip>

      <!-- type -->
      <v-chip-group v-model="filter.types" column multiple>
        <v-chip
          v-for="(item, index) in availableTypes"
          :key="index"
          filter
          small
          outlined
          :value="item.key"
        >
          <span>{{ item.text }}</span>
          <span class="ml-1">({{ item.count }})</span>
        </v-chip>
      </v-chip-group>
    </template>

    <notifications-list
      :notifications="notifications"
      :loading="internalLoading"
    />
  </card-base>
</template>

<script>
import _ from 'lodash'
import gql from 'graphql-tag'
import NotificationUtils from '@/utils/notification.js'

import {
  NOTIFICATION as FRAGMENT_NOTIFICATION,
  NOTIFICATION_COUNTS as FRAGMENT_NOTIFICATION_COUNTS
} from '@/graphql/fragments.js'

import { CardBase } from '@freshsolutions/fresh-vue-cards'
import ButtonReload from '@/components/ButtonReload'
import ButtonMarkAllAsRead from './ButtonMarkAllAsRead'
import NotificationsList from './NotificationsList'

export default {
  name: 'NotificationsCard',

  components: {
    CardBase,
    ButtonReload,
    ButtonMarkAllAsRead,
    NotificationsList
  },

  inheritAttrs: false,

  apollo: {
    me: {
      query: gql`
        query me($unread: Boolean, $type: [String!]) {
          me {
            id

            ...NotificationCounts

            notifications(unread: $unread, type: $type) {
              ...Notification
            }
          }
        }
        ${FRAGMENT_NOTIFICATION_COUNTS}
        ${FRAGMENT_NOTIFICATION}
      `,
      variables() {
        return {
          // We can't just use "false" here, we need to use "undefined" or not set the field at all.
          unread: this.filter.showRead ? undefined : true,
          type:
            _.get(this.filterTypes, 'length', 0) > 0
              ? this.filterTypes
              : undefined
        }
      },
      fetchPolicy: 'network-only'
    }
  },

  data() {
    return {
      filter: {
        showRead: false,
        types: []
      },
      me: null
    }
  },

  computed: {
    internalLoading() {
      return this.$apollo.queries.me.loading
    },

    notifications() {
      return _.get(this.me, 'notifications', [])
    },

    availableTypes() {
      return NotificationUtils.buildNotificationTypes(this.me)
    },

    filterTypes() {
      return NotificationUtils.getValuesForKeys(this.filter.types)
    }
  },

  mounted() {
    this.refetch()
  },

  methods: {
    onClickRefetch() {
      this.refetch()
    },

    refetch() {
      this.$apollo.queries.me.refetch()
    }
  }
}
</script>
