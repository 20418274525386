import colors from 'vuetify/lib/util/colors'
import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'action-green-card-request': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'globe-europe',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'question',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  'action-green-card-upload': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'globe-europe',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'upload',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'action-green-card-show': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'globe-europe',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  'freshcarsharing-car-brand': {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  'freshcarsharing-car-model': {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  'freshcarsharing-car-licensenumber': {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  'freshcarsharing-car-evb': {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  'freshcarsharing-car-certificate': {
    component: IconFontawesome,
    props: {
      icon: 'receipt'
    }
  },

  // Status Green Card
  'freshcarsharing-car-status-green-card-NOT_REQUESTED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'globe-europe',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'freshcarsharing-car-status-green-card-REQUESTED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'globe-europe',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          color: 'warning',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'freshcarsharing-car-status-green-card-UPLOADED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'globe-europe',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'check',
          color: 'success',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  // Status
  'freshcarsharing-status-NEW': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshcarsharing-status-SENT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'orange',
      iconChildren: [
        {
          icon: 'angle-double-right',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshcarsharing-status-STOCK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshcarsharing-status-CANCELED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  }
}
