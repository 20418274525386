import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'signature-request': {
    component: IconFontawesome,
    props: {
      icon: 'signature'
    }
  },

  // status
  'signature-request-status-DRAFT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'grey',
      iconChildren: [
        {
          icon: 'pencil-ruler',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'signature-request-status-APPROVAL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'signature-request-status-REJECTED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'hand',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'signature-request-status-ONGOING': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'signature-request-status-DECLINED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'ban',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'signature-request-status-EXPIRED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'signature-request-status-DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'signature-request-status-CANCELED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'signature-request-status-DONE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  }
}
