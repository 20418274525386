import store from '@/store'
import LocationUtils from '@/utils/location.js'

const utils = {
  getClient() {
    // fallback to domain client, if state isn't initialized yet
    return store?.state?.client ?? LocationUtils.getClientFromDomain()
  },

  getApiUrl() {
    switch (utils.getClient()) {
      case 'freshforfinance':
        return process.env.VUE_APP_FFF_API_URL
      case 'fahrzeugmarkt.online':
        return process.env.VUE_APP_FMO_API_URL
      default:
        return null
    }
  },

  getGraphqlUrl() {
    switch (utils.getClient()) {
      case 'freshforfinance':
        return process.env.VUE_APP_FFF_GRAPHQL_HTTP
      case 'fahrzeugmarkt.online':
        return process.env.VUE_APP_FMO_GRAPHQL_HTTP
      default:
        return null
    }
  }
}

export default utils
