<template>
  <div v-bind="$attrs">
    <slot name="activator" :on="{ click: openSettings }">
      <v-btn color="primary" depressed outlined x-small @click="openSettings">
        <v-icon left x-small>$privacy-settings</v-icon>
        Privatsphäre-Einstellungen
      </v-btn>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'AppPrivacyConsent',

  methods: {
    openSettings() {
      window.cookieconsent?.showSettings()
    }
  }
}
</script>
