import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'bg-removal-process': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'slash',
      iconMask: 'image',
      transform: 'grow-4 down-2 right-2',
      iconChildren: [
        {
          icon: 'slash',
          transform: 'grow-2'
        }
      ]
    }
  },

  'bg-removal-process-summary': {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  },

  // misc
  'bg-removal-process-images-count': {
    component: IconFontawesome,
    props: {
      icon: 'list-ol'
    }
  },
  'bg-removal-process-price': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'bg-removal-process-background-image': {
    component: IconFontawesome,
    props: {
      icon: 'image'
    }
  },
  'bg-removal-process-background-color': {
    component: IconFontawesome,
    props: {
      icon: 'palette'
    }
  },

  // actions
  'action-bg-removal-process-start': {
    component: IconFontawesome,
    props: {
      icon: 'play'
    }
  },

  // status
  'bg-removal-process-status-DRAFT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'grey',
      iconChildren: [
        {
          icon: 'pencil-ruler',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'bg-removal-process-status-IN_PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'forward',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'bg-removal-process-status-ERROR': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'bg-removal-process-status-OK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },

  // status image
  'bg-removal-process-image-status-DRAFT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'grey',
      iconChildren: [
        {
          icon: 'pencil-ruler',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'bg-removal-process-image-status-IN_PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'forward',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'bg-removal-process-image-status-ERROR': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'bg-removal-process-image-status-OK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  }
}
