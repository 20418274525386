<template>
  <app-navigation-list-base :items="items" />
</template>

<script>
import { mapGetters } from 'vuex'

import AppNavigationListBase from './AppNavigationListBase.vue'

export default {
  name: 'AppNavigationDrawerListMain',

  components: {
    AppNavigationListBase
  },

  computed: {
    ...mapGetters('auth', ['hasRole']),

    items() {
      if (
        this.hasRole('carsharing') ||
        this.hasRole('carsharing-user-migrated')
      ) {
        return [
          {
            routeName: 'dashboard',
            icon: '$dashboard',
            title: 'Dashboard'
          },
          {
            routeName: 'user-carsharingcontractdata',
            icon: '$user-carsharingcontractdata',
            title: 'Carsharingdaten'
          },
          {
            routeName: 'user-carsharingcontactdata',
            icon: '$user-carsharingcontactdata',
            title: 'Kontaktdaten'
          },
          {
            icon: '$fahrzeugmarkt',
            title: 'Fahrzeugmarkt',
            group: '/fahrzeugmarkt/',
            children: [
              {
                routeName: 'fahrzeugmarkt-trade-in-processes',
                title: 'Ankaufprozesse'
              },
              // {
              //   routeName: 'fahrzeugmarkt-valuations',
              //   title: 'Fahrzeugbewertungen'
              // },
              {
                routeName: 'fahrzeugmarkt-fahrzeuge',
                title: 'Fahrzeuge'
              },
              {
                routeName: 'fahrzeugmarkt-fahrzeugangebote',
                title: 'Inserate'
              },
              {
                routeName: 'fahrzeugmarkt-contracts',
                title: 'Verträge'
              }
            ]
          },
          {
            icon: '$insurance',
            title: 'Anträge',
            group: 'antrag',
            children: [
              {
                routeName: 'antrag',
                title: 'Übersicht',
                exact: true
              },
              {
                routeName: 'freshcarsharing',
                title: 'freshCARsharing'
              },
              {
                routeName: 'freshbike',
                title: 'freshBIKE'
              }
            ]
          },
          {
            icon: '$insurance-object',
            title: 'Objekte',
            // group: 'objekt',
            children: [
              {
                routeName: 'external-objektsuche',
                // icon: '$external-objektsuche',
                title: 'Objektsuche',
                exact: true
              },
              {
                routeName: 'carsharing-carsharinglessorcertificates-list',
                // icon: '$carsharing-carsharinglessorcertificates',
                title: 'Sicherungsscheine',
                exact: true
              }
            ]
          },
          {
            icon: '$versicherung-vertrag',
            title: 'Verträge',
            // group: 'vertrag',
            children: [
              {
                routeName: 'external-vertragssuche',
                // icon: '$external-vertragssuche',
                title: 'Vertragssuche'
              },
              {
                routeName: 'external-vertragsliste',
                // icon: '$external-vertragsliste',
                title: 'Vertragsliste'
              }
            ]
          },
          {
            icon: '$schadenmeldung',
            title: 'Schadenmeldung',
            // group: 'schadenmeldung',
            children: [
              {
                routeName: 'schadenmeldung',
                title: 'Übersicht',
                exact: true
              },
              {
                routeName: 'schadenmeldung-cardamage-list',
                title: 'freshCARsharing'
              },
              {
                routeName: 'schadenmeldung-freshbike-claims',
                title: 'freshBIKE'
              }
            ]
          },
          {
            icon: '$external-schadenliste',
            title: 'Schäden',
            // group: 'schaden',
            children: [
              {
                routeName: 'external-schadensuche',
                // icon: '$external-schadensuche',
                title: 'Schadensuche'
              },
              {
                routeName: 'external-schadenliste',
                // icon: '$external-schadenliste',
                title: 'Schadenliste'
              },
              {
                routeName: 'external-schadenquote',
                // icon: '$external-schadenquote',
                title: 'Schadenquote'
              }
            ]
          },
          // {
          //   routeName: 'external-partnerdaten',
          //   icon: '$user',
          //   title: 'Partnerdaten'
          // },
          {
            routeName: 'external-statistics-bestand',
            icon: '$external-statistics',
            title: 'Statistiken'
          },

          {
            routeName: 'external-kontenabfrage',
            icon: '$external-kontenabfrage',
            title: 'Kontenabfrage'
          },
          {
            routeName: 'external-epostkorb',
            icon: '$external-epostkorb',
            title: 'ePostkorb'
          },

          {
            routeName: 'filecenter',
            icon: '$filecenter',
            title: 'Dokumentencenter'
          },
          {
            icon: '$supportcenter',
            title: 'Kontaktcenter',
            group: 'supportcenter',
            children: [
              {
                routeName: 'issues',
                title: 'Übersicht'
              },
              {
                routeName: 'issue',
                title: 'Neue Nachricht'
              }
            ]
          },
          {
            routeName: 'tools',
            icon: '$tools',
            title: 'Tools'
          }
        ]
      } else {
        return [
          {
            routeName: 'dashboard',
            icon: '$dashboard',
            title: 'Dashboard'
          },
          {
            routeName: 'fahrzeugmarkt-startpage',
            icon: '$fahrzeugmarkt',
            icon_action: '$external-link',
            title: 'fahrzeugmarkt.online'
          },
          {
            routeName: 'calendar',
            icon: '$calendar',
            title: 'Kalender'
          },
          {
            icon: '$forms',
            title: 'Formulare',
            group: '/forms/',
            children: [
              {
                routeName: 'formrequests',
                title: 'Individuell'
              },
              {
                routeName: 'selfdisclosures',
                title: 'Selbstauskünfte'
              },
              {
                routeName: 'dataprotections',
                title: 'Datenschutz'
              },
              {
                routeName: 'fahrzeugmarkt-contracts',
                title: 'Fahrzeugverträge'
              }
            ]
          },
          {
            routeName: 'customers-overview',
            icon: '$customers',
            title: 'Kunden'
          },
          {
            icon: '$leads',
            title: 'Leads',
            group: '/leads/',
            children: [
              {
                routeName: 'leads',
                title: 'Übersicht'
              },
              {
                routeName: 'leadfeedbacks',
                title: 'Bewertungen'
              }
            ]
          },
          {
            icon: '$fahrzeugmarkt',
            title: 'Fahrzeugverwaltung',
            group: '/fahrzeugmarkt/',
            children: [
              {
                routeName: 'fahrzeugmarkt-trade-in-processes',
                title: 'Ankaufprozesse'
              },
              {
                routeName: 'fahrzeugmarkt-valuations',
                title: 'Fahrzeugbewertungen'
              },
              {
                routeName: 'fahrzeugmarkt-fahrzeuge',
                title: 'Fahrzeuge'
              },
              {
                routeName: 'fahrzeugmarkt-fahrzeuglocations',
                title: 'Fahrzeugortung'
              },
              {
                routeName: 'fahrzeugmarkt-fahrzeugangebote',
                title: 'Inserate'
              },
              {
                routeName: 'fahrzeugmarkt-contracts',
                title: 'Verträge'
              },
              {
                routeName: 'fahrzeugmarkt-emailings',
                title: 'E-Mailings'
              }
            ]
          },
          {
            icon: '$workshop',
            title: 'Werkstatt',
            group: '/workshop/',
            children: [
              {
                routeName: 'workshop-workshoprepairreports',
                title: 'Technische Bewertungen'
              },
              {
                routeName: 'workshop-storages',
                title: 'Ein-/Auslagerungen'
              }
            ]
          },
          {
            icon: '$insurance',
            title: 'Versicherung',
            group: 'antrag',
            children: [
              {
                routeName: 'antrag',
                title: 'Übersicht',
                exact: true
              },
              {
                routeName: 'freshcarflat',
                title: 'freshCARflat / freshCARfleet'
              },
              // {
              //   routeName: 'freshcarfleet',
              //   title: 'freshCARfleet'
              // },
              {
                routeName: 'freshcarsharing',
                title: 'freshCARsharing'
              },
              {
                routeName: 'builder',
                title: 'freshBAUimmo'
              },
              {
                routeName: 'freshbike',
                title: 'freshBIKE'
              }
            ]
          },
          {
            icon: '$schadenmeldung',
            title: 'Schadenmeldung',
            group: 'schadenmeldung',
            children: [
              {
                routeName: 'schadenmeldung',
                exact: true,
                title: 'Übersicht'
              },
              {
                routeName: 'schadenmeldung-cardamage-list',
                title: 'freshCARsharing'
              },
              {
                routeName: 'schadenmeldung-freshbike-claims',
                title: 'freshBIKE'
              }
            ]
          },
          {
            icon: '$bestand',
            title: 'Bestandsdaten',
            group: '/bestand/',
            children: [
              {
                routeName: 'bestand-import',
                title: 'Import'
              },
              {
                routeName: 'bestand-partners',
                title: 'Partner'
              },
              {
                routeName: 'bestand-vertraege',
                title: 'Verträge'
              },
              {
                routeName: 'bestand-schaeden',
                title: 'Schäden'
              }
            ]
          },
          {
            icon: '$controlling',
            title: 'Controlling',
            group: '/controlling/',
            children: [
              {
                routeName: 'controlling-carsharing-statistics',
                title: 'Carsharing'
              },
              {
                routeName: 'controlling-statistics',
                title: 'Statistiken'
              },
              {
                routeName: 'controlling-reports',
                title: 'Reports'
              }
            ]
          },
          {
            routeName: 'filecenter',
            icon: '$filecenter',
            title: 'Dokumentencenter'
          },
          {
            routeName: 'quicklinks',
            icon: '$quicklinks',
            title: 'Quicklinks'
          },
          {
            routeName: 'tools',
            icon: '$tools',
            title: 'Tools'
          },
          {
            icon: '$supportcenter',
            title: 'Kontaktcenter',
            group: 'supportcenter',
            children: [
              {
                routeName: 'issues',
                title: 'Übersicht'
              },
              {
                routeName: 'issue',
                title: 'Neue Nachricht'
              }
            ]
          },
          {
            routeName: 'administration',
            icon: '$administration',
            title: 'Administration'
            // group: '/admin/',
            // children: [
            //   {
            //     routeName: 'administration-settings',
            //     title: 'Einstellungen'
            //   },
            //   {
            //     routeName: 'administration-users',
            //     title: 'Benutzer'
            //   }
            // ]
          },

          /**
           * SYSTEM
           */
          {
            header: 'System'
          },
          {
            icon: '$systempartner',
            title: 'Systempartner',
            group: '/systempartner/',
            children: [
              {
                routeName: 'systempartner-carsharing',
                title: 'Carsharing'
              },
              {
                routeName: 'systempartner-autohaeuser',
                title: 'Autohäuser'
              },
              {
                routeName: 'systempartner-produktpartner',
                title: 'Produktpartner'
              },
              {
                routeName: 'systempartner-employermanagement',
                title: 'Firmen'
              }
            ]
          },
          {
            icon: '$system',
            title: 'System',
            group: '/system/',
            children: [
              {
                routeName: 'system-mails',
                title: 'E-Mails (empfangen)'
              },
              {
                routeName: 'system-maillogs',
                title: 'E-Mails (versendet)'
              },
              {
                routeName: 'system-emailings',
                title: 'E-Mailings'
              },
              {
                routeName: 'system-signature-requests',
                title: 'Signatur-Anfragen'
              },
              {
                routeName: 'system-users',
                title: 'Users'
              },
              {
                routeName: 'system-imports',
                title: 'Imports'
              },

              {
                title: 'System',
                group: '/system/settings/system/',
                children: [
                  {
                    routeName: 'settings-system-system-mail',
                    title: 'Mailversand'
                  },
                  {
                    routeName: 'settings-system-system-message',
                    title: 'Nachricht'
                  }
                ]
              },
              {
                title: 'Antrag',
                group: '/system/settings/antrag/',
                children: [
                  {
                    routeName: 'settings-system-antrag-freshcarflat',
                    title: 'freshCARflat'
                  },
                  {
                    routeName: 'settings-system-antrag-freshcarsharing',
                    title: 'freshCARsharing'
                  },
                  {
                    routeName: 'settings-system-antrag-freshbauimmo',
                    title: 'freshBAUimmo'
                  },
                  {
                    routeName: 'settings-system-antrag-freshbike',
                    title: 'freshBIKE'
                  }
                ]
              },
              {
                title: 'Ratenzuschlag',
                group: '/system/settings/ratenzuschlag/',
                children: [
                  {
                    routeName: 'settings-system-ratenzuschlag-allgemein',
                    title: 'Allgemein'
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
