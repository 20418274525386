<template>
  <edit-active-partner outlined dense hide-details class="ma-2" />
</template>

<script>
import EditActivePartner from '@/components/EditActivePartner'

export default {
  name: 'AppNavigationPartner',

  components: {
    EditActivePartner
  }
}
</script>
