var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"900","fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(bind){return [_vm._t("activator",null,null,{
        ...bind,
        loading: _vm.$apollo.queries.me.loading,
        total: _vm.me.notificationsCount,
        totalUnread: _vm.me.unreadNotificationsCount,
        types: _vm.types
      })]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('notifications-card',{ref:"card",attrs:{"dialog":"","draggable":""},on:{"close":function($event){_vm.dialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }