import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  dataprotection: {
    component: IconFontawesome,
    props: {
      icon: 'file-shield'
    }
  },

  // status
  'dataprotection-status-SENT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'dataprotection-status-URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-7 left-2',
          color: 'white'
        }
      ]
    }
  },
  'dataprotection-status-BLANKO': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'grey',
      iconChildren: [
        {
          icon: 'file-pdf',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'dataprotection-status-SIGNED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'dataprotection-status-REJECTED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'hand',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  }
}
