import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  commissions: {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },

  'commission-amount': {
    component: IconFontawesome,
    props: {
      icon: 'coins'
    }
  },

  'action-commission-close': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'action-commission-complain': {
    component: IconFontawesome,
    props: {
      icon: 'warning',
      color: 'warning'
    }
  },
  'action-commission-reopen': {
    component: IconFontawesome,
    props: {
      icon: 'rotate-right'
    }
  },

  'commission-status-OPEN': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'euro-sign',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-8 down-8 right-8',
          color: 'info'
        }
      ]
    }
  },
  'commission-status-NOT_OK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'euro-sign',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'warning',
          transform: 'shrink-8 down-8 right-8',
          color: 'warning'
        }
      ]
    }
  },
  'commission-status-OK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'euro-sign',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-8 down-8 right-8',
          color: 'success'
        }
      ]
    }
  }
}
