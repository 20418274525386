export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },

  computed: {
    src() {
      return `${process.env.VUE_APP_ASSET_URL}/fahrzeugmarkt.online/leadquellen/favicons/${this.id}.png`
    }
  }
}
