<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>$fahrzeug</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title> Fahrzeug </v-list-item-title>
      <v-list-item-subtitle>
        {{ vehicle.interne_nummer | emptyString }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            icon
            class="ma-0"
            :to="{
              name: 'fahrzeugmarkt-fahrzeug-details',
              params: { id: String(vehicle.id) }
            }"
            v-on="on"
          >
            <v-icon small>$action-show</v-icon>
          </v-btn>
        </template>
        <span>Fahrzeug anzeigen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemVehicle',

  props: {
    vehicle: {
      type: Object,
      required: true
    }
  }
}
</script>
