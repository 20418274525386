<template>
  <v-navigation-drawer
    v-if="renderRouterView"
    v-model="showSideSheet"
    app
    clipped
    right
    disable-resize-watcher
  >
    <router-view
      name="sideSheet"
      :side-sheet-data="sideSheetData"
      @close="close"
      @update:data="updateSideSheetData"
    />
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'AppSideSheet',

  computed: {
    ...mapGetters('layout', ['sideSheetData']),

    renderRouterView() {
      return !!_.get(this.$route, 'matched[0].components.sideSheet', false)
    },

    showSideSheet: {
      get() {
        return this.$store.state.layout.sideSheet
      },
      set(val) {
        this.$store.commit('layout/setSideSheet', val)
      }
    }
  },

  methods: {
    close() {
      this.showSideSheet = false
    },

    updateSideSheetData(data) {
      this.$store.commit('layout/setSideSheetData', { data: data })
    }
  }
}
</script>
