import Vue from 'vue'

Vue.filter('damageHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'DAMAGE_INPUT_VUDMGID_MANUAL':
      return 'Schadennummer manuell eingetragen'
    case 'DAMAGE_INPUT_VUDMGID_AUTOMATIC':
      return 'Schadennummer automatisch eingetragen'
    case 'DAMAGE_SENT_VU':
      return 'Schadenmeldung an VU übermittelt'
    case 'DAMAGE_CLOSED':
      return 'Schadenmeldung geschlossen'
    case 'DAMAGE_GENERATE_PDF':
      return 'Schadenmeldung als PDF exportiert'
    case 'DAMAGE_CONTACT_VU_EMAIL':
      return 'Versicherer kontaktiert'
    case 'DAMAGE_CONTACT_PERSON_EMAIL':
      return 'Unfallbeteiligte Person kontaktiert'
    case 'DAMAGE_RECEIVED_EMAIL':
      return 'E-Mail eingegangen'
    case 'CLAIMSTATEMENT_CREATED':
      return 'Anfrage zur Stellungnahme versendet'
    case 'CLAIMSTATEMENT_SUBMITTED':
      return 'Stellungnahme ausgefüllt'
    case 'CLAIMSTATEMENT_DELETED':
      return 'Anfrage zur Stellungnahme gelöscht'
    default:
      return undefined // return default event text
  }
})

Vue.filter('damageStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NEW':
      return 'Neu (noch nicht an VU übermittelt)'
    case 'SENT':
      return 'An VU übermittelt (Warten auf Schadennummer)'
    case 'IN_PROGRESS':
      return 'Schaden in Bearbeitung (Schadennummer erhalten)'
    case 'CLOSED':
      return 'Schaden geschlossen'
    default:
      return value
  }
})
