import Vue from 'vue'

Vue.filter('formFieldType', function (value) {
  if (!value) return ''

  switch (value) {
    case 'subheader':
      return 'Überschrift'
    case 'text':
      return 'Textfeld'
    case 'textarea':
      return 'Freitextfeld'
    case 'select':
      return 'Auswahlfeld'
    case 'upload':
      return 'Upload'
    default:
      return value
  }
})
