<template>
  <card-base
    title="Debug"
    :fullscreen="$vuetify.breakpoint.mobile"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>Local Storage</v-subheader>
          <v-btn small @click="localStorageClear()"> zurücksetzen </v-btn>
          <v-btn small @click="localStorageClear('vuex')">
            vuex zurücksetzen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </card-base>
</template>

<script>
import { CardBase } from '@freshsolutions/fresh-vue-cards'

export default {
  name: 'DebugCard',

  components: {
    CardBase
  },

  methods: {
    localStorageClear(key) {
      if (key) {
        window.localStorage.removeItem(key)
      } else {
        window.localStorage.clear()
      }
      this.$router.go()
    }
  }
}
</script>
