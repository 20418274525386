import Vue from 'vue'

Vue.filter('dataprotectionStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'SENT':
      return 'versendet (warten auf Unterschrift)'
    case 'URL':
      return 'als Direktlink erstellt'
    case 'BLANKO':
      return 'als Blanko erstellt'
    case 'SIGNED':
      return 'unterzeichnet'
    case 'REJECTED':
      return 'nicht genehmigt'

    default:
      return value
  }
})
