<template>
  <div>
    <v-list nav dense>
      <v-list-item :disabled="loadingLogout" @click="logout">
        <v-list-item-action>
          <v-progress-circular
            v-if="loadingLogout"
            indeterminate
            size="16"
            width="2"
            color="primary"
          />
          <v-icon v-else small>$action-logout</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <app-navigation-list-base :items="$options.items" />
  </div>
</template>

<script>
import AppNavigationListBase from './AppNavigationListBase.vue'

export default {
  name: 'AppNavigationDrawerListUser',

  components: {
    AppNavigationListBase
  },

  // create static items in $options (not in data - it doesn't need to be reactive)
  items: [
    {
      routeName: 'user-history',
      icon: '$user-history',
      title: 'Historie'
    },
    {
      routeName: 'user-administration',
      icon: '$administration',
      title: 'Einstellungen'
    }
  ],

  data() {
    return {
      loadingLogout: false
    }
  },

  methods: {
    logout() {
      this.loadingLogout = true

      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
          this.loadingLogout = false
        })
        .catch(() => {
          this.$router.push({ name: 'login' })
          this.loadingLogout = false
        })
    }
  }
}
</script>
