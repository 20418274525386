<template>
  <v-img :src="src" v-bind="$attrs" contain></v-img>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppLogo',

  // This doesn't need to be reactive, so we will define it in the $options.
  defaultSrc: process.env.BASE_URL + 'img/logo/placeholder.svg',

  computed: {
    ...mapGetters({
      images: 'theme/images'
    }),

    src() {
      return this.images.logo || this.$options.defaultSrc
    }
  }
}
</script>
