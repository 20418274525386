import colors from 'vuetify/lib/util/colors'
import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'assignment-coverage-object-type': {
    component: IconFontawesome,
    props: {
      icon: 'building'
    }
  },
  'assignment-coverage-number-of-objects': {
    component: IconFontawesome,
    props: {
      icon: 'list-ol'
    }
  },
  'assignment-coverage-book-number': {
    component: IconFontawesome,
    props: {
      icon: 'bookmark'
    }
  },

  'builder-assignmentcoverage-status-NEW': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature',
      color: colors.grey.lighten1
    }
  },
  'builder-assignmentcoverage-status-IN_APPROVAL': {
    component: IconFontawesome,
    props: {
      icon: 'clock',
      color: 'warning'
    }
  },
  'builder-assignmentcoverage-status-APPROVED': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'builder-assignmentcoverage-status-CANCELED': {
    component: IconFontawesome,
    props: {
      icon: 'xmark',
      color: 'error'
    }
  }
}
