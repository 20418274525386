import Vue from 'vue'

Vue.filter('emptyString', function (value, noDataText = '-') {
  return value ? value : noDataText
})

Vue.filter('trim', function (value) {
  return (value || '').trim()
})

Vue.filter('substring', function (value, length) {
  return (value || '').substring(0, length)
})

Vue.filter('substringWithEllipsis', function (value, length) {
  const filterSubstring = Vue.filter('substring')
  const oldLength = value ? value.length : 0
  const newLength = length > 3 ? length - 3 : length
  const suffix = oldLength > newLength ? '...' : ''

  return filterSubstring(value, newLength) + suffix
})
