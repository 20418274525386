<template>
  <v-tooltip bottom :disabled="!tooltip">
    <template #activator="{ on }">
      <v-avatar v-bind="$attrs" :tile="tile" v-on="on">
        <v-img :src="src" contain />
      </v-avatar>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import LeadquelleVisualization from '@/mixins/LeadquelleVisualizationFavicon.js'

export default {
  name: 'LeadquelleAvatar',
  mixins: [LeadquelleVisualization],

  props: {
    tile: {
      type: Boolean,
      required: false,
      default: true
    },

    tooltip: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
