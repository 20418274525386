<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>$fahrzeugevents</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ fahrzeugevent.type | emptyString }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ fahrzeugevent.period | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ fahrzeugevent.location | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text-wrap">
        {{ fahrzeugevent.message | emptyString }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemFahrzeugevent',

  props: {
    fahrzeugevent: {
      type: Object,
      required: true
    }
  }
}
</script>
