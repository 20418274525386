import _ from 'lodash'
import store from '@/store'

export default {
  getUrl(property, route) {
    if (property && route) {
      const client = store.state.client
      const url = _.get(route, 'meta.' + property, null)

      if (url) {
        if (typeof url === 'string' || typeof url === String) {
          return url
        } else if (Object.prototype.hasOwnProperty.call(url, client)) {
          return url[client]
        } else {
          return url['default']
        }
      }
    }

    return null
  },

  getKnowledgeBaseUrl(route) {
    return this.getUrl('knowledgeBaseUrl', route)
  }
}
