import Vue from 'vue'

Vue.filter('freshcarflatKasko', function (value) {
  switch (value) {
    case 'KH':
      return 'Nur HP'
    case 'TK150':
      return 'TK 160'
    case 'VK500_150':
      return 'VK 500 / TK 150'
    default:
      return '-'
  }
})

Vue.filter('freshcarflatFahrzeug', function (value) {
  if (!value) {
    return ''
  }
  const filterCurrency = Vue.filter('currency')
  const filterKasko = Vue.filter('freshcarflatKasko')

  if (
    value.fahrzeugart ||
    value.lfdnummer ||
    value.kasko ||
    (value.praemie && value.praemie.length >= 1)
  ) {
    return (
      value.lfdnummer +
      ', ' +
      value.fahrzeugart +
      ', ' +
      filterKasko(value.kasko) +
      ', ' +
      filterCurrency(((value.praemien || [])[0] || {}).gesamt)
    )
  } else {
    return '-'
  }
})

Vue.filter('freshcarflatFahrzeugStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'ANGEBOT':
      return 'Angebot'

    case 'AUFHEBUNG':
      return 'Aufgehoben'

    case 'AUFHEBUNG_PLANNED':
      return 'Aufhebung geplant'

    case 'WARTEN_EVB':
      return 'Warten auf eVB'

    case 'WARTEN_EVB_VERLAENGERUNG':
      return 'Warten auf eVB + Verlängerung'

    case 'ANTRAG_VERSENDET_VERLAENGERUNG':
      return 'Antrag versendet + Verlängerung'

    case 'ANTRAG_VERSENDET':
      return 'Antrag versendet'

    default:
      return value
  }
})
