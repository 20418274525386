import Vue from 'vue'
import store from '@/store'

Vue.filter('number', function (value, fractionDigits) {
  const zen = store.getters['theme/zen']

  return (value || 0).toLocaleString('de-DE', {
    minimumFractionDigits: zen
      ? 0
      : fractionDigits
      ? fractionDigits
      : undefined,
    maximumFractionDigits: zen ? 0 : fractionDigits ? fractionDigits : undefined
  })
})
