import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  notifications: {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },
  'notification-read': {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open'
    }
  },
  'notification-unread': {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  }
}
