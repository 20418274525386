var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:{ unread: _vm.unread },attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 pb-0 pr-0"},[(_vm.title)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-scale-transition',{attrs:{"origin":"center center"}},[(_vm.unread)?_c('v-icon',_vm._g({staticClass:"mr-4",attrs:{"color":"warning"}},on),[_vm._v(" $notification-unread ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("ungelesen")])])],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[(_vm.data)?_c('notifications-list-notification-data',{attrs:{"type":_vm.notification.type,"data":_vm.data}}):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"pl-4 pt-0"},[_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm._f("dateAndTime")(_vm.notification.created_at))+" ")]),_c('v-spacer'),_c('v-fade-transition',[(_vm.notification.read_at)?_c('div',[(_vm.notification.became_superfluous_at)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"info"}},on),[_vm._v(" $info ")])]}}],null,false,4160863867)},[_c('span',[_vm._v(" Wurde automatisch als gelesen markiert, weil die Benachrichtigung nicht mehr relevant ist. ")])]):_vm._e(),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm._f("dateAndTime")(_vm.notification.read_at))+" ")])],1):_vm._e()]),_c('list-options',{attrs:{"actions":[
        {
          icon: '$action-mark-as-read',
          text: 'Als gelesen markieren',
          hidden: !!_vm.notification.read_at,
          action: _vm.markAsRead,
          loading: _vm.loading
        },
        {
          icon: '$action-mark-as-unread',
          text: 'Als ungelesen markieren',
          hidden: !_vm.notification.read_at,
          action: _vm.markAsUnread,
          loading: _vm.loading
        }
      ],"no-menu":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }