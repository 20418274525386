import Vue from 'vue'
import store from '@/store'

Vue.filter('currency', function (value, returnNullIfEmpty) {
  if (returnNullIfEmpty && (value === null || value === undefined)) {
    return null
  } else {
    const zen = store.getters['theme/zen']
    return (value || 0).toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: zen ? 0 : 2,
      maximumFractionDigits: zen ? 0 : 2
    })
  }
})

Vue.filter('currencyWithSign', function (value) {
  const filter = Vue.filter('currency')
  const string = filter(Math.abs(value)) // always positive number
  const sign = value === 0 ? '' : value > 0 ? '+' : '-'

  return `${sign} ${string}`
})
