<template>
  <v-select
    :items="internalItems"
    :label="label"
    :loading="internalLoading"
    :disabled="internalDisabled"
    :multiple="multiple"
    item-value="id"
    item-text="name"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- select all -->
    <template v-if="multiple" #prepend-item>
      <v-list-item ripple @mousedown.prevent @click="selectAll">
        <v-list-item-content>
          <v-list-item-title> Alle auswählen </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>

    <!-- items -->
    <template #item="{ item, on, attrs }">
      <v-list-item v-slot="{ active }" v-bind="attrs" v-on="on">
        <v-list-item-action v-if="multiple">
          <v-checkbox :input-value="active" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-chip v-for="vudaten in item.vudaten" :key="vudaten.id" x-small>
              {{ vudaten?.partner_externe_nummer }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon
          v-if="
            showPreferredActivePartner &&
            item.id === me.preferredActivePartner?.id
          "
        >
          <v-icon small>$favorite</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import gql from 'graphql-tag'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectActivePartner',

  props: {
    label: {
      type: String,
      required: false,
      default: 'Partner'
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false
    },

    showPreferredActivePartner: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  apollo: {
    me: {
      query: gql`
        query me {
          me {
            id
            preferredActivePartner {
              id
            }

            partner {
              id
              name

              vudaten {
                id
                partner_externe_nummer
              }
            }
            employee {
              id
              relatedEmployers {
                id
                name

                vudaten {
                  id
                  partner_externe_nummer
                }
              }
            }
          }
        }
      `
    }
  },

  data() {
    return {
      me: null
    }
  },

  computed: {
    ...mapGetters('auth', {
      authUser: 'user'
    }),

    internalItems() {
      if (this.authUser?.email?.endsWith('bgv-carsharing@freshforfinance.de')) {
        return this.me?.partner ? [this.me.partner] : []
      } else {
        return this.me?.employee?.relatedEmployers
      }
    },

    internalLoading() {
      return this.loading || this.$apollo.queries.me.loading
    },

    internalDisabled() {
      return this.disabled || this.internalLoading
    }
  },

  methods: {
    selectAll() {
      this.$emit(
        'input',
        this.internalItems.map((item) => item.id)
      )
    }
  }
}
</script>
