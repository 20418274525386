import Vue from 'vue'

Vue.filter('commissionStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'OPEN':
      return 'Offen'
    case 'NOT_OK':
      return 'Reklamation'
    case 'OK':
      return 'Geld eingegangen'
    default:
      return value
  }
})
