import Vue from 'vue'

Vue.filter('freshcarsharingApplicationStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NEW':
      return 'Angelegt'
    case 'SENT':
      return 'Übermittelt'
    case 'CANCELED':
      return 'Storniert'
    default:
      return value
  }
})

Vue.filter('freshcarsharingCarStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NEW':
      return 'Angelegt'
    case 'SENT':
      return 'Übermittelt'
    case 'STOCK':
      return 'Policiert / Bestand'
    case 'CANCELED':
      return 'Storniert'
    default:
      return value
  }
})

Vue.filter('freshcarsharingCarStatusGreenCard', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NOT_REQUESTED':
      return 'Noch nicht angefordert'
    case 'REQUESTED':
      return 'Angefordert'
    case 'UPLOADED':
      return 'Hochgeladen'
    default:
      return value
  }
})

Vue.filter('freshcarsharingApplicationHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'CREATED':
      return 'Antrag freshCARsharing erstellt'
    case 'UPDATED':
      return 'Antrag freshCARsharing geändert'

    default:
      return undefined // return default event text
  }
})

Vue.filter('freshcarsharingCarHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'CREATED':
      return 'Fahrzeug angelegt'
    case 'UPDATED':
      return 'Fahrzeug geändert'

    default:
      return undefined // return default event text
  }
})

Vue.filter('lessorCertificateHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NEW_LESSOR_CERTIFICATE_OUT':
      return 'Sicherungsschein an VU versendet'
    case 'NEW_LESSOR_CERTIFICATE_IN':
      return 'Sicherungsschein: Bestätigung von VU erhalten'
    case 'NEW_LESSOR_CERTIFICATE_INFO':
      return 'Sicherungsschein: Bestätigung an Leasinggeber gesendet'
    default:
      return undefined // return default event text
  }
})
