import moment from 'moment'

function toMoment(value) {
  return moment(value)
}

const validator = {
  message(field, { min, max }) {
    const minFormatted = min.format('DD.MM.YYYY')
    const maxFormatted = max.format('DD.MM.YYYY')
    return (
      field +
      ' muss zwischen ' +
      minFormatted +
      ' und ' +
      maxFormatted +
      ' liegen.'
    )
  },
  castValue: toMoment,
  params: [
    {
      name: 'min',
      cast: toMoment
    },
    {
      name: 'max',
      cast: toMoment
    }
  ],
  validate(value, { min, max }) {
    return moment(value).isBetween(min, max)
  }
}
export default validator
