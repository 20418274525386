import _ from 'lodash'
import store from '@/store'

export default {
  buildNotificationTypes(data) {
    const hasRole = store.getters['auth/hasRole']

    // We will set a key for the types.
    // This is neccessary to use them as the selected value in the v-chip-group.
    // We can't use the value directly, because this is sometimes an array itself.
    if (hasRole('carsharing')) {
      return [
        {
          key: 'CLAIMSTATEMENT_FEEDBACK',
          value:
            'App\\Notifications\\Schadenmeldung\\ClaimstatementFeedbackReceived',
          text: 'Stellungnahme',
          count: _.get(data, 'unreadClaimstatementFeedbackReceived', 0)
        },
        {
          key: 'ISSUE_MESSAGES',
          value: 'App\\Notifications\\Supportcenter\\IssueMailReceived',
          text: 'Kontaktcenter',
          count: _.get(data, 'unreadIssueMailReceived', 0)
        }
      ]
    } else {
      return [
        {
          key: 'NOTES',
          value: 'App\\Notifications\\NoteCreated',
          text: 'Notizen',
          count: _.get(data, 'unreadNoteCreated', 0)
        },
        {
          key: 'LEADS',
          value: 'App\\Notifications\\Fahrzeugmarkt\\CreateLead',
          text: 'Leadeingang',
          count: _.get(data, 'unreadCreateLead', 0)
        },
        {
          key: 'LEAD_REMINDER',
          value: 'App\\Notifications\\Leads\\CreateLeadReminder',
          text: 'Lead-Wiedervorlage',
          count: _.get(data, 'unreadCreateLeadReminder', 0)
        },
        {
          key: 'EXPIRED_RESERVATION',
          value:
            'App\\Notifications\\Fahrzeugmarkt\\ExpiredFahrzeugreservation',
          text: 'Fahrzeugreservierung',
          count: _.get(data, 'unreadExpiredFahrzeugreservation', 0)
        },
        {
          key: 'VEHICLE_EVENT',
          value: 'App\\Notifications\\Fahrzeugmarkt\\FahrzeugeventCreated',
          text: 'Fahrzeugevent',
          count: _.get(data, 'unreadFahrzeugeventCreated', 0)
        },
        {
          key: 'STORAGE_EVENT',
          value: 'App\\Notifications\\Fahrzeugmarkt\\StorageeventCreated',
          text: 'Ein-/Auslagerung',
          count: _.get(data, 'unreadStorageeventCreated', 0)
        },
        {
          key: 'MEETINGS',
          value: [
            'App\\Notifications\\Fahrzeugmarkt\\MeetingCreated',
            'App\\Notifications\\Fahrzeugmarkt\\MeetingDeleted',
            'App\\Notifications\\Fahrzeugmarkt\\MeetingReminder'
          ],
          text: 'Termine',
          count: _.get(data, 'unreadMeeting', 0)
        },
        {
          key: 'LEAD_MESSAGES',
          value: 'App\\Notifications\\Fahrzeugmarkt\\ReceivedMail',
          text: 'Nachricht zum Lead',
          count: _.get(data, 'unreadReceivedMail', 0)
        },
        {
          key: 'SELFDISCLOSURE_FEEDBACK',
          value:
            'App\\Notifications\\Fahrzeugmarkt\\SelfdisclosureFeedbackReceived',
          text: 'Selbstauskunft',
          count: _.get(data, 'unreadSelfdisclosureFeedbackReceived', 0)
        },
        {
          key: 'DATAPROTECTION_MESSAGES',
          value: 'App\\Notifications\\Fahrzeugmarkt\\DataprotectionSigned',
          text: 'Datenschutz',
          count: _.get(data, 'unreadDataprotectionSigned', 0)
        },
        {
          key: 'RENTALCONTRACT_MESSAGES',
          value: 'App\\Notifications\\Fahrzeugmarkt\\RentalcontractSigned',
          text: 'Leihverträge',
          count: _.get(data, 'unreadRentalcontractSigned', 0)
        },
        {
          key: 'LEADFEEDBACK_FEEDBACK',
          value: 'App\\Notifications\\Leads\\LeadfeedbackFeedbackReceived',
          text: 'Bewertung',
          count: _.get(data, 'unreadLeadfeedbackFeedbackReceived', 0)
        },
        {
          key: 'LEAD_ASSIGNMENTS',
          value: 'App\\Notifications\\Leads\\SetBearbeiterLead',
          text: 'Lead-Zuweisung',
          count: _.get(data, 'unreadSetBearbeiterLead', 0)
        },
        {
          key: 'CHECK_PROFITS',
          value: [
            'App\\Notifications\\Fahrzeugmarkt\\CheckProfits',
            'App\\Notifications\\Fahrzeugmarkt\\CheckUpdatedProfits',
            'App\\Notifications\\Fahrzeugmarkt\\CarRevenueWarning'
          ],
          text: 'Prüfung Kosten / Erträge',
          count: _.get(data, 'unreadCheckProfits', 0)
        },
        {
          key: 'OFFERS',
          value:
            'App\\Notifications\\Fahrzeugmarkt\\ChangeFahrzeugangebotprice',
          text: 'Inserate',
          count: _.get(data, 'unreadOffer', 0)
        },
        {
          key: 'CUSTOMER_MESSAGES',
          value: 'App\\Notifications\\Customers\\CustomerReceivedMail',
          text: 'Nachricht vom Kunden',
          count: _.get(data, 'unreadCustomerReceivedMail', 0)
        },
        {
          key: 'FORMREQUEST_FEEDBACK',
          value: 'App\\Notifications\\FormrequestFeedbackReceived',
          text: 'Formulare',
          count: _.get(data, 'unreadFormrequestFeedbackReceived', 0)
        },
        {
          key: 'ISSUE_MESSAGES',
          value: 'App\\Notifications\\Supportcenter\\IssueMailReceived',
          text: 'Kontaktcenter',
          count: _.get(data, 'unreadIssueMailReceived', 0)
        }
      ]
    }
  },

  getValuesForKeys(keys) {
    return this.buildNotificationTypes(null)
      .filter((item) => keys.includes(item.key))
      .flatMap((item) => item.value)
  }
}
