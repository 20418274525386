<template>
  <v-btn-toggle v-model="dark" v-bind="$attrs">
    <!-- Light -->
    <v-btn :value="false" :small="small">
      Light
      <v-icon right :x-small="small">$theme-light</v-icon>
    </v-btn>
    <!-- Dark -->
    <v-btn :value="true" :small="small">
      Dark
      <v-icon right :x-small="small">$theme-dark</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'ThemeSwitcher',

  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    // vuex two-way binding
    dark: {
      get() {
        return this.$store.state.theme.dark
      },
      set(val) {
        this.$store.commit('theme/setDark', val)
      }
    }
  }
}
</script>
