import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { sync } from 'vuex-router-sync'
import router from '@/router'

// Utils
import LocationUtils from '@/utils/location'

// modules
import layout from '@/store/modules/layout.js'
import theme from '@/store/modules/theme.js'
import auth from '@/store/modules/auth.js'
import progress from '@/store/modules/progress.js'
import filecenter from '@/store/modules/filecenter.js'
import freshcarflat from '@/store/modules/freshcarflat.js'
import freshcarflatAntrag from '@/store/modules/freshcarflatAntrag.js'
import config from '@/store/modules/config.js'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'theme', 'layout', 'config']
})

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    layout,
    theme,
    auth,
    progress,
    filecenter,
    freshcarflat,
    freshcarflatAntrag,
    config
  },
  state: {
    version: process.env.VUE_APP_VERSION || 0,
    versionApi: null,
    environment: process.env.NODE_ENV || 'not set',
    stage: process.env.VUE_APP_STAGE || 'not set',
    message: null,

    client: null // "freshforfinance" | "fahrzeugmarkt.online"
  },
  mutations: {
    setVersionApi(state, version) {
      state.versionApi = version
    },

    setClient(state, client) {
      state.client = client
    },

    setMessage(state, message) {
      state.message = message
    }
  },
  actions: {
    loadClient({ commit }) {
      return new Promise((resolve, reject) => {
        let client = LocationUtils.getClientFromDomain()
        commit('setClient', client)

        if (client) {
          resolve(client)
        } else {
          reject('NO_CLIENT_FOUND')
        }
      })
    }
  },
  getters: {},
  plugins: [vuexLocal.plugin]
})

// sync router with vuex (vuex-router-sync)
sync(store, router)

export default store
