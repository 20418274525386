import axios from 'axios'
import ExceptionUtils from '@/utils/exceptions.js'
import ApiUtils from '@/utils/api.js'

const urlPrefix = ApiUtils.getApiUrl() + '/auth'

export default {
  login({ username, password, type, client }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/login', { username, password, type, client })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  logout({ type } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/logout', { type })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  register({ email, password } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/register', {
          email,
          password
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  requestResetPassword({ email, client } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/request-password-reset', {
          email: email,
          client: client
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  resetPassword({ token, email, password, client } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/reset-password', {
          token: token,
          email: email,
          password: password,
          client: client
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  resendVerfication({ email } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/resend-verification', {
          email: email
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  verify({ token } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/verify', {
          token: token
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  }
}
