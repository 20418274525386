<template>
  <span v-bind="$attrs">{{ text }}</span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Version',

  computed: {
    ...mapState(['version', 'versionApi', 'stage']),

    text() {
      let textVersion = 'ui-' + this.version
      let textVersionApi = this.versionApi ? 'api-' + this.versionApi : null
      let textStage = this.stage

      // Join versions with '|' only if they are set
      return [textVersion, textVersionApi, textStage]
        .filter(Boolean)
        .join(' | ')
    }
  }
}
</script>
