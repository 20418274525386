import Vue from 'vue'

Vue.filter('userStatus', function (value) {
  if (!value) return 'Nicht gesetzt'

  switch (value) {
    case 'ONLINE':
      return 'verfügbar'
    case 'BUSY':
      return 'beschäftigt'
    case 'OFFLINE':
      return 'abwesend'

    default:
      return value
  }
})

Vue.filter('userHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    // login
    case 'USER_LOGIN':
      return 'User eingeloggt'
    default:
      return undefined // return default event text
  }
})
