import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'emailing-status-NEW': {
    component: IconFontawesome,
    props: {
      icon: 'folder-plus'
    }
  },
  'emailing-status-QUEUE': {
    component: IconFontawesome,
    props: {
      icon: 'angle-double-right'
    }
  },
  'emailing-status-PROCESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'angle-double-right',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-8 down-8 right-8',
          color: 'info'
        }
      ]
    }
  },
  'emailing-status-SENT': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'emailing-status-ERROR': {
    component: IconFontawesome,
    props: {
      icon: 'triangle-exclamation',
      color: 'error'
    }
  }
}
