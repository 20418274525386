<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>{{ meetingableData.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ meetingableData.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ meetingableData.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="meetingableData.to">
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn icon class="ma-0" :to="meetingableData.to" v-on="on">
            <v-icon small>$action-show</v-icon>
          </v-btn>
        </template>
        <span>{{ meetingableData.title }} anzeigen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemMeetingable',

  props: {
    meetingable: {
      type: Object,
      required: true
    }
  },

  computed: {
    meetingableData() {
      const type = this.meetingable.type

      switch (type) {
        case 'App\\Models\\leads\\Lead':
          return {
            icon: '$lead',
            title: 'Lead',
            subtitle: this.meetingable.description,
            to: {
              name: 'lead',
              params: { id: '' + this.meetingable.id }
            }
          }
        case 'App\\Models\\fahrzeugmarkt\\Customer':
          return {
            icon: '$customer',
            title: 'Kunde',
            subtitle: this.meetingable.description,
            to: {
              name: 'customers-customer',
              params: { id: '' + this.meetingable.id }
            }
          }
        default:
          return null
      }
    }
  }
}
</script>
