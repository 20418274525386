<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <template #activator="bind">
      <slot name="activator" v-bind="bind" />
    </template>

    <card-base
      dialog
      draggable
      :title="dialogTitle"
      :error="error"
      :loading="loading"
      :disabled="loading"
      show-actions
      show-actions-cancel
      @submit="submit"
      @close="close"
      @cancel="close"
    >
      <ValidationObserver ref="observer">
        <!-- title -->
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|max:255"
          name="Titel"
        >
          <v-text-field
            v-model="title"
            label="Titel"
            :error-messages="errors"
          />
        </ValidationProvider>

        <!-- description -->
        <ValidationProvider
          v-slot="{ errors }"
          rules="max:4096"
          name="Beschreibung"
        >
          <v-textarea
            v-model="description"
            label="Beschreibung"
            :error-messages="errors"
            counter="4096"
            no-resize
            clearable
          />
        </ValidationProvider>
      </ValidationObserver>
    </card-base>
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag'

import { CardBase } from '@freshsolutions/fresh-vue-cards'

export default {
  name: 'TodoSaveDialog',

  components: {
    CardBase
  },

  props: {
    todo: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      error: false,
      dialog: false,

      title: null,
      description: null
    }
  },

  computed: {
    dialogTitle() {
      if (this.todo) {
        return 'Aufgabe bearbeiten'
      } else {
        return 'Neue Aufgabe erstellen'
      }
    }
  },

  watch: {
    todo: {
      handler(value) {
        this.loadFromTodo(value)
      },
      immediate: true
    }
  },

  methods: {
    submit() {
      this.loading = true
      this.error = false

      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          // we want to update the existing todo
          this.$apollo
            .mutate({
              mutation: gql`
                mutation upsertTodo($input: UpsertTodoInput!) {
                  upsertTodo(input: $input) {
                    id
                  }
                }
              `,
              variables: {
                input: {
                  id: this.todo?.id,
                  title: this.title,
                  description: this.description
                }
              }
            })
            .then(() => {
              this.loading = false
              this.$emit('submit:success')
              this.close()
            })
            .catch(() => {
              this.loading = false
              this.error = true
              this.$emit('submit:fail')
            })
        } else {
          this.loading = false
          this.error = false
        }
      })
    },

    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.reset()
    },

    reset() {
      this.title = null
      this.description = null

      this.loadFromTodo(this.todo)

      this.loading = false
      this.error = false

      requestAnimationFrame(() => {
        if (this.$refs.observer) {
          this.$refs.observer.reset()
        }
      })
    },

    loadFromTodo(todo) {
      if (this.todo) {
        this.title = todo?.title || null
        this.description = todo?.description || null
      }
    }
  }
}
</script>
