<template>
  <v-text-field
    v-model.trim="search"
    label="Suchen"
    clearable
    outlined
    hide-details
    dense
    append-icon="$search"
    class="ma-2"
    @click:append="submit"
    @keydown.enter="submit"
  />
</template>

<script>
export default {
  name: 'AppNavigationGlobalsearch',

  data() {
    return {
      search: null
    }
  },

  methods: {
    submit() {
      this.$router.push({
        name: 'globalsearch',
        query: {
          search: this.search
        }
      })
    }
  }
}
</script>
