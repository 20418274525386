import Vue from 'vue'

Vue.filter('anrede', function (value) {
  switch (value) {
    case '1':
      return 'Herr'
    case '2':
      return 'Frau'
    case '3':
      return 'Firma'
    default:
      return ''
  }
})

Vue.filter('zahlweise', function (value) {
  switch (value) {
    case '23':
      return 'halbjährlich o. RZ'
    case '43':
      return 'vierteljährlich o. RZ'
    case '47':
      return 'Einmalbeitrag'
    case '48':
      return 'monatlich'
    case '49':
      return 'vierteljährlich'
    case '50':
      return 'halbjährlich'
    case '51':
      return 'jährlich'
    case '64':
      return 'monatlich o. RZ'
    case '84':
      return 'vierteljährlich RZ KRModul'
    default:
      return '-'
  }
})

Vue.filter('zahlweiseShort', function (value) {
  switch (value) {
    case '23':
      return 'hj. o. RZ'
    case '43':
      return 'vj. o. RZ'
    case '47':
      return 'Einmalbeitrag'
    case '48':
      return 'mtl.'
    case '49':
      return 'vj.'
    case '50':
      return 'hj.'
    case '51':
      return 'j.'
    case '64':
      return 'mtl. o. RZ'
    case '84':
      return 'vj. RZ KRModul'
    default:
      return '-'
  }
})

Vue.filter('finanzierungsart', function (value) {
  const { code, laufzeit } = value
  switch (code) {
    case 'FIN':
      return `Finanzierung (${laufzeit} Monate)`
    case 'LES':
      return `Finanzierung (${laufzeit} Monate)`
    case 'BAR':
      return 'Barkauf'
    default:
      return '-'
  }
})

Vue.filter('abstellplatz', function (value) {
  switch (value) {
    case 'FREI':
      return 'Im Freien'
    case 'CARP':
      return 'Carport'
    case 'GARG':
      return 'Garage'
    default:
      return '-'
  }
})

Vue.filter('partnerbeziehung', function (value) {
  switch (value) {
    case 'AN':
      return 'Arbeitnehmer'
    case 'BET':
      return 'Betreuer'
    case 'GVT':
      return 'Gruppenvertreter'
    case 'GF':
      return 'Geschäftsführer'
    default:
      return '-'
  }
})
