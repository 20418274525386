import Vue from 'vue'

Vue.filter('bgRemovalProcessStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'DRAFT':
      return 'Entwurf'
    case 'IN_PROGRESS':
      return 'In Arbeit'
    case 'ERROR':
      return 'Fehler'
    case 'OK':
      return 'Abgeschlossen'

    default:
      return value
  }
})

Vue.filter('bgRemovalProcessImageStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'DRAFT':
      return 'Entwurf'
    case 'IN_PROGRESS':
      return 'In Arbeit'
    case 'ERROR':
      return 'Fehler'
    case 'OK':
      return 'Abgeschlossen'

    default:
      return value
  }
})
