import UserApi from '@/api/user.js'

const validator = {
  lazy: false,
  message: 'Dieser Nutzer existiert bereits.',
  async validate(value) {
    const { unique } = await UserApi.isUnique({ username: value })

    return {
      valid: unique
    }
  }
}

export default validator
