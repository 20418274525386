export default {
  getClientFromDomain() {
    let host = window.location.host
    host = host.toLowerCase()

    if (host.includes('freshforfinance.de')) {
      return 'freshforfinance'
    } else if (host.includes('fahrzeugmarkt.online')) {
      return 'fahrzeugmarkt.online'
    } else {
      return null
    }
  }
}
