<template>
  <v-container class="ma-0 pa-0 mt-5">
    <v-skeleton-loader type="paragraph@3" :loading="loading">
      <v-data-iterator
        v-if="todos.length > 0"
        :items="todos"
        :footer-props="{
          'items-per-page-options': [itemsPerPage, -1],
          'disable-items-per-page': true
        }"
        :items-per-page.sync="itemsPerPage"
        :hide-default-footer="todos.length <= itemsPerPage"
      >
        <template #default="props">
          <v-row dense>
            <v-col v-for="item in props.items" :key="item.id" cols="12">
              <todos-list-todo :todo="item" @submit:success="$emit('reload')" />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>

      <div v-else class="ma-16 text-h5 text-center">
        <span class="text-h5"> Sie haben keine offenen Aufgaben 🎉 </span>
        <br />
        <span class="text-body-1">
          Behalten Sie den Überblick über Ihre offenen Tätigkeiten und Aufgaben.
          Erstellen Sie jetzt Ihre nächste Aufgabe!
        </span>
        <br />

        <todo-save-dialog @submit:success="$emit('reload')">
          <template #activator="{ on }">
            <v-btn color="primary" depressed class="mt-8" v-on="on">
              <v-icon left small>$action-add</v-icon>
              Aufgabe Erstellen
            </v-btn>
          </template>
        </todo-save-dialog>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import TodosListTodo from './TodosListTodo'
import TodoSaveDialog from './TodoSaveDialog'

export default {
  name: 'TodosList',

  components: {
    TodosListTodo,
    TodoSaveDialog
  },

  props: {
    todos: {
      type: Array,
      required: false,
      default: () => []
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      itemsPerPage: 5
    }
  }
}
</script>
