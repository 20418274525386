<template>
  <v-app>
    <!-- navigation stuff -->
    <app-side-sheet v-if="authorized" />
    <app-app-bar v-if="authorized" />
    <app-navigation-drawer v-if="authorized" />

    <!-- content -->
    <app-content />

    <!-- footer -->
    <app-footer />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import log from 'loglevel'

import HealthcheckUtils from '@/utils/healthcheck'

import AppSideSheet from '@/layout/AppSideSheet'
import AppAppBar from '@/layout/AppAppBar'
import AppNavigationDrawer from '@/layout/AppNavigationDrawer'
import AppContent from '@/layout/AppContent'
import AppFooter from '@/layout/AppFooter'

export default {
  name: 'App',

  components: {
    AppSideSheet,
    AppAppBar,
    AppNavigationDrawer,
    AppContent,
    AppFooter
  },

  metaInfo() {
    return {
      title: 'Portal - ' + this.header.header_title_text,
      link: [
        { rel: 'icon', href: this.faviconSrc },
        { rel: 'apple-touch-icon', href: this.faviconSrc },
        this.client
          ? { rel: 'manifest', href: `/manifest-${this.client}.json` }
          : undefined
      ].filter(Boolean),
      meta: [{ name: 'theme-color', content: this.colors?.primary }]
    }
  },

  computed: {
    ...mapState(['client']),

    ...mapGetters({
      authorized: 'auth/isAuthorized',
      images: 'theme/images',
      header: 'theme/header',
      colors: 'theme/colors'
    }),

    faviconSrc() {
      return (
        this.images.favicon ||
        process.env.BASE_URL + 'img/favicon/placeholder.png'
      )
    }
  },

  mounted() {
    log.info("App version: '" + this.$store.state.version + "'")
    log.info("App node-environment: '" + this.$store.state.environment + "'")
    log.info("App stage: '" + this.$store.state.stage + "'")

    HealthcheckUtils.start()
  },

  beforeDestroy() {
    HealthcheckUtils.stop()
  }
}
</script>

<style lang="scss">
@import 'src/assets/css/index.scss';
</style>
