import moment from 'moment'

const validator = {
  message(field, { max }) {
    const formatted = moment(max).format('DD.MM.YYYY')
    return field + ' muss vor dem ' + formatted + ' liegen.'
  },
  params: ['max'],
  validate(value, { max }) {
    return max ? moment(value).isBefore(max) : true
  }
}
export default validator
