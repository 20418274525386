import Vue from 'vue'
import Vuetify, {
  VTextField,
  VSelect,
  VRow,
  VList,
  VExpansionPanels
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'
import de from 'vuetify/es5/locale/de'
import icons from './icons/index.js'
import { detect } from 'detect-browser'
import store from '@/store/index.js'

function isIE() {
  const browser = detect()
  return browser && browser.name === 'ie'
}

Vue.use(Vuetify, {
  components: {
    // Import some components here. Otherwise they will throw warnings on build ("Conflicting order between")
    VTextField,
    VSelect,
    // Necessary for @freshsolutions/fresh-vue
    VRow,
    // Necessary for draggable (AdvancedDataTableConfigureDialog, @freshsolutions/fresh-vue-forms)
    VList,
    VExpansionPanels
  },
  directives: {
    Ripple
  }
})

const vuetify = new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    options: {
      customProperties: !isIE() // we can only enable this stuff if we are not in internet explorer. See: https://github.com/vuetifyjs/vuetify/issues/8235
    },
    dark: store.state.theme.dark,
    themes: store.state.theme.themes
  },
  icons: {
    iconfont: 'faSvg',
    values: icons
  },
  breakpoint: {
    mobileBreakpoint: 'sm'
  }
})

store.watch(
  (state) => state.theme.dark,
  (val) => {
    vuetify.framework.theme.dark = val
  }
)

store.watch(
  (state) => state.theme.themes,
  (val) => {
    vuetify.framework.theme.themes.dark = val.dark
    vuetify.framework.theme.themes.light = val.light
  },
  {
    deep: true
  }
)

export default vuetify
