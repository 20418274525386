<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>$comment</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title> Nachricht </v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        {{ message | emptyString }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemMessage',

  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>
