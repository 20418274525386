<template>
  <v-card outlined :class="{ unread: unread }">
    <v-card-title class="pt-2 pb-0 pr-0">
      <span v-if="title">{{ title }}</span>

      <v-spacer />

      <v-tooltip left>
        <template #activator="{ on }">
          <v-scale-transition origin="center center">
            <v-icon v-if="unread" color="warning" class="mr-4" v-on="on">
              $notification-unread
            </v-icon>
          </v-scale-transition>
        </template>
        <span>ungelesen</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text class="py-0">
      <v-row dense>
        <v-col cols="12">
          <span class="font-italic">
            {{ description }}
          </span>
        </v-col>
        <v-col cols="12">
          <notifications-list-notification-data
            v-if="data"
            :type="notification.type"
            :data="data"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pl-4 pt-0">
      <span class="text-caption">
        {{ notification.created_at | dateAndTime }}
      </span>

      <v-spacer />

      <v-fade-transition>
        <div v-if="notification.read_at">
          <v-tooltip v-if="notification.became_superfluous_at" left>
            <template #activator="{ on }">
              <v-icon small color="info" class="mr-1" v-on="on"> $info </v-icon>
            </template>
            <span>
              Wurde automatisch als gelesen markiert, weil die Benachrichtigung
              nicht mehr relevant ist.
            </span>
          </v-tooltip>

          <span class="text-caption">
            {{ notification.read_at | dateAndTime }}
          </span>
        </div>
      </v-fade-transition>

      <list-options
        :actions="[
          {
            icon: '$action-mark-as-read',
            text: 'Als gelesen markieren',
            hidden: !!notification.read_at,
            action: markAsRead,
            loading: loading
          },
          {
            icon: '$action-mark-as-unread',
            text: 'Als ungelesen markieren',
            hidden: !notification.read_at,
            action: markAsUnread,
            loading: loading
          }
        ]"
        no-menu
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash'
import gql from 'graphql-tag'

import {
  NOTIFICATION as FRAGMENT_NOTIFICATION,
  NOTIFICATION_COUNTS as FRAGMENT_NOTIFICATION_COUNTS
} from '@/graphql/fragments.js'

import { ListOptions } from '@freshsolutions/fresh-vue'
import NotificationsListNotificationData from './NotificationsListNotificationData'

export default {
  name: 'NotificationsListNotification',

  components: {
    ListOptions,
    NotificationsListNotificationData
  },

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    /**
     * The JSON should be like this:
     * {
     *  "title": "Lorem Ipsum",
     *  "description": "Lorem Ipsum",
     *  "data": {
     *    "object-1": {},
     *    "object-2": {}
     *  }
     * }
     */
    json() {
      return JSON.parse(this.notification.data)
    },

    title() {
      return _.get(this.json, 'title')
    },

    description() {
      return _.get(this.json, 'description')
    },

    data() {
      return _.get(this.json, 'data')
    },

    unread() {
      return !this.notification.read_at
    }
  },

  methods: {
    markAsRead() {
      this.loading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateMe($input: UpdateMeInput!) {
              updateMe(input: $input) {
                id

                ...NotificationCounts

                notifications {
                  ...Notification
                }
              }
            }
            ${FRAGMENT_NOTIFICATION_COUNTS}
            ${FRAGMENT_NOTIFICATION}
          `,
          variables: {
            input: {
              notifications: {
                markAsRead: [this.notification.id]
              }
            }
          }
        })
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    markAsUnread() {
      this.loading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateMe($input: UpdateMeInput!) {
              updateMe(input: $input) {
                id

                ...NotificationCounts

                notifications {
                  ...Notification
                }
              }
            }
            ${FRAGMENT_NOTIFICATION_COUNTS}
            ${FRAGMENT_NOTIFICATION}
          `,
          variables: {
            input: {
              notifications: {
                markAsUnread: [this.notification.id]
              }
            }
          }
        })
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.unread {
  border-color: var(--v-warning-base);
  border-width: 2px;
}
</style>
