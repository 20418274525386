import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  /**
   * theme icons
   */
  'theme-light': {
    component: IconFontawesome,
    props: {
      icon: 'sun'
    }
  },
  'theme-dark': {
    component: IconFontawesome,
    props: {
      icon: 'moon'
    }
  }
}
