import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  // Imports
  'imports-status-CREATING': {
    component: IconFontawesome,
    props: {
      icon: 'clock',
      color: 'info'
    }
  },
  'imports-status-CREATED': {
    component: IconFontawesome,
    props: {
      icon: 'copy',
      color: 'info'
    }
  },
  'imports-status-FINISHED': {
    component: IconFontawesome,
    props: {
      icon: 'circle-check',
      color: 'success'
    }
  },
  'imports-status-FAILURE': {
    component: IconFontawesome,
    props: {
      icon: 'triangle-exclamation',
      color: 'error'
    }
  }
}
