import colors from 'vuetify/lib/util/colors'
import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'freshbike-bike': {
    component: IconFontawesome,
    props: {
      icon: 'bicycle'
    }
  },

  'freshbike-bike-brand': {
    component: IconFontawesome,
    props: {
      icon: 'bicycle'
    }
  },
  'freshbike-bike-style': {
    component: IconFontawesome,
    props: {
      icon: 'bicycle'
    }
  },
  'freshbike-bike-type': {
    component: IconFontawesome,
    props: {
      icon: 'bicycle'
    }
  },
  'freshbike-bike-identification': {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  'freshbike-bike-purchase-price': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'freshbike-bike-premium': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'freshbike-bike-purchase-date': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  'freshbike-bike-invoice': {
    component: IconFontawesome,
    props: {
      icon: 'receipt'
    }
  },

  'freshbike-status-SENT': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'freshbike-bike-status-SENT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'orange',
      iconChildren: [
        {
          icon: 'angle-double-right',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshbike-bike-status-OK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshbike-bike-status-CANCELED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },

  // Schadenmeldung / Claims
  'freshbike-claim-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'question',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshbike-claim-status-NEW': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshbike-claim-status-CHECK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'grey',
      iconChildren: [
        {
          icon: 'list-check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshbike-claim-status-SENT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'orange',
      iconChildren: [
        {
          icon: 'angle-double-right',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshbike-claim-status-IN_PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshbike-claim-status-CLOSED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'freshbike-claim-guilty-type': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  }
}
