function toDate(value) {
  const split = value.split(':')
  const hours = split[0]
  const minutes = split[1]

  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

const validator = {
  message: '{_field_} muss maximal {_min_} sein.',
  params: ['max'],
  validate(value, { min }) {
    const time = toDate(value)
    const timeMax = toDate(min)

    if (time && timeMax) {
      return time <= timeMax
    } else {
      return true
    }
  }
}
export default validator
