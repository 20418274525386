import store from '@/store'
import log from 'loglevel'

/**
 * This will be called once the app is loaded / reloaded (browser refresh)
 */
export default new Promise((resolve, reject) => {
  Promise.resolve(true)
    .then(() => {
      /* init client */
      return store.dispatch('loadClient')
    })
    .then(() => {
      if ('serviceWorker' in navigator) {
        try {
          navigator.serviceWorker.register(
            `/serviceworker.js?client=${store.state.client}`
          )
        } catch (e) {
          log.error('Service worker registration failed!')
        }

        return Promise.resolve()
      }
    })
    .then(() => {
      /* load theme */

      // watch for changes in the color-scheme
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', () => {
          store.dispatch('theme/loadColorScheme')
        })

      // load current theme
      return store.dispatch('theme/load')
    })
    .then(() => {
      // do other init steps if necessary
      // ...
      resolve()
    })
    .catch((e) => {
      if (e === 'NO_CLIENT_FOUND' && process.env.VUE_APP_ERROR_REDIRECT_URL) {
        // if there is no client we will redirect the user to the product-page
        window.location.href = process.env.VUE_APP_ERROR_REDIRECT_URL
      } else {
        reject(e)
      }
    })
})
