import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/dist/cookieconsent.js'
import { bootstrap, optOut, optIn } from 'vue-gtag'

window.cookieconsent = window.initCookieConsent()

var updateSettings = function () {
  if (window.cookieconsent) {
    // analytics
    if (window.cookieconsent.allowedCategory('analytics')) {
      enableAnalytics()
    } else {
      optOut()
    }
  }
}

var enableAnalytics = function () {
  if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
    bootstrap().then(() => {
      optIn()
    })
  }
}

window.cookieconsent.run({
  revision: 0, // change this to show cookie-banner again

  current_lang: 'de',
  autoclear_cookies: true, // default: false
  force_consent: true, // default: false

  gui_options: {
    consent_modal: {
      layout: 'cloud', // box/cloud/bar
      position: 'middle center', // bottom/middle/top + left/right/center
      transition: 'zoom' // zoom/slide
    },
    settings_modal: {
      layout: 'box', // box/bar
      // position: 'left',           // left/right
      transition: 'zoom' // zoom/slide
    }
  },

  onAccept: updateSettings,
  onChange: updateSettings,

  languages: {
    de: {
      consent_modal: {
        title: 'Privatsphäre-Einstellungen',
        description:
          'Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten von dir (z.B. IP-Adresse), um z.B. Inhalte und Anzeigen zu personalisieren, Medien von Drittanbietern einzubinden oder Zugriffe auf unsere Website zu analysieren. Die Datenverarbeitung kann auch erst in Folge gesetzter Cookies stattfinden. Wir teilen diese Daten mit Dritten, die wir in den Privatsphäre-Einstellungen benennen. <br /><br /> Die Datenverarbeitung kann mit deiner Einwilligung oder auf Basis eines berechtigten Interesses erfolgen, dem du in den Privatsphäre-Einstellungen widersprechen kannst. Du hast das Recht, nicht einzuwilligen und deine Einwilligung zu einem späteren Zeitpunkt zu ändern oder zu widerrufen. Weitere Informationen zur Verwendung deiner Daten findest du in unserer <a href="https://www.fahrzeugmarkt.online/datenschutz/" class="cc-link">Datenschutzerklärung</a>. <br /><br /> <button type="button" data-cc="c-settings" class="cc-link">Privatsphäre-Einstellungen individuell festlegen</button>',
        primary_btn: {
          text: 'Alle akzeptieren',
          role: 'accept_all' // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Weiter ohne Einwilligung',
          role: 'accept_necessary' // 'settings' or 'accept_necessary'
        }
      },
      settings_modal: {
        title: 'Individuelle Privatsphäre-Einstellungen',
        save_settings_btn: 'Individuelle Auswahl speichern',
        accept_all_btn: 'Alle akzeptieren',
        reject_all_btn: 'Weiter ohne Einwilligung',
        close_btn_label: 'Schließen',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Typ' },
          { col3: 'Anbieter' },
          { col4: 'Ablauf' },
          { col5: 'Beschreibung' }
        ],
        blocks: [
          {
            description:
              'Im Folgenden findest du eine Übersicht über alle Services, die von dieser Website genutzt werden. Du kannst dir detaillierte Informationen zu jedem Service ansehen und ihm einzeln zustimmen oder von deinem Widerspruchsrecht Gebrauch machen. <a href="https://www.fahrzeugmarkt.online/datenschutz/" class="cc-link">Datenschutzerklärung</a>.'
          },
          {
            title: 'Essenziell',
            description:
              'Essenzielle Services sind für die grundlegende Funktionalität der Website erforderlich. Sie enthalten nur technisch notwendige Services. Diesen Services kann nicht widersprochen werden.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true
            },
            cookie_table: [
              {
                col1: 'cc_cookie',
                col2: 'HTTP Cookie',
                col3: 'Webseite',
                col4: '6 Monate',
                col5: 'Speichern der Benutzer-Auswahl der Privatsphäre-Einstellungen.'
              }
            ]
          },
          {
            title: 'Statistik ',
            description:
              'Statistik-Services werden benötigt, um pseudonymisierte Daten über die Besucher der Website zu sammeln. Die Daten ermöglichen es uns, die Besucher besser zu verstehen und die Website zu optimieren.',
            toggle: {
              value: 'analytics',
              enabled: false,
              readonly: false
            },
            cookie_table: [
              {
                col1: '_ga',
                col2: 'HTTP Cookie',
                col3: 'Google Ireland Limited',
                col4: '2 Jahre',
                col5: 'Unterscheidung der Besucher.'
              },
              {
                col1: '^_ga', // match all cookies starting with "_ga"
                col2: 'HTTP Cookie',
                col3: 'Google Ireland Limited',
                col4: '2 Jahre',
                col5: 'Speichern der Sitzung.',
                is_regex: true
              }
            ]
          }
        ]
      }
    }
  }
})
