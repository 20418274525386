import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  issues: {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  },
  issue: {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },
  issueType: {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },

  // actions
  'action-issue-send-message': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'action-issue-close': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'action-issue-open': {
    component: IconFontawesome,
    props: {
      icon: 'rotate-right',
      color: 'success'
    }
  },
  'action-issue-create': {
    component: IconFontawesome,
    props: {
      icon: 'circle-plus'
    }
  },

  // category
  'issue-category-IN': {
    component: IconFontawesome,
    props: {
      icon: 'right-to-bracket'
    }
  },
  'issue-category-OUT': {
    component: IconFontawesome,
    props: {
      icon: 'right-from-bracket'
    }
  },
  'issue-category-FFF': {
    component: IconFontawesome,
    props: {
      icon: 'right-to-bracket'
    }
  },
  'issue-category-FMO': {
    component: IconFontawesome,
    props: {
      icon: 'right-to-bracket'
    }
  },

  // status
  'issue-status-INFO': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'info',
      iconChildren: [
        {
          icon: 'info',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'issue-status-OPEN': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'error',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'issue-status-ANSWER': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'warning',
      iconChildren: [
        {
          icon: 'envelope',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'issue-status-CLOSED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  }
}
