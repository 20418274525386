import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  adjustment: {
    component: IconFontawesome,
    props: {
      icon: 'sliders'
    }
  },
  'adjustment-1': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'sliders',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: '1',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'adjustment-2': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'sliders',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: '2',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'adjustment-3': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'sliders',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: '3',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'required-ok': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'required-up': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-up',
      color: 'success'
    }
  },
  'required-down': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-down',
      color: 'error'
    }
  }
}
