<template>
  <v-dialog v-model="dialog" max-width="600">
    <!-- Activator -->
    <template #activator="bind">
      <slot name="activator" v-bind="bind" />
    </template>

    <debug-card dialog @close="dialog = false" />
  </v-dialog>
</template>

<script>
import DebugCard from '@/components/DebugCard'

export default {
  name: 'DebugDialog',

  components: {
    DebugCard
  },

  data() {
    return {
      dialog: false
    }
  }
}
</script>
