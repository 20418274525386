<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>$storage</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ $_.get(storage, 'storageevent.type') | emptyString }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ $_.get(storage, 'storageevent.period') | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="$_.get(storage, 'storageevent.location')">
        {{ $_.get(storage, 'storageevent.location') }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            icon
            class="ma-0"
            :to="{
              name: 'workshop-storage',
              params: { id: String(storage.id) }
            }"
            v-on="on"
          >
            <v-icon small>$action-show</v-icon>
          </v-btn>
        </template>
        <span>Einlagerung anzeigen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemStorage',

  props: {
    storage: {
      type: Object,
      required: true
    }
  }
}
</script>
