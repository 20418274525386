import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  workshoprepairreport: {
    component: IconFontawesome,
    props: {
      icon: 'file-pen'
    }
  },

  // misc
  'workshoprepairreport-sumofcosts': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },

  // actions
  'action-workshoprepairreport-cancel': {
    component: IconFontawesome,
    props: {
      icon: 'xmark',
      color: 'error'
    }
  },
  'action-workshoprepairreport-complete': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'action-workshoprepairreport-reopen': {
    component: IconFontawesome,
    props: {
      icon: 'rotate-left'
    }
  },

  // tabs
  'workshoprepairreport-items': {
    component: IconFontawesome,
    props: {
      icon: 'screwdriver-wrench'
    }
  },

  // status
  'workshoprepairreport-status-IN_PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'forward',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'workshoprepairreport-status-OK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'workshoprepairreport-status-CANCELED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },

  // workshoprepairreportitem condition
  'workshoprepairreport-condition-VERY_GOOD': {
    component: IconFontawesome,
    props: {
      icon: 'face-laugh'
    }
  },
  'workshoprepairreport-condition-GOOD': {
    component: IconFontawesome,
    props: {
      icon: 'face-smile'
    }
  },
  'workshoprepairreport-condition-OK': {
    component: IconFontawesome,
    props: {
      icon: 'face-meh'
    }
  },
  'workshoprepairreport-condition-BAD': {
    component: IconFontawesome,
    props: {
      icon: 'face-frown'
    }
  },
  'workshoprepairreport-condition-REPAIR': {
    component: IconFontawesome,
    props: {
      icon: 'screwdriver-wrench'
    }
  }
}
