import iban from 'iban'

const validator = {
  message: 'Bitte geben Sie eine gültige IBAN ein.',
  validate(value) {
    return iban.isValid(value)
  }
}

export default validator
