<template>
  <div>
    <slot
      name="activator"
      v-bind="{
        disabled: disabled,
        loading: loading,
        on: {
          click: open
        }
      }"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'PersonalmarktOnlineLink',

  apollo: {
    access: {
      query: gql`
        query me {
          me {
            id
            setting(key: "FZM_PORTALS_PMO_ACCESS")
          }
        }
      `,
      update: (data) => (data.me.setting ? JSON.parse(data.me.setting) : []),
      fetchPolicy: 'network-only'
    }
  },

  data() {
    return {
      access: {}
    }
  },

  computed: {
    loading() {
      return this.$apollo.queries.access.loading
    },

    disabled() {
      return !(this.access?.host && this.access?.token) || this.loading
    }
  },

  methods: {
    open() {
      const host = this.access?.host
      const token = this.access?.token

      if (host && token) {
        window.open(`${host}public/v1/sso?token=${token}`)
      }
    }
  }
}
</script>
