import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  forms: {
    component: IconFontawesome,
    props: {
      icon: 'file-pen'
    }
  },
  formrequests: {
    component: IconFontawesome,
    props: {
      icon: 'file-pen'
    }
  },

  // fields
  'form-field-text': {
    component: IconFontawesome,
    props: {
      icon: 'font'
    }
  },
  'form-field-textarea': {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open-text'
    }
  },
  'form-field-select': {
    component: IconFontawesome,
    props: {
      icon: 'list'
    }
  }
}
