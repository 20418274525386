export default {
  namespaced: true,

  state: {
    loading: false,
    progress: 0,
    indeterminate: false,
    block: true,
    text: ''
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setProgress(state, progress) {
      state.progress = progress
    },
    setIndeterminate(state, indeterminate) {
      state.indeterminate = !!indeterminate
    },
    setBlock(state, block) {
      state.block = block
    },
    setText(state, text) {
      state.text = text
    }
  },

  actions: {
    reset({ commit }) {
      commit('setLoading', false)
      commit('setProgress', 0)
      commit('setIndeterminate', false)
      commit('setText', '')
    },

    start({ commit }, { indeterminate, text, block = true } = {}) {
      commit('setLoading', true)
      commit('setProgress', 0)
      commit('setIndeterminate', indeterminate)
      commit('setText', text)
      commit('setBlock', block)
    },

    stop({ dispatch }) {
      dispatch('reset')
    },

    update({ commit }, { progress, text } = {}) {
      commit('setProgress', progress)
      commit('setText', text)
    }
  }
}
