import Vue from 'vue'

Vue.filter('taxType', function (value) {
  if (!value) return ''

  switch (value) {
    case 'GROSS':
      return 'Brutto'
    case 'NET':
      return 'Netto'
    case 'NONE':
    default:
      return 'MwSt. frei'
  }
})
