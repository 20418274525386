<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn v-bind="$attrs" :loading="loading" icon v-on="on" @click="submit">
        <v-icon small>$action-mark-as-read-all</v-icon>
      </v-btn>
    </template>
    <span>Alle als gelesen markieren</span>
  </v-tooltip>
</template>

<script>
import gql from 'graphql-tag'
import { AlertUtils } from '@freshsolutions/fresh-vue'

import {
  NOTIFICATION as FRAGMENT_NOTIFICATION,
  NOTIFICATION_COUNTS as FRAGMENT_NOTIFICATION_COUNTS
} from '@/graphql/fragments.js'

export default {
  name: 'ButtonMarkAllAsRead',

  data() {
    return {
      loading: false
    }
  },

  methods: {
    submit() {
      AlertUtils.fireQuestion({
        text: 'Möchten Sie wirklich alle Benachrichtigungen als gelesen markieren? Ihr derzeit ausgewählter Filter wird dabei nicht berücksichtigt.',
        icon: 'warning'
      }).then((result) => {
        if (result.value) {
          this.loading = true
          this.$apollo
            .mutate({
              mutation: gql`
                mutation markAllMyNotificationsAsRead {
                  markAllMyNotificationsAsRead {
                    id

                    ...NotificationCounts

                    notifications {
                      ...Notification
                    }
                  }
                }
                ${FRAGMENT_NOTIFICATION_COUNTS}
                ${FRAGMENT_NOTIFICATION}
              `
            })
            .then(() => {
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
