import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'maillogs-simple-view-email-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'circle-xmark',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'maillogs-simple-view-email-status-FAILED': {
    component: IconFontawesome,
    props: {
      icon: 'triangle-exclamation',
      color: 'error'
    }
  },
  'maillogs-simple-view-email-status-OPENED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'circle-check',
      color: 'success',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-8 down-8 right-8',
          color: 'info'
        }
      ]
    }
  },
  'maillogs-simple-view-email-status-DELIVERED': {
    component: IconFontawesome,
    props: {
      icon: 'circle-check',
      color: 'success'
    }
  },
  'maillogs-simple-view-email-status-SENT': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  }
}
