<template>
  <!-- render appBar from vue-router, if there is one -->
  <router-view v-if="renderRouterView" name="appBar"></router-view>

  <!-- otherwise render a default app-bar -->
  <app-bar-base v-else />
</template>

<script>
import _ from 'lodash'

import AppBarBase from '@/layout/AppBarBase'

export default {
  name: 'AppAppBar',

  components: {
    AppBarBase
  },

  computed: {
    renderRouterView() {
      return !!_.get(this.$route, 'matched[0].components.appBar', false)
    }
  }
}
</script>
