import axios from 'axios'
import ExceptionUtils from '@/utils/exceptions.js'
import ApiUtils from '@/utils/api.js'

const urlPrefix = ApiUtils.getApiUrl() + '/healthcheck'

export default {
  index(client = null) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix, { params: { client } })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  }
}
