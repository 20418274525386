import moment from 'moment'

const validator = {
  message(field) {
    return field + ' muss in der Zukunft liegen.'
  },
  validate(value) {
    const today = moment()
    return moment(value).isAfter(today)
  }
}
export default validator
