<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn v-bind="$attrs" icon v-on="on" @click="$emit('click', $event)">
        <v-icon small>$action-reload</v-icon>
      </v-btn>
    </template>
    <span>Aktualisieren</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ButtonReload'
}
</script>
