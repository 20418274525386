import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'trade-in-process': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'list-check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },

  'trade-in-process-summary': {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  },

  // misc
  'trade-in-process-schwacke-price': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'trade-in-process-dat-price': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'trade-in-process-appraisal-value': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },

  // actions
  'action-trade-in-process-cancel': {
    component: IconFontawesome,
    props: {
      icon: 'xmark',
      color: 'error'
    }
  },
  'action-trade-in-process-complete': {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  'action-trade-in-process-reopen': {
    component: IconFontawesome,
    props: {
      icon: 'rotate-left'
    }
  },

  // tabs
  'trade-in-process-valuation': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'trade-in-process-assessment': {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  },
  'trade-in-process-certificate': {
    component: IconFontawesome,
    props: {
      icon: 'certificate'
    }
  },
  'trade-in-process-purchase': {
    component: IconFontawesome,
    props: {
      icon: 'handshake'
    }
  },

  // status
  'trade-in-process-status-IN_PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'forward',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'trade-in-process-status-OK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'trade-in-process-status-CANCELED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  }
}
