import Vue from 'vue'
import FreshcarflatApi from '@/api/freshcarflat.js'
import moment from 'moment'
import _ from 'lodash'
import ExceptionUtils from '@/utils/exceptions.js'

export default {
  namespaced: true,

  state: {
    status: 'OK',
    filter: {
      daterange: {
        start:
          moment().get('month') === 0
            ? moment().subtract(1, 'year').startOf('year')
            : moment().startOf('year'),
        end: moment().endOf('month')
      },
      autohaeuser: [],
      vorgang: null,
      status: [],
      verkaeufer: [],
      evb_nummer: null,
      kfz_kennzeichen: null,
      vorname: null,
      nachname: null,
      zusatz: null
    },
    autohaeuser: [],
    ranglisteView: 'TABLE',

    // Dialogs
    // eVB eintragen
    dialogEvb: false,
    dialogEvbAntragId: null,
    dialogEvbFahrzeugId: null,
    dialogEvbPromiseHandler: {},

    // Angebot versenden
    dialogAngebotVersenden: false,
    dialogAngebotVersendenFahrzeugId: null,
    dialogAngebotVersendenEmailVerkaeufer: null,
    dialogAngebotVersendenEmailVersicherungsnehmer: null,
    dialogAngebotVersendenPromiseHandler: {},

    // Fahrzeug aufheben
    dialogFahrzeugAufheben: false,
    dialogFahrzeugAufhebenFahrzeug: null,
    dialogFahrzeugAufhebenPromiseHandler: {},

    // Fahrzeugaufhebung planen
    dialogFahrzeugAufhebenPlanen: false,
    dialogFahrzeugAufhebenPlanenFahrzeug: null,
    dialogFahrzeugAufhebenPlanenPromiseHandler: {},

    // Verlaengerung:
    dialogFahrzeugVerlaengern: false,
    dialogFahrzeugVerlaengernFahrzeug: null,
    dialogFahrzeugVerlaengernPartnernummer: null,
    dialogFahrzeugVerlaengernPromiseHandler: {}
  },

  mutations: {
    startLoading(state) {
      state.status = 'LOADING'
    },

    finishLoading(state, ok) {
      if (ok) {
        state.status = 'OK'
      } else {
        state.status = 'ERROR'
      }
    },

    setFilter(state, filter) {
      state.filter = filter
    },

    setAutohaeuser(state, autohaeuser) {
      state.autohaeuser = autohaeuser
    },

    setStandorte(state, data) {
      Vue.set(state.autohaeuser[data.index], 'standorte', data.standorte)
    },

    setVerkaeufer(state, { index, indexAutohaus, verkaeufer }) {
      if (index >= 0) {
        Vue.set(
          state.autohaeuser[indexAutohaus].standorte[index],
          'verkaeufer',
          verkaeufer
        )
      } else {
        // There are no Standorte, so we will add the Verkaeufer to the autohaus directly
        Vue.set(state.autohaeuser[indexAutohaus], 'verkaeufer', verkaeufer)
      }
    },

    setRanglisteView(state, ranglisteView) {
      state.ranglisteView = ranglisteView
    },

    // Dialogs

    // eVB eintragen
    setDialogEvb(state, dialogEvb) {
      state.dialogEvb = dialogEvb
    },
    setDialogEvbAntragId(state, antragId) {
      state.dialogEvbAntragId = antragId
    },
    setDialogEvbFahrzeugId(state, fahrzeugId) {
      state.dialogEvbFahrzeugId = fahrzeugId
    },
    setDialogEvbPromiseHandler(state, promiseHandler) {
      state.dialogEvbPromiseHandler = promiseHandler
    },

    // Angebot versenden
    setDialogAngebotVersenden(state, dialogAngebotVersenden) {
      state.dialogAngebotVersenden = dialogAngebotVersenden
    },
    setDialogAngebotVersendenFahrzeugId(state, fahrzeugId) {
      state.dialogAngebotVersendenFahrzeugId = fahrzeugId
    },
    setDialogAngebotVersendenEmailVerkaeufer(state, emailVerkaeufer) {
      state.dialogAngebotVersendenEmailVerkaeufer = emailVerkaeufer
    },
    setDialogAngebotVersendenEmailVersicherungsnehmer(
      state,
      emailVersicherungsnehmer
    ) {
      state.dialogAngebotVersendenEmailVersicherungsnehmer =
        emailVersicherungsnehmer
    },
    setDialogAngebotVersendenPromiseHandler(state, promiseHandler) {
      state.dialogAngebotVersendenPromiseHandler = promiseHandler
    },

    // Fahrzeug aufheben
    setDialogFahrzeugAufheben(state, dialogFahrzeugAufheben) {
      state.dialogFahrzeugAufheben = dialogFahrzeugAufheben
    },
    setDialogFahrzeugAufhebenFahrzeug(state, fahrzeug) {
      state.dialogFahrzeugAufhebenFahrzeug = fahrzeug
    },
    setDialogFahrzeugAufhebenPromiseHandler(state, promiseHandler) {
      state.dialogFahrzeugAufhebenPromiseHandler = promiseHandler
    },

    // Fahrzeugaufhebung planen
    setDialogFahrzeugAufhebenPlanen(state, dialogFahrzeugAufhebenPlanen) {
      state.dialogFahrzeugAufhebenPlanen = dialogFahrzeugAufhebenPlanen
    },
    setDialogFahrzeugAufhebenPlanenFahrzeug(state, fahrzeug) {
      state.dialogFahrzeugAufhebenPlanenFahrzeug = fahrzeug
    },
    setDialogFahrzeugAufhebenPlanenPromiseHandler(state, promiseHandler) {
      state.dialogFahrzeugAufhebenPlanenPromiseHandler = promiseHandler
    },

    // Verlaengerung
    setDialogFahrzeugVerlaengern(state, dialogFahrzeugVerlaengern) {
      state.dialogFahrzeugVerlaengern = dialogFahrzeugVerlaengern
    },
    setDialogFahrzeugVerlaengernFahrzeug(
      state,
      dialogFahrzeugVerlaengernFahrzeug
    ) {
      state.dialogFahrzeugVerlaengernFahrzeug =
        dialogFahrzeugVerlaengernFahrzeug
    },
    setDialogFahrzeugVerlaengernPartnernummer(
      state,
      dialogFahrzeugVerlaengernPartnernummer
    ) {
      state.dialogFahrzeugVerlaengernPartnernummer =
        dialogFahrzeugVerlaengernPartnernummer
    },
    setDialogFahrzeugVerlaengernPromiseHandler(state, promiseHandler) {
      state.dialogFahrzeugVerlaengernPromiseHandler = promiseHandler
    }
  },

  actions: {
    loadAutohaeuser({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('startLoading')

        FreshcarflatApi.loadAutohaeuser(
          _.get(state, 'filter.daterange.start')
            ? moment(state.filter.daterange.start).format('YYYY-MM-DD')
            : null,
          _.get(state, 'filter.daterange.end')
            ? moment(state.filter.daterange.end).format('YYYY-MM-DD')
            : null
        )
          .then((autohaeuser) => {
            commit('setAutohaeuser', autohaeuser)
            commit('finishLoading', true)
            resolve(autohaeuser.length)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('setAutohaeuser', [])
            commit('finishLoading', false)
            reject()
          })
      })
    },

    loadStandorte({ commit, state }, data) {
      let isLoaded = function (item) {
        // If there is an standorte array or an verkaeufer array, the items has already been loaded
        return !!((item || {}).standorte || (item || {}).verkaeufer)
      }

      return new Promise((resolve, reject) => {
        let { autohaus, force } = data

        if (!force && isLoaded(autohaus)) {
          resolve(autohaus.standorte.length)
          return
        }

        commit('startLoading')

        let index = state.autohaeuser.indexOf(autohaus)

        FreshcarflatApi.loadStandorte(
          autohaus.id,
          _.get(state, 'filter.daterange.start')
            ? moment(state.filter.daterange.start).format('YYYY-MM-DD')
            : null,
          _.get(state, 'filter.daterange.end')
            ? moment(state.filter.daterange.end).format('YYYY-MM-DD')
            : null
        )
          .then((standorte) => {
            commit('setStandorte', {
              index,
              standorte
            })
            commit('finishLoading', true)
            resolve(standorte.length)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('setStandorte', {
              index,
              standorte: []
            })
            commit('finishLoading', false)
            reject()
          })
      })
    },

    loadVerkaeufer({ commit, state, getters }, { id, force }) {
      let isLoaded = function (item) {
        // If there is an verkaeufer array, the items has already been loaded
        return !!(item || {}).verkaeufer
      }

      return new Promise((resolve, reject) => {
        let { autohaus, standort } = getters.standortByIdExtended(id)

        if (!force && (isLoaded(standort) || isLoaded(autohaus))) {
          resolve((autohaus.verkaeufer || standort.verkaeufer).length)
          return
        }

        commit('startLoading')

        let indexAutohaus = state.autohaeuser.indexOf(autohaus)
        if (indexAutohaus < 0) {
          reject()
          return
        }

        let index = (autohaus.standorte || []).indexOf(standort)

        FreshcarflatApi.loadVerkaeufer(
          id,
          state.filter.daterange.start
            ? moment(state.filter.daterange.start).format('YYYY-MM-DD')
            : null,
          state.filter.daterange.end
            ? moment(state.filter.daterange.end).format('YYYY-MM-DD')
            : null
        )
          .then((verkaeufer) => {
            commit('setVerkaeufer', {
              indexAutohaus,
              index,
              verkaeufer
            })
            commit('finishLoading', true)
            resolve(verkaeufer.length)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('setVerkaeufer', {
              indexAutohaus,
              index,
              verkaeufer: []
            })
            commit('finishLoading', false)
            reject()
          })
      })
    },

    deleteAntrag({ commit }, antragId) {
      return new Promise((resolve, reject) => {
        commit('startLoading')

        FreshcarflatApi.deleteAntrag(antragId)
          .then((id) => {
            commit('finishLoading', true)
            resolve(id)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('finishLoading', false)
            reject()
          })
      })
    },

    submitFilterRangliste({ state, commit, dispatch }, filter) {
      const filterInternal = Object.assign({}, state.filter, filter)

      if (!_.isEqual(state.filter, filterInternal)) {
        commit('setFilter', filterInternal)
        dispatch('loadAutohaeuser')
      }
    },

    submitFilterVorgaenge({ state, commit }, filter) {
      const filterInternal = Object.assign({}, state.filter, filter)

      if (!_.isEqual(state.filter, filterInternal)) {
        commit('setFilter', filterInternal)
      }
    },

    setFilter({ state, commit }, filter) {
      const filterInternal = Object.assign({}, state.filter, filter)

      commit('setFilter', filterInternal)
    },

    // Dialogs

    // eVB eintragen
    openDialogEvb({ commit }, { antragId, fahrzeugId }) {
      return new Promise((resolve, reject) => {
        commit('setDialogEvbAntragId', antragId)
        commit('setDialogEvbFahrzeugId', fahrzeugId)
        commit('setDialogEvbPromiseHandler', { resolve, reject })
        commit('setDialogEvb', true)
      })
    },

    // Angebot versenden
    openDialogAngebotVersenden(
      { commit },
      { fahrzeugId, emailVerkaeufer, emailVersicherungsnehmer }
    ) {
      return new Promise((resolve, reject) => {
        commit('setDialogAngebotVersendenFahrzeugId', fahrzeugId)
        commit('setDialogAngebotVersendenEmailVerkaeufer', emailVerkaeufer)
        commit(
          'setDialogAngebotVersendenEmailVersicherungsnehmer',
          emailVersicherungsnehmer
        )
        commit('setDialogAngebotVersendenPromiseHandler', { resolve, reject })
        commit('setDialogAngebotVersenden', true)
      })
    },

    // Fahrzeug aufheben
    openDialogFahrzeugAufheben({ commit }, { fahrzeug }) {
      return new Promise((resolve, reject) => {
        commit('setDialogFahrzeugAufhebenFahrzeug', fahrzeug)
        commit('setDialogFahrzeugAufhebenPromiseHandler', { resolve, reject })
        commit('setDialogFahrzeugAufheben', true)
      })
    },

    // Fahrzeugaufhebung planen
    openDialogFahrzeugAufhebenPlanen({ commit }, { fahrzeug }) {
      return new Promise((resolve, reject) => {
        commit('setDialogFahrzeugAufhebenPlanenFahrzeug', fahrzeug)
        commit('setDialogFahrzeugAufhebenPlanenPromiseHandler', {
          resolve,
          reject
        })
        commit('setDialogFahrzeugAufhebenPlanen', true)
      })
    },

    // Verlaengerung
    openDialogFahrzeugVerlaengern({ commit }, { fahrzeug, partnernummer }) {
      return new Promise((resolve, reject) => {
        commit('setDialogFahrzeugVerlaengernFahrzeug', fahrzeug)
        commit('setDialogFahrzeugVerlaengernPartnernummer', partnernummer)
        commit('setDialogFahrzeugVerlaengernPromiseHandler', {
          resolve,
          reject
        })
        commit('setDialogFahrzeugVerlaengern', true)
      })
    }
  },

  getters: {
    isLoading: (state) => state.status === 'LOADING',
    isError: (state) => state.status === 'ERROR',
    filter: (state) => state.filter,
    autohaeuser: (state) => state.autohaeuser,

    autohausById: (state) => (id) => {
      return state.autohaeuser.find((autohaus) => autohaus.id === id)
    },

    standortByIdExtended: (state, getters) => (id) => {
      let value = {
        autohaus: getters.autohausById(id),
        standort: null
      }

      // if there is no autohaus, search every autohaus for a standort
      if (!value.autohaus) {
        for (var autohaus of state.autohaeuser) {
          // Search for a standort only if there are standorte
          if (autohaus.standorte) {
            value.autohaus = autohaus
            value.standort = autohaus.standorte.find(
              (standort) => standort.id === id
            )
          }

          // We found a standort, so we can stop the for-loop here
          if (value.standort) {
            break
          }
        }
      }

      return value
    },

    standortById: (state, getters) => (id) => {
      let { autohaus, standort } = getters.standortByIdExtended(id)
      return standort || autohaus
    }
  }
}
