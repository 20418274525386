<template>
  <v-list nav dense>
    <v-list-item two-line @click="showProfile = !showProfile">
      <v-list-item-avatar>
        <profile-avatar />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <span v-if="user && user.fullname">{{ user.fullname }}</span>
          <span v-else-if="user && user.email">{{ user.email }}</span>
          <span v-else class="font-italic">Unbekannt</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="user && user.fullname">{{ user.email }}</span>
          <span v-else-if="user && !user.fullname"></span>
          <span v-else>Fehler beim Laden</span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon :class="{ rotate180: showProfile }">$action-expand</v-icon>
      </v-list-item-action>
    </v-list-item>

    <!-- Profile Navigation -->
    <v-expand-transition>
      <app-navigation-list-user v-if="showProfile" />
    </v-expand-transition>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

import AppNavigationListUser from './AppNavigationListUser'
import ProfileAvatar from '@/components/ProfileAvatar'

export default {
  name: 'AppNavigationProfile',

  components: {
    AppNavigationListUser,
    ProfileAvatar
  },

  data() {
    return {
      showProfile: false
    }
  },

  computed: {
    ...mapGetters('auth', ['isAuthorized', 'user', 'hasRole']),

    logoSrc() {
      return process.env.BASE_URL + 'img/logo/freshforfinance.png'
    },

    // vuex two-way binding
    navigationDrawer: {
      get() {
        return this.$store.state.layout.navigationDrawer
      },
      set(val) {
        this.$store.commit('layout/setNavigationDrawer', val)
      }
    }
  }
}
</script>
