import Vue from 'vue'
import store from '@/store/index.js'

export default {
  addDefaultInterceptorsTo(instance) {
    // request interceptor
    instance.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        const token = store.state.auth.token

        if (token) {
          // do not check for authorized here, because it will be false if auth is still loading
          config.headers['Authorization'] = 'Bearer ' + token
        }

        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    // response interceptor
    instance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        switch (error.response.status) {
          /*
    case 401: // Listen for 401 (unauthorized)
      store.dispatch('auth/unauthorize')
      break
    */
          case 500:
            Vue.toasted.error('Es ist ein Fehler aufgetreten.', {
              duration: '10000',
              keepOnHover: true
            })
            break
        }

        return Promise.reject(error)
      }
    )
  }
}
