import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'warning-target-loss-ratio': {
    component: IconFontawesome,
    props: {
      icon: 'triangle-exclamation',
      color: 'warning'
    }
  },

  'carsharing-list-damages': {
    component: IconFontawesome,
    props: {
      icon: 'list'
    }
  },

  'carsharingadjustment-amount-requested': {
    component: IconFontawesome,
    props: {
      icon: 'money-bill'
    }
  }
}
