<template>
  <v-row>
    <!-- noteable -->
    <v-col v-if="data.noteable" cols="12" sm="6">
      <data-list-item-noteable :noteable="data.noteable" />
    </v-col>

    <!-- note -->
    <v-col v-if="data.note" cols="12" sm="6">
      <data-list-item-note :note="data.note" />
    </v-col>

    <!-- lead -->
    <v-col v-if="data.lead" cols="12" sm="6">
      <data-list-item-lead :lead="data.lead" :mail-id="data.mail?.id" />
    </v-col>

    <!-- offer -->
    <v-col v-if="data.offer" cols="12" sm="6">
      <data-list-item-offer :offer="data.offer" />
    </v-col>

    <!-- fahrzeugevent -->
    <v-col v-if="data.fahrzeugevent" cols="12" sm="6">
      <data-list-item-fahrzeugevent :fahrzeugevent="data.fahrzeugevent" />
    </v-col>

    <!-- vehicle -->
    <v-col v-if="data.vehicle" cols="12" sm="6">
      <data-list-item-vehicle :vehicle="data.vehicle" />
    </v-col>

    <!-- customer -->
    <v-col v-if="data.customer" cols="12" sm="6">
      <data-list-item-customer
        :customer="data.customer"
        :mail-id="data.mail?.id"
      />
    </v-col>

    <!-- storage -->
    <v-col v-if="data.storage" cols="12" sm="6">
      <data-list-item-storage :storage="data.storage" />
    </v-col>

    <!-- meetingable -->
    <v-col v-if="data.meetingable" cols="12" sm="6">
      <data-list-item-meetingable :meetingable="data.meetingable" />
    </v-col>

    <!-- meeting -->
    <v-col v-if="data.meeting" cols="12" sm="6">
      <data-list-item-meeting :meeting="data.meeting" />
    </v-col>

    <!-- claim -->
    <v-col v-if="data.claim" cols="12" sm="6">
      <data-list-item-claim :claim="data.claim" />
    </v-col>

    <!-- issue -->
    <v-col v-if="data.issue" cols="12" sm="6">
      <data-list-item-issue :issue="data.issue" />
    </v-col>

    <!-- message -->
    <v-col v-if="data.message" cols="12" sm="6">
      <data-list-item-message :message="data.message" />
    </v-col>
  </v-row>
</template>

<script>
// Models
import DataListItemNoteable from './DataListItemNoteable'
import DataListItemNote from './DataListItemNote'
import DataListItemLead from './DataListItemLead'
import DataListItemOffer from './DataListItemOffer'
import DataListItemFahrzeugevent from './DataListItemFahrzeugevent'
import DataListItemVehicle from './DataListItemVehicle'
import DataListItemCustomer from './DataListItemCustomer'
import DataListItemStorage from './DataListItemStorage'
import DataListItemMeetingable from './DataListItemMeetingable'
import DataListItemMeeting from './DataListItemMeeting'
import DataListItemClaim from './DataListItemClaim'
import DataListItemIssue from './DataListItemIssue'

// Other
import DataListItemMessage from './DataListItemMessage'

export default {
  name: 'NotificationsListNotificationData',

  components: {
    // Models
    DataListItemNoteable,
    DataListItemNote,
    DataListItemLead,
    DataListItemOffer,
    DataListItemFahrzeugevent,
    DataListItemVehicle,
    DataListItemCustomer,
    DataListItemStorage,
    DataListItemMeetingable,
    DataListItemMeeting,
    DataListItemClaim,
    DataListItemIssue,

    // Other
    DataListItemMessage
  },

  props: {
    type: {
      type: String,
      required: true
    },

    data: {
      type: Object,
      required: true
    }
  }
}
</script>
