import Vue from 'vue'
import humanizeDuration from 'humanize-duration'

const germanHumanizer = humanizeDuration.humanizer({
  language: 'de'
})

Vue.filter('humanizeDuration', function (value, options = {}) {
  if (value) {
    // return moment.duration(value, type).humanize()
    return germanHumanizer(value * 1000, options)
  } else {
    return '-'
  }
})
