<template>
  <div class="d-flex">
    <todo-save-dialog
      :todo="todo"
      @submit:success="$emit('submit:success')"
      @submit:fail="$emit('submit:fail')"
    >
      <template #activator="{ on }">
        <button-tooltip
          tooltip-text="Aufgabe bearbeiten"
          icon="$action-edit"
          v-bind="$attrs"
          :loading="loading"
          v-on="on"
        />
      </template>
    </todo-save-dialog>

    <button-tooltip
      tooltip-text="Aufgabe löschen"
      icon="$action-delete"
      v-bind="$attrs"
      :loading="loading"
      @click="onDelete"
    />

    <button-tooltip
      tooltip-text="Aufgabe erledigen"
      icon="$action-ok"
      v-bind="$attrs"
      :loading="loading"
      @click="onComplete"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { AlertUtils } from '@freshsolutions/fresh-vue'

import { ButtonTooltip } from '@freshsolutions/fresh-vue'
import TodoSaveDialog from './TodoSaveDialog'

export default {
  name: 'TodosListTodoActions',

  components: {
    ButtonTooltip,
    TodoSaveDialog
  },

  props: {
    todo: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    onDelete() {
      AlertUtils.fireQuestion({
        text: 'Möchten Sie die Aufgabe wirklich unwiderruflich löschen?'
      }).then((result) => {
        if (result.value) {
          this.loading = true
          this.$apollo
            .mutate({
              mutation: gql`
                mutation deleteTodo($id: ID!) {
                  deleteTodo(id: $id) {
                    id
                  }
                }
              `,
              variables: {
                id: this.todo.id
              }
            })
            .then(() => {
              this.loading = false
              this.$emit('submit:success')
            })
            .catch(() => {
              this.loading = false
              this.$emit('submit:fail')
            })
        }
      })
    },

    onComplete() {
      AlertUtils.fireQuestion({
        text: 'Möchten Sie die Aufgabe wirklich als abgeschlossen markieren?'
      }).then((result) => {
        if (result.value) {
          this.loading = true
          this.$apollo
            .mutate({
              mutation: gql`
                mutation upsertTodo($input: UpsertTodoInput!) {
                  upsertTodo(input: $input) {
                    id
                    completed_at
                  }
                }
              `,
              variables: {
                input: {
                  id: this.todo.id,
                  completed_at: this.$moment().format('YYYY-MM-DD HH:mm:ss')
                }
              }
            })
            .then(() => {
              this.loading = false
              this.$emit('submit:success')
            })
            .catch(() => {
              this.loading = false
              this.$emit('submit:fail')
            })
        }
      })
    }
  }
}
</script>
