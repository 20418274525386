import Vue from 'vue'

import { ApolloClient } from 'apollo-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import VueApollo from 'vue-apollo'
import store from '@/store'
import ApiUtils from '@/utils/api.js'

Vue.use(VueApollo)

const cache = new InMemoryCache()
const link = new BatchHttpLink({ uri: () => ApiUtils.getGraphqlUrl() })
const authLink = setContext((_, { headers }) => {
  const token = store.state.auth.token // get token from vuex

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache: cache,
  shouldBatch: true
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})
