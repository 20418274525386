import axios from 'axios'
import ExceptionUtils from '@/utils/exceptions.js'
import ApiUtils from '@/utils/api.js'

const urlPrefix = ApiUtils.getApiUrl() + '/user'

export default {
  loadUser() {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadRoles() {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + '/roles')
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadPermissions() {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + '/permissions')
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadVerkaeufer() {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + '/verkaeufer')
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadAutohaus() {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + '/autohaus')
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadTheme(client, partner) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + '/theme', {
          params: { client, partner }
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  isUnique({ username }) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + '/isunique', {
          params: { username }
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  changePassword({ currentPassword, newPassword, client }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/password', {
          current_password: currentPassword,
          new_password: newPassword,
          client: client
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  }
}
