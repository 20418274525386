import Vue from 'vue'

Vue.filter('auditEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'created':
      return 'erstellt'

    case 'updated':
      return 'geändert'

    case 'deleted':
      return 'gelöscht'

    case 'restored':
      return 'wiederhergestellt'

    default:
      return value
  }
})

Vue.filter('auditTypename', function (value) {
  if (!value) return ''

  switch (value) {
    // note
    case 'Note':
    case 'App\\Models\\Note':
      return 'Notiz'

    // customer
    case 'Customer':
    case 'App\\Models\\fahrzeugmarkt\\Customer':
      return 'Kunde'
    case 'CustomerAddress':
    case 'App\\Models\\fahrzeugmarkt\\CustomerAddress':
      return 'Adresse'
    case 'CustomerCommunication':
    case 'App\\Models\\fahrzeugmarkt\\CustomerCommunication':
      return 'Kommunikationsmedium'
    case 'CustomerBankdetail':
    case 'App\\Models\\fahrzeugmarkt\\CustomerBankdetail':
      return 'Bank'
    case 'CustomerContactperson':
    case 'App\\Models\\fahrzeugmarkt\\CustomerContactperson':
      return 'Kontaktperson'

    // freshBIKE
    case 'FreshbikeApplication':
    case 'App\\Models\\freshbike\\FreshbikeApplication':
      return 'freshBIKE Antrag'
    case 'FreshbikeBike':
    case 'App\\Models\\freshbike\\FreshbikeBike':
      return 'freshBIKE Fahrrad'
    case 'App\\Models\\freshbike\\FreshbikeClaim':
      return 'freshBIKE Schadenmeldung'

    // freshCARsharing
    case 'FreshcarsharingApplication':
    case 'App\\Models\\freshcarsharing\\FreshcarsharingApplication':
      return 'freshCARsharing Antrag'
    case 'FreshcarsharingCar':
    case 'App\\Models\\freshcarsharing\\FreshcarsharingCar':
      return 'freshCARsharing Fahrzeug'

    // Sicherungsschein
    case 'FreshcarsharingLessorCertificate':
    case 'App\\Models\\freshcarsharing\\FreshcarsharingLessorCertificate':
      return 'Sicherungsschein'

    // fahrzeuge
    case 'Fahrzeug':
    case 'App\\Models\\fahrzeugmarkt\\Fahrzeug':
      return 'Fahrzeug'
    case 'Fahrzeugangebot':
    case 'App\\Models\\fahrzeugmarkt\\Fahrzeugangebot':
      return 'Fahrzeugangebot'
    case 'Lead':
    case 'App\\Models\\leads\\Lead':
      return 'Lead'

    // Einlagerung
    case 'Storage':
    case 'App\\Models\\fahrzeugmarkt\\Storage':
      return 'Einlagerung'
    case 'StorageTire':
    case 'App\\Models\\fahrzeugmarkt\\StorageTire':
      return 'Reifen'

    // TradeInProcess
    case 'TradeInProcess':
    case 'App\\Models\\fahrzeugmarkt\\TradeInProcess':
      return 'Ankaufprozess'

    // Workshoprepairreport
    case 'Workshoprepairreport':
    case 'App\\Models\\fahrzeugmarkt\\Workshoprepairreport':
      return 'Technische Bewertung'

    case 'Workshoprepairreportitem':
    case 'App\\Models\\fahrzeugmarkt\\Workshoprepairreportitem':
      return 'Technische Bewertung Instandsetzung'

    // Schadenmeldung
    case 'CarDamage':
    case 'App\\Models\\schadenmeldung\\CarDamage':
      return 'Schadenmeldung'
    case 'CarDamageObject':
    case 'App\\Models\\schadenmeldung\\CarDamageObject':
      return 'Schadenmeldung Objekt'
    case 'CarDamagePerson':
    case 'App\\Models\\schadenmeldung\\CarDamagePerson':
      return 'Schadenmeldung Person'

    default:
      return value
  }
})
