import moment from 'moment'

const validator = {
  message: '{_field_} ist kein gültiges Datum.',
  params: ['format'],
  validate(value, { format }) {
    return moment(value, format, true).isValid()
  }
}
export default validator
