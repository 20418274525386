<template>
  <v-container class="pa-0 ma-0 mt-5">
    <v-skeleton-loader type="paragraph@3" :loading="loading">
      <v-data-iterator
        v-if="notifications.length > 0"
        :items="notifications"
        :footer-props="{
          'items-per-page-options': [itemsPerPage, -1],
          'disable-items-per-page': true
        }"
        :items-per-page.sync="itemsPerPage"
        :hide-default-footer="notifications.length <= itemsPerPage"
      >
        <template #default="props">
          <v-row dense>
            <v-col v-for="item in props.items" :key="item.id" cols="12">
              <notifications-list-notification :notification="item" />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <div v-else class="ma-16 text-h5 text-center">
        <span class="text-h5"> Sie sind auf dem aktuellen Stand 🎉 </span>
        <br />
        <span class="text-body-1">
          Zur Zeit haben Sie keine ungelesenen Benachrichtigungen.
        </span>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import NotificationsListNotification from './NotificationsListNotification'

export default {
  name: 'NotificationsList',

  components: {
    NotificationsListNotification
  },

  props: {
    notifications: {
      type: Array,
      required: false,
      default: () => []
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      itemsPerPage: 3
    }
  },

  computed: {}
}
</script>
