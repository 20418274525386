var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("activator",null,null,{
      disabled: _vm.disabled,
      loading: _vm.loading,
      on: {
        click: _vm.open
      }
    })],2)
}
var staticRenderFns = []

export { render, staticRenderFns }