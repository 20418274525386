import Vue from 'vue'

Vue.filter('workshoprepairreportStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'IN_PROGRESS':
      return 'In Arbeit'
    case 'OK':
      return 'Abgeschlossen'
    case 'CANCELED':
      return 'Abgebrochen'
    default:
      return value
  }
})

Vue.filter('workshoprepairreportHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    // items
    case 'WORKSHOPREPAIRREPORT_ITEM_CREATED':
      return 'Instandsetzungseintrag erstellt'
    case 'WORKSHOPREPAIRREPORT_ITEM_UPDATED':
      return 'Instandsetzungseintrag geändert'
    case 'WORKSHOPREPAIRREPORT_ITEM_DELETED':
      return 'Instandsetzungseintrag gelöscht'

    default:
      return undefined // return default event text
  }
})

Vue.filter('workshoprepairreportCondition', function (value) {
  if (!value) return ''

  switch (value) {
    case 'VERY_GOOD':
      return 'sehr gut'
    case 'GOOD':
      return 'gut'
    case 'OK':
      return 'mittel'
    case 'BAD':
      return 'schlecht'
    case 'REPAIR':
      return 'Reparatur nötig'
    default:
      return value
  }
})
