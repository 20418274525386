import Vue from 'vue'
import _ from 'lodash'

// ##########################################################
// Kunden
// ##########################################################

Vue.filter('customerHistoryEvent', function (value) {
  if (!value) return ''

  const filterFahrzeugangebot = Vue.filter(
    'fahrzeugmarktFahrzeugangebotHistoryEvent'
  )
  const filterFahrzeug = Vue.filter('fahrzeugmarktFahrzeugHistoryEvent')

  switch (value) {
    // dataprotection (privacy policy)
    case 'CUSTOMER_GENERATE_PERSONAL_DATA_PDF':
      return 'Datenschutzauskunft als PDF erstellt'
    case 'CUSTOMER_SEND_PERSONAL_DATA_PDF':
      return 'Datenschutzauskunft per E-Mail versendet'
    case 'CUSTOMER_GENERATE_PRIVACY_POLICY_PDF':
      return 'Datenschutzerklärung als PDF erstellt'
    case 'CUSTOMER_GENERATE_PRIVACY_POLICY_URL':
      return 'Datenschutzerklärung als Direktlink erstellt'
    case 'CUSTOMER_SEND_PRIVACY_POLICY_PDF':
      return 'Datenschutzerklärung per E-Mail versendet'
    case 'CUSTOMER_SIGNED_PRIVACY_POLICY_PDF':
      return 'Datenschutzerklärung unterschrieben'

    // testdrivecontract
    case 'CUSTOMER_CREATE_TESTDRIVECONTRACT_PDF':
      return 'Probefahrtvertrag als PDF erstellt'
    case 'CUSTOMER_GENERATE_TESTDRIVECONTRACT_URL':
      return 'Probefahrtvertrag als Direktlink erstellt'
    case 'CUSTOMER_SEND_TESTDRIVECONTRACT_PDF':
      return 'Probefahrtvertrag per E-Mail versendet'
    case 'CUSTOMER_SIGNED_TESTDRIVECONTRACT_PDF':
      return 'Probefahrtvertrag unterschrieben'

    // rentaldrivecontract
    case 'CUSTOMER_CREATE_RENTALDRIVECONTRACT_PDF':
      return 'Mietwagenvertrag als PDF erstellt'
    case 'CUSTOMER_GENERATE_RENTALDRIVECONTRACT_URL':
      return 'Mietwagenvertrag als Direktlink erstellt'
    case 'CUSTOMER_SEND_RENTALDRIVECONTRACT_PDF':
      return 'Mietwagenvertrag per E-Mail versendet'
    case 'CUSTOMER_SIGNED_RENTALDRIVECONTRACT_PDF':
      return 'Mietwagenvertrag unterschrieben'

    // address
    case 'CUSTOMER_ADDRESS_CREATED':
      return 'Adresse erstellt'
    case 'CUSTOMER_ADDRESS_UPDATED':
      return 'Adresse geändert'
    case 'CUSTOMER_ADDRESS_DELETED':
      return 'Adresse gelöscht'
    case 'CUSTOMER_ADDRESS_RESTORED':
      return 'Adresse wiederhergestellt'

    // communication
    case 'CUSTOMER_COMMUNICATION_CREATED':
      return 'Kommunikationsmedium erstellt'
    case 'CUSTOMER_COMMUNICATION_UPDATED':
      return 'Kommunikationsmedium geändert'
    case 'CUSTOMER_COMMUNICATION_DELETED':
      return 'Kommunikationsmedium gelöscht'
    case 'CUSTOMER_COMMUNICATION_RESTORED':
      return 'Kommunikationsmedium wiederhergestellt'

    // bankdetail
    case 'CUSTOMER_BANKDETAIL_CREATED':
      return 'Bankverbindung erstellt'
    case 'CUSTOMER_BANKDETAIL_UPDATED':
      return 'Bankverbindung geändert'
    case 'CUSTOMER_BANKDETAIL_DELETED':
      return 'Bankverbindung gelöscht'
    case 'CUSTOMER_BANKDETAIL_RESTORED':
      return 'Bankverbindung wiederhergestellt'

    // contactperson
    case 'CUSTOMER_CONTACTPERSON_CREATED':
      return 'Kontaktperson erstellt'
    case 'CUSTOMER_CONTACTPERSON_UPDATED':
      return 'Kontaktperson geändert'
    case 'CUSTOMER_CONTACTPERSON_DELETED':
      return 'Kontaktperson gelöscht'

    // contact
    case 'CUSTOMER_CONTACT_EMAIL':
      return 'Kunde per E-Mail kontaktiert'
    case 'CUSTOMER_CONTACT_PHONE':
      return 'Kunde per Telefon kontaktiert'
    case 'CUSTOMER_RECEIVED_EMAIL':
      return 'E-Mail von Kunde eingegangen'

    case 'CUSTOMER_SEND_BIRTHDAY_GREETINGS':
      return 'Kunde per E-Mail zum Geburtstag gratuliert'

    // selfdisclosure
    case 'SELFDISCLOSURE_CREATED':
      return 'Selbstauskunft-Anfrage versendet'
    case 'SELFDISCLOSURE_SUBMITTED':
      return 'Selbstauskunft-Anfrage ausgefüllt'
    case 'SELFDISCLOSURE_DELETED':
      return 'Selbstauskunft-Anfrage gelöscht'

    // formrequest
    case 'FORMREQUEST_CREATED':
      return 'Formular-Anfrage versendet'
    case 'FORMREQUEST_SUBMITTED':
      return 'Formular-Anfrage ausgefüllt'
    case 'FORMREQUEST_DELETED':
      return 'Formular-Anfrage gelöscht'

    //  vehicle
    case 'VEHICLE_HUAU_REMINDER_SEND':
      return 'HU/AU Erinnerung versendet'
    case 'VEHICLE_INSPECTION_REMINDER_SEND':
      return 'Inspektion Erinnerung versendet'

    default:
      return filterFahrzeugangebot(value) ?? filterFahrzeug(value) // somtimes there are events from the offers, so we want to check for them to
  }
})

Vue.filter('customerContactName', function (value) {
  if (!value) return ''

  const salutation = _.get(value, 'salutation.name', null)
  const text = []

  text.push(salutation)
  if (_.get(value, 'salutation.code', null) === '3') {
    // Company
    text.push(value.company)
  } else {
    // Privat person or something else
    text.push(value.firstname)
    text.push(value.lastname)
  }

  // Remove falsy values (empty, null, ...) and join with space
  return text.filter((item) => !!item).join(' ')
})

Vue.filter('customerDetailsShort', function (value) {
  if (!value) return ''

  const filterDateAndTime = Vue.filter('dateAndTime')

  let text = []

  const id = _.get(value, 'id', null)
  const identifier = _.get(value, 'identifier', null)
  const created_at = _.get(value, 'created_at', null)
  const updated_at = _.get(value, 'updated_at', null)

  if (id) {
    text.push(`ID: ${identifier}`)
  }
  if (created_at) {
    text.push('Erstellt: ' + filterDateAndTime(created_at))
  }
  if (updated_at) {
    text.push('Geändert: ' + filterDateAndTime(updated_at))
  }

  return text.join(', ')
})

// ##########################################################
// Fahrzeuge, Inserate, Leads
// ##########################################################

Vue.filter('fahrzeugmarktFahrzeugHistoryEvent', function (value) {
  if (!value) return ''

  const filter = Vue.filter('fahrzeugmarktFahrzeugangebotHistoryEvent')

  switch (value) {
    // Fahrzeug
    case 'VEHICLE_RESERVATION_CREATED':
      return 'Fahrzeug reserviert'
    case 'VEHICLE_RESERVATION_CANCELED':
      return 'Fahrzeugreservierung aufgehoben'
    case 'VEHICLE_PURCHASE_CREATED':
      return 'Ankaufschein erstellt'
    case 'VEHICLE_PURCHASE_URL_CREATED':
      return 'Ankaufschein als Direktlink erstellt'
    case 'VEHICLE_PURCHASE_CLOSED':
      return 'Ankaufschein abgeschlossen'
    case 'VEHICLE_PURCHASE_CANCELED':
      return 'Ankaufschein storniert'
    case 'VEHICLE_PURCHASE_REOPENED':
      return 'Ankaufschein reaktiviert'
    case 'VEHICLE_PURCHASE_SEND':
      return 'Ankaufschein versendet'
    case 'VEHICLE_SIGNED_PURCHASE':
      return 'Ankaufschein unterschrieben'
    case 'VEHICLE_PURCHASEOFFER_SEND':
      return 'Angebot (Ankauf) versendet'
    case 'VEHICLE_PURCHASEOFFER_URL_CREATED':
      return 'Angebot (Ankauf) als Direktlink erstellt'
    case 'VEHICLE_SIGNED_PURCHASEOFFER':
      return 'Angebot (Ankauf) unterschrieben'
    case 'VEHICLE_HUAU_REMINDER_SEND':
      return 'HU/AU Erinnerung versendet'
    case 'VEHICLE_INSPECTION_REMINDER_SEND':
      return 'Inspektion Erinnerung versendet'

    // Dokumente
    case 'VEHICLE_GENERATE_ENERGYLABEL_PDF':
      return 'Energielabel erstellt'

    // testdrivecontract
    case 'VEHICLE_CREATE_TESTDRIVECONTRACT_PDF':
      return 'Probefahrtvertrag als PDF erstellt'
    case 'VEHICLE_GENERATE_TESTDRIVECONTRACT_URL':
      return 'Probefahrtvertrag als Direktlink erstellt'
    case 'VEHICLE_SEND_TESTDRIVECONTRACT_PDF':
      return 'Probefahrtvertrag per E-Mail versendet'
    case 'VEHICLE_SIGNED_TESTDRIVECONTRACT':
      return 'Probefahrtvertrag unterschrieben'

    // rentaldrivecontract
    case 'VEHICLE_CREATE_RENTALDRIVECONTRACT_PDF':
      return 'Mietwagenvertrag als PDF erstellt'
    case 'VEHICLE_GENERATE_RENTALDRIVECONTRACT_URL':
      return 'Mietwagenvertrag als Direktlink erstellt'
    case 'VEHICLE_SEND_RENTALDRIVECONTRACT_PDF':
      return 'Mietwagenvertrag per E-Mail versendet'
    case 'VEHICLE_SIGNED_RENTALDRIVECONTRACT':
      return 'Mietwagenvertrag unterschrieben'

    // Kosten/Erträge
    case 'VEHICLE_PROFIT_CREATED':
      return 'Kosten/Erträge erstellt'
    case 'VEHICLE_PROFIT_UPDATED':
      return 'Kosten/Erträge geändert'
    case 'VEHICLE_PROFIT_DELETED':
      return 'Kosten/Erträge gelöscht'

    default:
      return filter(value) // somtimes there are events from the offers, so we want to check for them to
  }
})

Vue.filter('fahrzeugmarktFahrzeugangebotHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'LEAD_CONTACT_EMAIL_EXPOSE':
      return 'Exposé per E-Mail versendet'

    // Dokumente
    case 'OFFER_GENERATE_EXPOSE_PDF':
      return 'Expose erstellt'
    case 'OFFER_GENERATE_PRICE_PDF':
      return 'Preisschild erstellt'
    case 'OFFER_FLYER_CREATED':
      return 'Flyer erstellt'
    case 'OFFER_FLYER_UPDATED':
      return 'Flyer aktualisiert'

    // Preisänderungen
    case 'OFFER_PRICES_CHANGED':
      return 'Preisanpassung per Massenänderung'
    case 'OFFER_PRICES_CHANGED_JOB':
      return 'Preisanpassung durch geplanter Job'

    // Kaufvertrag
    case 'OFFER_CONTRACT_CREATED':
      return 'Kaufvertrag erstellt'
    case 'OFFER_CONTRACT_URL_CREATED':
      return 'Kaufvertrag als Direktlink erstellt'
    case 'OFFER_CONTRACT_SIGNED':
      return 'Kaufvertrag unterschrieben'
    case 'OFFER_CONTRACT_CLOSED':
      return 'Kaufvertrag abgeschlossen'
    case 'OFFER_CONTRACT_CANCELED':
      return 'Kaufvertrag storniert'
    case 'OFFER_CONTRACT_REOPENED':
      return 'Kaufvertrag reaktiviert'
    case 'OFFER_CONTRACT_SEND':
      return 'Kaufvertrag versendet'
    case 'OFFER_CONTRACTOFFER_SEND':
      return 'Angebot (Verkauf) versendet'
    case 'OFFER_CONTRACTOFFER_URL_CREATED':
      return 'Angebot (Verkauf) als Direktlink erstellt'
    case 'OFFER_CONTRACTOFFER_SIGNED':
      return 'Angebot (Verkauf) unterschrieben'
    case 'OFFER_ORDERCONFIRMATION_SEND':
      return 'Auftragsbestätigung versendet'

    // Fahrzeugbörsen
    case 'OFFER_PUBLISH':
      return 'An Fahrzeugbörse übertragen'
    case 'OFFER_UNPUBLISH':
      return 'Von Fahrzeugbörse entfernt'

    //  vehicle
    case 'VEHICLE_HUAU_REMINDER_SEND':
      return 'HU/AU Erinnerung versendet'
    case 'VEHICLE_INSPECTION_REMINDER_SEND':
      return 'Inspektion Erinnerung versendet'

    default:
      return undefined // return default event text
  }
})

Vue.filter('fahrzeugmarktFahrzeugangebotStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'OFFLINE':
      return 'Offline'

    case 'EXPIRED':
      return 'Beendet'

    case 'ONLINE':
      return 'Veröffentlicht'

    case 'ONLINE_PLANNED':
      return 'Veröffentlichung geplant'

    case 'DRAFT':
      return 'Entwurf'

    default:
      return value
  }
})

Vue.filter('leadHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'LEAD_CHANGE_STATUS':
      return 'Status geändert'
    case 'LEAD_PAUSE':
      return 'Lead pausiert'
    case 'LEAD_CHANGE_BEARBEITUNGSSTATUS':
      return 'Bearbeitungsstatus geändert'
    case 'LEAD_CHANGE_BEARBEITER':
      return 'Bearbeiter geändert'
    case 'LEAD_CLOSE':
      return 'Lead geschlossen'
    case 'LEAD_COMMENT_ADD':
      return 'Freitext hinzugefügt'
    case 'LEAD_REMINDER_SET':
      return 'Wiedervorlage gesetzt'
    case 'LEAD_REMINDER_DELETED':
      return 'Wiedervorlage gelöscht'
    case 'LEAD_REMINDER_REACHED':
      return 'Wiedervorlagedatum erreicht'
    case 'LEAD_CONTACT_EMAIL_INITIAL':
      return 'Initiale E-Mail an Kunden versendet'
    case 'LEAD_CONTACT_EMAIL':
      return 'Kunde per E-Mail kontaktiert'
    case 'LEAD_CONTACT_EMAIL_EXTERN':
      return 'Kunde per E-Mail über ein externes Programm kontaktiert'
    case 'LEAD_CONTACT_PHONE':
      return 'Kunde per Telefon kontaktiert'
    case 'LEAD_CONTACT_EMAIL_EXPOSE':
      return 'Exposé per E-Mail versendet'

    // dataprotection (privacy policy)
    case 'LEAD_CREATE_DATAPROTECTION_PDF':
      return 'Datenschutzauskunft als PDF erstellt'
    case 'LEAD_CONTACT_EMAIL_DATAPROTECTION':
      return 'Datenschutzauskunft per E-Mail versendet'
    case 'LEAD_CREATE_PRIVACY_POLICY_PDF':
      return 'Datenschutzerklärung als PDF erstellt'
    case 'LEAD_GENERATE_PRIVACY_POLICY_URL':
      return 'Datenschutzerklärung als Direktlink erstellt'
    case 'LEAD_CONTACT_EMAIL_PRIVACY_POLICY':
      return 'Datenschutzerklärung per E-Mail versendet'
    case 'LEAD_CONTACT_SIGNED_PRIVACY_POLICY':
      return 'Datenschutzerklärung unterschrieben'

    // testdrivecontract
    case 'LEAD_CREATE_TESTDRIVECONTRACT_PDF':
      return 'Probefahrtvertrag als PDF erstellt'
    case 'LEAD_GENERATE_TESTDRIVECONTRACT_URL':
      return 'Probefahrtvertrag als Direktlink erstellt'
    case 'LEAD_CONTACT_EMAIL_TESTDRIVECONTRACT':
      return 'Probefahrtvertrag per E-Mail versendet'
    case 'LEAD_CONTACT_SIGNED_TESTDRIVECONTRACT':
      return 'Probefahrtvertrag unterschrieben'

    // rentaldrivecontract
    case 'LEAD_CREATE_RENTALDRIVECONTRACT_PDF':
      return 'Mietwagenvertrag als PDF erstellt'
    case 'LEAD_GENERATE_RENTALDRIVECONTRACT_URL':
      return 'Mietwagenvertrag als Direktlink erstellt'
    case 'LEAD_CONTACT_EMAIL_RENTALDRIVECONTRACT':
      return 'Mietwagenvertrag per E-Mail versendet'
    case 'LEAD_CONTACT_SIGNED_RENTALDRIVECONTRACT':
      return 'Mietwagenvertrag unterschrieben'

    // others
    case 'LEAD_RECEIVED_EMAIL':
      return 'E-Mail von Kunde eingegangen'
    case 'LEAD_CUSTOMER_ASSIGNED':
      return 'Lead wurde einem Kunden zugeordnet'
    case 'LEAD_ESCALATION_MAIL':
      return 'Lead-Eskalation'

    // selfdisclosure
    case 'SELFDISCLOSURE_CREATED':
      return 'Selbstauskunft-Anfrage versendet'
    case 'SELFDISCLOSURE_SUBMITTED':
      return 'Selbstauskunft-Anfrage ausgefüllt'
    case 'SELFDISCLOSURE_DELETED':
      return 'Selbstauskunft-Anfrage gelöscht'

    // leadfeedback
    case 'LEADFEEDBACK_CREATED':
      return 'Bewertungsanfrage versendet'
    case 'LEADFEEDBACK_SUBMITTED':
      return 'Bewertung erhalten'
    case 'LEADFEEDBACK_DELETED':
      return 'Bewertungsanfrage gelöscht'

    // formrequest
    case 'FORMREQUEST_CREATED':
      return 'Formular-Anfrage versendet'
    case 'FORMREQUEST_SUBMITTED':
      return 'Formular-Anfrage ausgefüllt'
    case 'FORMREQUEST_DELETED':
      return 'Formular-Anfrage gelöscht'

    // contract
    case 'OFFER_CONTRACT_CREATED':
      return 'Kaufvertrag erstellt'
    case 'OFFER_CONTRACT_URL_CREATED':
      return 'Kaufvertrag als Direktlink erstellt'
    case 'OFFER_CONTRACT_SIGNED':
      return 'Kaufvertrag unterschrieben'
    case 'OFFER_CONTRACT_CLOSED':
      return 'Kaufvertrag abgeschlossen'
    case 'OFFER_CONTRACT_CANCELED':
      return 'Kaufvertrag storniert'
    case 'OFFER_CONTRACT_REOPENED':
      return 'Kaufvertrag reaktiviert'
    case 'OFFER_CONTRACT_SEND':
      return 'Kaufvertrag versendet'
    case 'OFFER_CONTRACTOFFER_SEND':
      return 'Angebot (Verkauf) versendet'
    case 'OFFER_CONTRACTOFFER_URL_CREATED':
      return 'Angebot (Verkauf) als Direktlink erstellt'
    case 'OFFER_CONTRACTOFFER_SIGNED':
      return 'Angebot (Verkauf) unterschrieben'
    case 'OFFER_ORDERCONFIRMATION_SEND':
      return 'Auftragsbestätigung versendet'

    default:
      return undefined // return default event text
  }
})

Vue.filter('leadStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NEW':
      return 'Neu'

    case 'ASSIGNED':
      return 'Zugewiesen'

    case 'IN_PROGRESS':
      return 'In Bearbeitung'

    case 'PAUSE':
      return 'Pausiert'

    case 'CLOSED_DEAL':
      return 'Abgeschlossen mit Erfolg'

    case 'CLOSED_NO_DEAL':
      return 'Abgeschlossen ohne Erfolg'

    default:
      return value
  }
})

Vue.filter('leadBearbeitungsstatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NULL':
      return 'Kein Status'

    case 'WAIT_CAR':
      return 'Warten auf Fahrzeug'

    case 'CONTACT':
      return 'Kontaktaufnahme'

    case 'WAIT_CONTACT':
      return 'Warten auf Rückmeldung'

    case 'WAIT_CUSTOMER':
      return 'Kunde bisher nicht erreichbar'

    case 'OFFER':
      return 'Angebotserstellung'

    case 'DOCUMENTS_BANK':
      return 'Warten auf Unterlagen für Bank'

    case 'CONTACT_BANK':
      return 'Bankanfrage'

    case 'CONTRACT':
      return 'Vertragserstellung'

    case 'NO_CONTRACT':
      return 'Vertragsnachverhandlung'

    case 'MEETING':
      return 'Terminvereinbarung'

    default:
      return value
  }
})

Vue.filter('leadClosedReason', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NULL':
      return 'Kein Grund'

    case 'SOLD_CASH':
      return 'Fahrzeug als Barkauf vermittelt'

    case 'SOLD_LEASING':
      return 'Fahrzeug als Leasing vermittelt'

    case 'SOLD_FINANCING':
      return 'Fahrzeug als Finanzierung vermittelt'

    case 'SOLD_ABO':
      return 'Fahrzeug als Abo vermittelt'

    case 'CUSTOMER_MEETING':
      return 'Kundentermin wurde vereinbart'

    case 'BAD_LEAD':
      return 'Fehl-Lead / Spam'

    case 'DUPLICATE':
      return 'Dublette'

    case 'NO_CAR':
      return 'Fahrzeug nicht verfügbar'

    case 'BAD_CREDIT':
      return 'Bonität'

    case 'DELIVERY_TIME':
      return 'Lieferzeit'

    case 'NO_RESPONSE':
      return 'Keine Rückmeldung'

    case 'CUSTOMER_CANCEL':
      return 'Kundenabsage'

    case 'OTHERS':
      return 'Sonstiges'

    default:
      return value
  }
})

Vue.filter('leadquelleType', function (value) {
  if (!value) return ''

  switch (value) {
    case 'MANUAL':
      return 'Manuelle Quellen'

    case 'WEB_INTERNAL':
      return 'Eigene Portale'

    case 'WEB_EXTERNAL':
      return 'Externe Portale'

    default:
      return value
  }
})

Vue.filter('leadContactName', function (value) {
  if (!value) return ''

  const filterAnrede = Vue.filter('anrede')
  const anrede = _.get(value, 'anrede.code', null)

  const text = []

  text.push(filterAnrede(anrede))
  if (anrede === '3') {
    // Firma
    text.push(value.zusatz)
  } else {
    if (
      _.get(value, 'vorname.length', null) > 1 ||
      _.get(value, 'nachname.length', null > 1)
    ) {
      // Privatperson / anderes
      text.push(value.vorname)
      text.push(value.nachname)
    } else {
      text.push(value.zusatz)
    }
  }

  // Remove falsy values (empty, null, ...) and join with space
  return text.filter((item) => !!item).join(' ')
})

Vue.filter('leadDetailsShort', function (value) {
  if (!value) return ''

  const filterDateAndTime = Vue.filter('dateAndTime')

  let text = []

  const id = _.get(value, 'id', null)
  const interne_nummer = _.get(value, 'interne_nummer', null)
  const created_at = _.get(value, 'created_at', null)
  const updated_at = _.get(value, 'updated_at', null)

  if (id) {
    text.push(`Lead-ID: ${interne_nummer}`)
  }
  if (created_at) {
    text.push('Eingegangen: ' + filterDateAndTime(created_at))
  }
  if (updated_at) {
    text.push('Geändert: ' + filterDateAndTime(updated_at))
  }

  return text.join(', ')
})

Vue.filter('fahrzeugmarktProfitItemType', function (value) {
  if (!value) return ''

  switch (value) {
    case 'PERCENTAGE':
      return 'Prozentualer Wert'
    case 'FIXED':
      return 'Fester Wert'
    default:
      return null
  }
})

// ##########################################################
// Werkstatt
// ##########################################################

Vue.filter('storageObjectName', function (value) {
  if (!value) return ''

  if (_.get(value, 'object', null) === 'TIRES') {
    return 'Reifen'
  } else {
    return 'Unbekannt'
  }
})

Vue.filter('storageDetailsShort', function (value) {
  if (!value) return ''

  const filterDateAndTime = Vue.filter('dateAndTime')

  let text = []

  const id = _.get(value, 'id', null)
  const identifier = _.get(value, 'identifier', null)
  const created_at = _.get(value, 'created_at', null)
  const updated_at = _.get(value, 'updated_at', null)

  if (id) {
    text.push(`ID: ${identifier}`)
  }
  if (created_at) {
    text.push('Erstellt: ' + filterDateAndTime(created_at))
  }
  if (updated_at) {
    text.push('Geändert: ' + filterDateAndTime(updated_at))
  }

  return text.join(', ')
})

Vue.filter('storageHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    // tires
    case 'STORAGE_TIRE_CREATED':
      return 'Reifeneinlagerung erstellt'
    case 'STORAGE_TIRE_UPDATED':
      return 'Reifeneinlagerung geändert'
    case 'STORAGE_TIRE_DELETED':
      return 'Reifeneinlagerung gelöscht'

    // PDF
    case 'STORAGE_GENERATE_TIRESIN_PDF':
      return 'Einlagerungsbeleg als PDF erstellt'
    case 'STORAGE_GENERATE_TIRESOUT_PDF':
      return 'Auslagerungsbeleg als PDF erstellt'
    default:
      return undefined // return default event text
  }
})

// ##########################################################
// Rentalcontracts
// ##########################################################

Vue.filter('rentalcontractTypeName', function (value) {
  if (!value) return ''

  switch (value) {
    case 'TESTDRIVECONTRACT':
      return 'Probefahrtvertrag'
    case 'RENTALDRIVECONTRACT':
      return 'Mietwagenvertrag'
    default:
      return undefined // return default event text
  }
})
