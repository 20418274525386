<template>
  <error-default
    text="Diese Seite wurde nicht gefunden"
    image-alt="404 Not Found"
    image-name="404.svg"
  ></error-default>
</template>

<script>
import ErrorDefault from '@/components/ErrorDefault.vue'

export default {
  components: {
    ErrorDefault
  }
}
</script>
