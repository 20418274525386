<template>
  <v-snackbar v-model="show" :timeout="-1" bottom right multi-line>
    <v-layout row wrap>
      <v-progress-linear
        :indeterminate="indeterminate"
        :value="progress"
        color="primary"
      ></v-progress-linear>
      <div class="text-caption">Bitte warten... {{ text }}</div>
    </v-layout>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProgressSnackbar',

  computed: {
    ...mapState('progress', [
      'loading',
      'progress',
      'indeterminate',
      'block',
      'text'
    ]),

    show() {
      return this.loading && !this.block
    }
  }
}
</script>
