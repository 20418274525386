<template>
  <div class="text-no-wrap">
    <span class="mr-2 caption">Zen-Mode:</span>
    <v-switch v-model="zen" inset dense />
    <v-tooltip right>
      <template #activator="{ on }">
        <v-icon x-small v-on="on"> $info </v-icon>
      </template>
      In diesem Modus werden für eine übersichtlichere Darstellung die
      Nachkommastellen ausgeblendet.
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ZenSwitcher',

  computed: {
    // vuex two-way binding
    zen: {
      get() {
        return this.$store.state.theme.zen
      },
      set(val) {
        this.$store.commit('theme/setZen', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input--switch {
  display: inline-block;
}
</style>
