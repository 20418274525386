import _ from 'lodash'
import log from 'loglevel'
import goTo from 'vuetify/es5/services/goto'

export default {
  logToAnalytics(err) {
    log.error(err)
    // we will disable this for now
    // let error = this.parseError(err)
    // if (error) {
    //   exception(error)
    // }
  },

  parseError(err) {
    return JSON.stringify(err || {})
  },

  getErrors(err) {
    return _.get(err, 'response.data.errors', [])
  },

  getErrorsForField(err, field) {
    const errors = this.getErrors(err)
    return errors[field] ? errors[field] : []
  },

  hasErrors(err, field) {
    const errors = this.getErrorsForField(err, field)
    return errors.length > 0
  },

  scrollToFirstValidationError(observer) {
    if (observer) {
      // 1. loop through all elements (refs) in the observer
      // 2. extract only the element
      // 3. remove all elements without an error
      const errorElements = Object.entries(observer.refs)
        .map((item) => item[1])
        .filter((el) => el.errors && el.errors.length > 0)

      const el = errorElements[0]

      if (el) {
        goTo(el, { offset: 256 })
      }
    }
  }
}
