import Vue from 'vue'

Vue.filter('signatureRequestStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'DRAFT':
      return 'Entwurf'
    case 'APPROVAL':
      return 'Warten auf Genehmigung(en)'
    case 'REJECTED':
      return 'Nicht genehmigt'
    case 'ONGOING':
      return 'Warten auf Unterschrift(en)'
    case 'DECLINED':
      return 'Unterschrift abgelehnt'
    case 'EXPIRED':
      return 'Abgelaufen'
    case 'DELETED':
      return 'Gelöscht'
    case 'CANCELED':
      return 'Abgebrochen'
    case 'DONE':
      return 'Unterschrieben'

    default:
      return value
  }
})
