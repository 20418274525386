<template>
  <v-footer app :absolute="$vuetify.breakpoint.mobile" inset padless>
    <v-row justify="end" no-gutters class="mx-2">
      <template v-if="!isAuthorized">
        <debug-dialog>
          <template #activator="{ on }">
            <v-btn outlined class="my-2" x-small v-on="on">
              <version />
            </v-btn>
          </template>
        </debug-dialog>
        <v-spacer />
      </template>
      <app-privacy-consent class="my-2" />
      <v-spacer />
      <v-btn
        v-for="(link, index) in links"
        :key="index"
        color="primary"
        text
        rounded
        x-small
        :href="link.url"
        target="_blank"
        class="my-2"
      >
        {{ link.text }}
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppPrivacyConsent from '@/layout/AppPrivacyConsent'
import DebugDialog from '@/components/DebugDialog'
import Version from '@/components/Version'

export default {
  name: 'AppFooter',

  components: {
    AppPrivacyConsent,
    DebugDialog,
    Version
  },

  data() {
    return {
      loading: false,
      apiVersion: null
    }
  },

  computed: {
    ...mapState(['client']),
    ...mapGetters('auth', ['isAuthorized']),

    links() {
      switch (this.client) {
        case 'freshforfinance':
          return [
            {
              text: '©️ fresh for finance GmbH',
              url: 'https://www.freshforfinance.de'
            },
            {
              text: 'Datenschutz',
              url: 'https://www.freshforfinance.de/datenschutz/'
            },
            {
              text: 'Hilfe',
              url: 'https://freshgroup.atlassian.net/l/cp/110HYeqM'
            },
            {
              text: 'Feedback',
              url: 'https://forms.office.com/r/DW3hT9VdHf'
            }
          ]
        case 'fahrzeugmarkt.online':
          return [
            {
              text: '©️ fahrzeugmarkt.online',
              url: 'https://www.fahrzeugmarkt.online'
            },
            {
              text: 'AGB',
              url: 'https://fahrzeugmarkt.online/agb/'
            },
            {
              text: 'Datenschutz',
              url: 'https://fahrzeugmarkt.online/datenschutz/'
            },
            {
              text: 'Hilfe',
              url: 'https://freshgroup.atlassian.net/l/cp/JgJEoaqs'
            },
            {
              text: 'Feedback',
              url: 'https://forms.office.com/r/pNzeVisxAN'
            }
          ]
        default:
          return []
      }
    }
  }
}
</script>
