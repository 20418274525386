import store from '@/store/index.js'
import router from '@/router'
import HealthcheckApi from '@/api/healthcheck'

export default {
  taskID: null,

  start() {
    this.stop() // Stop the currently running interval
    this.check()

    this.taskID = setInterval(this.check, 30000)
  },

  stop() {
    if (this.taskID) {
      clearInterval(this.taskID)
    }
  },

  check() {
    HealthcheckApi.index(store.state.client)
      .then((data) => {
        // update store
        store.commit('setVersionApi', data.version)
        store.commit('setMessage', data.message)

        // logout if necessary
        if (store.getters['auth/isAuthorized'] && !data.status_login) {
          store
            .dispatch('auth/logout')
            .then(() => {
              router.push({
                name: 'login',
                query: { redirect: router.currentRoute.path }
              })
            })
            .catch(() => {
              router.push({
                name: 'login',
                query: { redirect: router.currentRoute.path }
              })
            })
        }
      })
      .catch(() => {
        // update store
        store.commit('setVersionApi', null)
        store.commit('setMessage', null)
      })
  }
}
