import axios from 'axios'
import ExceptionUtils from '@/utils/exceptions.js'
import ApiUtils from '@/utils/api.js'
import { S3Utils } from '@freshsolutions/fresh-vue-files'
import { FileUtils } from '@freshsolutions/fresh-vue-files'

const urlPrefix = ApiUtils.getApiUrl() + '/filecenter'

export default {
  index() {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  files: {
    index() {
      return new Promise((resolve, reject) => {
        axios
          .get(urlPrefix + '/files')
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    store({ name, description, partner, roles, groups, file }) {
      return new Promise((resolve, reject) => {
        if (!name) {
          reject("name can't be empty")
          return
        }
        if (!groups || groups.length <= 0) {
          reject("groups can't be empty")
          return
        }
        if (!file) {
          reject("file can't be empty")
          return
        }

        let extension = FileUtils.getExtension(file)
        let mimetype = FileUtils.getMimetype(file)

        axios
          .post(urlPrefix + '/files', {
            name,
            description,
            extension,
            mimetype,
            partner,
            roles,
            groups
          })
          .then((res) => {
            const { file: fileData, upload: uploadData } = res.data.data

            S3Utils.upload(file, uploadData)
              .then(() => resolve(fileData))
              .catch((err) => {
                // Google Analytics
                ExceptionUtils.logToAnalytics(err)

                reject(err)
              })
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    update({ id, name, description, partner, roles, groups }) {
      return new Promise((resolve, reject) => {
        if (!id) {
          reject("id can't be empty")
          return
        }
        if (!name) {
          reject("name can't be empty")
          return
        }
        if (!groups || groups.length <= 0) {
          reject("groups can't be empty")
          return
        }

        axios
          .patch(urlPrefix + '/files/' + id, {
            name,
            description,
            partner,
            roles,
            groups
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    destroy(id) {
      return new Promise((resolve, reject) => {
        if (!id) {
          reject("id can't be empty")
          return
        }

        axios
          .delete(urlPrefix + '/files/' + id)
          .then(() => {
            resolve()
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    }
  },

  groups: {
    index() {
      return new Promise((resolve, reject) => {
        axios
          .get(urlPrefix + '/groups')
          .then((res) => {
            resolve(res.data.data)
            return
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    store({ name, description, partner, roles }) {
      return new Promise((resolve, reject) => {
        if (!name) {
          reject("name can't be empty")
          return
        }

        axios
          .post(urlPrefix + '/groups', {
            name,
            description,
            partner,
            roles
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    update({ id, name, description, order, partner, roles }) {
      return new Promise((resolve, reject) => {
        if (!id) {
          reject("id can't be empty")
          return
        }
        if (!name) {
          reject("name can't be empty")
          return
        }

        axios
          .patch(urlPrefix + '/groups/' + id, {
            name,
            description,
            order,
            partner,
            roles
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    },

    destroy(id) {
      return new Promise((resolve, reject) => {
        if (!id) {
          reject("id can't be empty")
          return
        }

        axios
          .delete(urlPrefix + '/groups/' + id)
          .then(() => {
            resolve()
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            reject(err)
          })
      })
    }
  }
}
