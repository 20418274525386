import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  todos: {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  }
}
