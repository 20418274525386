import { Chart, registerables } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import datalabelsPlugin from 'chartjs-plugin-datalabels'
import zoomPlugin from 'chartjs-plugin-zoom'

Chart.register(...registerables)

// chartjs-plugin-annotation
Chart.register(annotationPlugin)

// chartjs-plugin-datalabels
Chart.register(datalabelsPlugin)
Chart.defaults.set('plugins.datalabels', {
  display: false,
  backgroundColor: 'rgba(255, 255, 255, .75)',
  borderRadius: 6
})

// chartjs-plugin-zoom
Chart.register(zoomPlugin)
