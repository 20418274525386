<template>
  <select-active-partner
    v-model="selected"
    label="Carsharer"
    show-preferred-active-partner
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import SelectActivePartner from '@/components/inputs/SelectActivePartner'

export default {
  name: 'EditActivePartner',

  components: {
    SelectActivePartner
  },

  data() {
    return {
      loading: false,

      selected: null
    }
  },

  computed: {
    ...mapGetters('auth', ['activePartner'])
  },

  watch: {
    activePartner: {
      handler: function (value) {
        this.selected = value
      },
      immediate: true
    },

    selected: {
      handler: function (value) {
        this.$store.commit('auth/setActivePartner', value)
        this.$store.dispatch('theme/load')
      },
      immediate: true
    }
  }
}
</script>
