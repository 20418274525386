<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>$customer</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title> Kunde </v-list-item-title>
      <v-list-item-subtitle>
        {{ customer.identifier | emptyString }} /
        {{ customer.internal_identifier | emptyString }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="customer.name">
        {{ customer.name | emptyString }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            icon
            class="ma-0"
            :to="{
              name: 'customers-customer',
              params: { id: String(customer.id) },
              query: { mail: mailId ? String(mailId) : undefined }
            }"
            v-on="on"
          >
            <v-icon small>$action-show</v-icon>
          </v-btn>
        </template>
        <span>Kunde anzeigen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemCustomer',

  props: {
    customer: {
      type: Object,
      required: true
    },
    mailId: {
      type: [Number, String],
      required: false,
      default: null
    }
  }
}
</script>
