import Vue from 'vue'

Vue.filter('builderAssignmentCoverageStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NEW':
      return 'Eingegangen'
    case 'IN_APPROVAL':
      return 'In Freigabe'
    case 'APPROVED':
      return 'Freigegeben'
    case 'CANCELED':
      return 'Storniert'
    default:
      return value
  }
})
