import Vue from 'vue'

Vue.filter('eventTypename', function (value) {
  if (!value) return ''

  switch (value) {
    case 'VEHICLE_RESERVATION':
      return 'Fahrzeug-Reservierung'

    case 'VEHICLE_EVENT':
      return 'Fahrzeug-Event'

    case 'STORAGE_EVENT':
      return 'Ein-/Auslagerung'

    case 'MEETING':
      return 'Kundentermin'

    case 'LEAD_REMINDER':
      return 'Lead Wiedervorlage'

    case 'PMO_ABSENCE':
      return 'Abwesenheit'

    default:
      return value
  }
})
