<template>
  <div class="flex-container">
    <figure class="flex-item">
      <img :src="src" :alt="imageAlt" />
      <figcaption v-if="text">
        {{ text }}
      </figcaption>
    </figure>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: null
    },
    imageAlt: {
      type: String,
      required: true
    },
    imageName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      basePath: process.env.BASE_URL + 'img/errors/'
    }
  },

  computed: {
    src() {
      return this.basePath + this.imageName
    }
  }
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  & > .flex-item {
    max-width: 75%;

    img {
      width: 100%;
    }
    figcaption {
      margin-top: 1em;
      font-size: 2em;
      text-align: center;
    }
  }
}
</style>
