import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from 'vee-validate'
import {
  required,
  between,
  min,
  max,
  email,
  numeric,
  integer,
  double,
  min_value,
  max_value,
  oneOf,
  confirmed,
  image,
  mimes
} from 'vee-validate/dist/rules'
import Address from './rules/address'
import IBAN from './rules/iban'
import DateFormat from './rules/date_format'
import DateBetween from './rules/date_between'
import DateMin from './rules/date_min'
import DateMinEquals from './rules/date_min_equals'
import DateMax from './rules/date_max'
import DateMaxEquals from './rules/date_max_equals'
import DatePast from './rules/date_past'
import DateFuture from './rules/date_future'
import TimeMin from './rules/time_min'
import TimeMax from './rules/time_max'
import Checked from './rules/checked'
import Uniqueuser from './rules/uniqueuser'
import de from 'vee-validate/dist/locale/de.json'

// Add rules
extend('required', required)
extend('between', between)
extend('min', min)
extend('max', max)
extend('email', email)
extend('numeric', numeric)
extend('integer', integer)
extend('double', double)
extend('min_value', min_value)
extend('max_value', max_value)
extend('oneOf', oneOf)
extend('confirmed', confirmed)
extend('image', image)
extend('mimes', mimes)

// Add custom rules
extend('address', Address)
extend('iban', IBAN)
extend('date_format', DateFormat)
extend('date_between', DateBetween)
extend('date_min', DateMin)
extend('date_min_equals', DateMinEquals)
extend('date_max', DateMax)
extend('date_max_equals', DateMaxEquals)
extend('date_past', DatePast)
extend('date_future', DateFuture)
extend('time_min', TimeMin)
extend('time_max', TimeMax)
extend('checked', Checked)
extend('uniqueuser', Uniqueuser)

// install and set language
localize('de', de)

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
