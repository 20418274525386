import Vue from 'vue'
import _ from 'lodash'

// ##########################################################
// Bestandsdaten
// ##########################################################

Vue.filter('bestandPartnerID', function (value) {
  if (!value) return ''

  const id = _.get(value, 'id', null)
  const identifier = 'BP' + id.padStart(5, '0')

  return identifier
})

Vue.filter('bestandPartnerName', function (value) {
  if (!value) return ''

  const salutation = _.get(value, 'salutation.name', null)
  const text = []

  text.push(salutation)
  if (_.get(value, 'salutation.code', null) === '3') {
    // Company
    text.push(value.company)
  } else {
    // Privat person or something else
    text.push(value.firstname)
    text.push(value.lastname)
  }

  // Remove falsy values (empty, null, ...) and join with space
  return text.filter((item) => !!item).join(' ')
})

Vue.filter('bestandPartnerDetailsShort', function (value) {
  if (!value) return ''

  const filterDateAndTime = Vue.filter('dateAndTime')

  let text = []

  const id = _.get(value, 'id', null)
  const identifier = 'BP' + id.padStart(5, '0')
  const created_at = _.get(value, 'created_at', null)
  const updated_at = _.get(value, 'updated_at', null)

  if (id) {
    text.push(`ID: ${identifier}`)
  }
  if (created_at) {
    text.push('Erstellt: ' + filterDateAndTime(created_at))
  }
  if (updated_at) {
    text.push('Geändert: ' + filterDateAndTime(updated_at))
  }

  return text.join(', ')
})

Vue.filter('bestandVertragID', function (value) {
  if (!value) return ''

  const id = _.get(value, 'id', null)
  const identifier = 'BV' + id.padStart(6, '0')

  return identifier
})

Vue.filter('bestandVertragDetailsShort', function (value) {
  if (!value) return ''

  const filterDateAndTime = Vue.filter('dateAndTime')

  let text = []

  const id = _.get(value, 'id', null)
  const identifier = 'BV' + id.padStart(6, '0')
  const created_at = _.get(value, 'created_at', null)
  const updated_at = _.get(value, 'updated_at', null)

  if (id) {
    text.push(`ID: ${identifier}`)
  }
  if (created_at) {
    text.push('Erstellt: ' + filterDateAndTime(created_at))
  }
  if (updated_at) {
    text.push('Geändert: ' + filterDateAndTime(updated_at))
  }

  return text.join(', ')
})

Vue.filter('bestandSchadenID', function (value) {
  if (!value) return ''

  const id = _.get(value, 'id', null)
  const identifier = 'BS' + id.padStart(5, '0')

  return identifier
})

Vue.filter('bestandSchadenDetailsShort', function (value) {
  if (!value) return ''

  const filterDateAndTime = Vue.filter('dateAndTime')

  let text = []

  const id = _.get(value, 'id', null)
  const identifier = 'BS' + id.padStart(5, '0')
  const created_at = _.get(value, 'created_at', null)
  const updated_at = _.get(value, 'updated_at', null)

  if (id) {
    text.push(`ID: ${identifier}`)
  }
  if (created_at) {
    text.push('Erstellt: ' + filterDateAndTime(created_at))
  }
  if (updated_at) {
    text.push('Geändert: ' + filterDateAndTime(updated_at))
  }

  return text.join(', ')
})

Vue.filter('bestandSchadenZahlungID', function (value) {
  if (!value) return ''

  const id = _.get(value, 'id', null)
  const identifier = 'BSZ' + id.padStart(5, '0')

  return identifier
})
