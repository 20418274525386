<template>
  <v-list nav dense>
    <template v-for="(item, index1) in visibleItems">
      <!-- subheader -->
      <v-subheader v-if="item.header" :key="index1">
        {{ item.header }}
      </v-subheader>

      <!-- item with children -->
      <v-list-group
        v-else-if="item.children"
        :key="index1"
        :prepend-icon="item.icon"
        :disabled="item.disabled"
        :group="item.group"
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle">
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template v-for="(subitem, index2) in item.children">
          <!-- item with children -->
          <v-list-group
            v-if="subitem.children"
            :key="index2"
            :disabled="subitem.disabled"
            :group="subitem.group"
            no-action
            sub-group
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="subitem.subtitle">
                  {{ subitem.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>

            <template v-for="(subitem2, index3) in subitem.children">
              <v-list-item
                :key="index3"
                :to="subitem2.routeName ? { name: subitem2.routeName } : null"
                :disabled="subitem2.disabled"
                :exact="subitem2.exact"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ subitem2.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="subitem2.subtitle">
                    {{ subitem2.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>{{ subitem2.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-group>

          <!-- single item -->
          <v-list-item
            v-else
            :key="index2"
            :to="subitem.routeName ? { name: subitem.routeName } : null"
            :disabled="subitem.disabled"
            :exact="subitem.exact"
          >
            <v-list-item-icon>
              <v-icon>{{ subitem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="subitem.subtitle">
                {{ subitem.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="item.icon_action">
              <v-icon small>{{ item.icon_action }}</v-icon>
            </v-list-item-action>

            <v-chip v-if="subitem.badge" x-small color="info">{{
              subitem.badge
            }}</v-chip>
          </v-list-item>
        </template>
      </v-list-group>

      <!-- single item -->
      <v-list-item
        v-else
        :key="index1"
        :to="item.routeName ? { name: item.routeName } : null"
        :disabled="item.disabled"
        :exact="item.exact"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle v-if="item.subtitle">
            {{ item.subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="item.icon_action">
          <v-icon small>{{ item.icon_action }}</v-icon>
        </v-list-item-action>

        <v-chip v-if="item.badge" x-small color="info">{{ item.badge }}</v-chip>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AppNavigationDrawerListBase',

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState(['client']),
    ...mapGetters('auth', ['roles', 'permissions']),

    visibleItems() {
      return this.filterHeaders(this.filterChildren(this.items)) || []
    }
  },

  methods: {
    isVisible(routeName) {
      if (!routeName) {
        // if there is no routeName, it should be visible (placeholder)
        return true
      }

      const resolvedRoute = this.$router.resolve({ name: routeName }).resolved
      if (resolvedRoute) {
        const routeRoles = resolvedRoute.meta.requiresRoles
        const routePermissions = resolvedRoute.meta.requiresPermissions
        const routeClient = resolvedRoute.meta.requiresClients

        return (
          this.isVisibleRoles(routeRoles) &&
          this.isVisiblePermissions(routePermissions) &&
          this.isVisibleClients(routeClient)
        )
      } else {
        // if there is no route, it should be visible (placeholder)
        return true
      }
    },

    isVisibleRoles(requiredRoles) {
      if (this.roles && requiredRoles) {
        return this.roles.some((item) => requiredRoles.includes(item))
      } else {
        // if there is no requiredRoles, it should be visible
        return true
      }
    },

    isVisiblePermissions(requiredPermissions) {
      if (this.permissions && requiredPermissions) {
        return this.permissions.some((item) =>
          requiredPermissions.includes(item)
        )
      } else {
        // if there is no requiredPermissions, it should be visible
        return true
      }
    },

    isVisibleClients(requiresClients) {
      if (this.client && requiresClients) {
        return requiresClients.includes(this.client)
      } else {
        // if there is no requiresClients, it should be visible
        return true
      }
    },

    filterChildren(children) {
      if (!children || children.length === 0) {
        return []
      }

      const mappedChildren = children.map((item) => {
        if (!item.children) {
          // the item has no children, so we will check if it should be visible or not
          return this.isVisible(item.routeName) ? item : null
        } else {
          // the item has children, so we will check the children
          const children = this.filterChildren(item.children)
          if (children && children.length > 0) {
            return {
              /*
              routeName: item.routeName,
              icon: item.icon,
              title: item.title,
              disabled: item.disabled,
              group: item.group
               */
              ...item,
              children: children
            }
          } else {
            return null
          }
        }
      })

      // remove falsy (null, ...) values from array
      return _.compact(mappedChildren)
    },

    filterHeaders(items) {
      if (!items || items.length === 0) {
        return []
      }

      // we need to filter the headers afterwards, because we need to check if there are some items between the headers.
      return items.filter((item, index) => {
        if (item.header) {
          // item is a header, we need to check if there is something after it (which is not another header). Then we can show it
          const nextItem = items?.[index + 1]
          if (nextItem) {
            // There is another item after the header. If it is another header, we hide it. Otherwise we can show it.
            return !nextItem.header
          }

          // there is no item after the header, so we hide it
          return false
        }

        // item is not a header, we can show it
        return true
      })
    }
  }
}
</script>
