<template>
  <error-default
    text="Sie haben keine Berechtigung diese Seite aufzurufen."
    image-alt="401 Unauthorized"
    image-name="401.svg"
  ></error-default>
</template>

<script>
import ErrorDefault from '@/components/ErrorDefault.vue'

export default {
  components: {
    ErrorDefault
  }
}
</script>
