import axios from 'axios'
import _ from 'lodash'
import ExceptionUtils from '@/utils/exceptions.js'
import ApiUtils from '@/utils/api.js'

const urlPrefix = ApiUtils.getApiUrl() + '/tarifrechner/freshcarflat/'

const parseRangliste = function (data) {
  if (data) {
    return data.map((item, index) => {
      return Object.assign(item, {
        rang: index + 1
      })
    })
  } else {
    return []
  }
}

export default {
  ////////////
  // Dashboard
  ////////////
  loadAutohaeuser(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + 'rangliste', {
          params: {
            art: 'RANG_AH',
            von: dateFrom ? dateFrom : undefined,
            bis: dateTo ? dateTo : undefined
          }
        })
        .then((res) => {
          resolve(parseRangliste(res.data.data))
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadAutohaeuserChart(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + 'diagramm', {
          params: {
            art: 'RANG_AH',
            von: dateFrom ? dateFrom : undefined,
            bis: dateTo ? dateTo : undefined
          }
        })
        .then((res) => {
          resolve(parseRangliste(res.data.data))
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadStandorte(id, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + 'rangliste', {
          params: {
            autohausId: id,
            art: 'RANG_STANDORTE',
            von: dateFrom ? dateFrom : undefined,
            bis: dateTo ? dateTo : undefined
          }
        })
        .then((res) => {
          resolve(parseRangliste(res.data.data))
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadStandorteChart(id, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + 'diagramm', {
          params: {
            autohausId: id,
            art: 'RANG_STANDORTE',
            von: dateFrom ? dateFrom : undefined,
            bis: dateTo ? dateTo : undefined
          }
        })
        .then((res) => {
          resolve(parseRangliste(res.data.data))
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadVerkaeufer(id, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + 'rangliste', {
          params: {
            autohausId: id,
            art: 'RANG_VERKAEUFER',
            von: dateFrom ? dateFrom : undefined,
            bis: dateTo ? dateTo : undefined
          }
        })
        .then((res) => {
          resolve(parseRangliste(res.data.data))
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadVerkaeuferChart(id, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + 'diagramm', {
          params: {
            autohausId: id,
            art: 'RANG_VERKAEUFER',
            von: dateFrom ? dateFrom : undefined,
            bis: dateTo ? dateTo : undefined
          }
        })
        .then((res) => {
          resolve(parseRangliste(res.data.data))
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  /////////
  // Antrag
  /////////
  loadPraemie(data) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + 'beitrag', {
          params: {
            // do it one by one to avoid other data beeing inserted into request
            kfzvnanrede: data.anrede,
            kfzart: data.fahrzeugart,
            kfzversicherungsbeginn: data.versicherungsbeginn,
            kfzlaufzeit: data.laufzeit,
            kfzstaerke: data.kfzstaerke,
            kfzfahrzeugwert: data.fahrzeugwert,
            kfzvngebdat: data.geburtsdatum,
            kfzjungerfahreru23: data.fahreru23,
            kfzjungerfahreru33: data.fahreru33,
            kfzzahlweise: data.zahlweise
          }
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  loadAntrag(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(urlPrefix + 'antraege/' + id)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  storeAntrag(data) {
    let requestData = {}

    if (data.versicherungsnehmer.type === 'FIRMA') {
      // Firma
      requestData = {
        // Verkaeufer
        verkaeuferId: _.get(data, 'verkaeufer.id', null),
        autohausId: _.get(data, 'autohaus.id', null),

        // Firmendaten
        cfdanrede: _.get(data, 'versicherungsnehmer.anrede', null),
        cfdzusatz: _.get(data, 'versicherungsnehmer.firmenname', null),
        cfdstrasse: _.get(data, 'versicherungsnehmer.adresse.strasse', null),
        cfdplz: _.get(data, 'versicherungsnehmer.adresse.plz', null),
        cfdort: _.get(data, 'versicherungsnehmer.adresse.ort', null),
        cfdmonatseinkommen: _.get(
          data,
          'versicherungsnehmer.jahresbruttoumsatz',
          null
        ),

        // Geschaeftsfuehrung
        cfdanredegf: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.anrede',
          null
        ),
        cfdvorname: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.vorname',
          null
        ),
        cfdnachname: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.nachname',
          null
        ),
        cfdgebdat: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.geburtsdatum',
          null
        ),
        cfdlndcode: _.get(data, 'versicherungsnehmer.nationalitaet', null),
        cfdtelefon: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.telefon',
          null
        ),
        cfdmobil: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.mobil',
          null
        ),
        cfdemail: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.email',
          null
        )
      }
    } else {
      requestData = {
        // Verkaeufer
        verkaeuferId: _.get(data, 'verkaeufer.id', null),
        autohausId: _.get(data, 'autohaus.id', null),

        // Kundendaten
        cfdanrede: _.get(data, 'versicherungsnehmer.anrede', null),
        cfdvorname: _.get(data, 'versicherungsnehmer.vorname', null),
        cfdnachname: _.get(data, 'versicherungsnehmer.nachname', null),
        cfdgebdat: _.get(data, 'versicherungsnehmer.geburtsdatum', null),
        cfdoedkennz: _.get(data, 'versicherungsnehmer.oed', null),
        cfdlndcode: _.get(data, 'versicherungsnehmer.nationalitaet', null),
        cfdtelefon: _.get(data, 'versicherungsnehmer.telefon', null),
        cfdmobil: _.get(data, 'versicherungsnehmer.mobil', null),
        cfdemail: _.get(data, 'versicherungsnehmer.email', null),
        cfdwohneigentum: _.get(data, 'versicherungsnehmer.wohneigentum', null),
        cfdmonatseinkommen: _.get(
          data,
          'versicherungsnehmer.nettoeinkommen',
          null
        ),
        cfdfamilienstand: _.get(
          data,
          'versicherungsnehmer.familienstand',
          null
        ),
        cfdanzkinder: _.get(data, 'versicherungsnehmer.kinder', null),

        // Adresse
        cfdstrasse: _.get(data, 'versicherungsnehmer.adresse.strasse', null),
        cfdplz: _.get(data, 'versicherungsnehmer.adresse.plz', null),
        cfdort: _.get(data, 'versicherungsnehmer.adresse.ort', null)
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'antraege', requestData)
        .then((res) => {
          resolve(res.data.data.id)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  updateAntrag(data) {
    let requestData = {}

    if (data.versicherungsnehmer.type === 'FIRMA') {
      // Firma
      requestData = {
        cfkId: _.get(data, 'fahrzeugId', null),

        // Verkaeufer
        verkaeuferId: _.get(data, 'verkaeufer.id', null),
        autohausId: _.get(data, 'autohaus.id', null),

        // Firmendaten
        cfdanrede: _.get(data, 'versicherungsnehmer.anrede', null),
        cfdzusatz: _.get(data, 'versicherungsnehmer.firmenname', null),
        cfdstrasse: _.get(data, 'versicherungsnehmer.adresse.strasse', null),
        cfdplz: _.get(data, 'versicherungsnehmer.adresse.plz', null),
        cfdort: _.get(data, 'versicherungsnehmer.adresse.ort', null),
        cfdmonatseinkommen: _.get(
          data,
          'versicherungsnehmer.jahresbruttoumsatz',
          null
        ),

        // Geschaeftsfuehrung
        cfdanredegf: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.anrede',
          null
        ),
        cfdvorname: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.vorname',
          null
        ),
        cfdnachname: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.nachname',
          null
        ),
        cfdgebdat: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.geburtsdatum',
          null
        ),
        cfdlndcode: _.get(data, 'versicherungsnehmer.nationalitaet', null),
        cfdtelefon: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.telefon',
          null
        ),
        cfdmobil: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.mobil',
          null
        ),
        cfdemail: _.get(
          data,
          'versicherungsnehmer.geschaeftsfuehrung.email',
          null
        )
      }
    } else {
      requestData = {
        cfkId: _.get(data, 'fahrzeugId', null),

        // Verkaeufer
        verkaeuferId: _.get(data, 'verkaeufer.id', null),
        autohausId: _.get(data, 'autohaus.id', null),

        // Kundendaten
        cfdanrede: _.get(data, 'versicherungsnehmer.anrede', null),
        cfdvorname: _.get(data, 'versicherungsnehmer.vorname', null),
        cfdnachname: _.get(data, 'versicherungsnehmer.nachname', null),
        cfdgebdat: _.get(data, 'versicherungsnehmer.geburtsdatum', null),
        cfdoedkennz: _.get(data, 'versicherungsnehmer.oed', null),
        cfdlndcode: _.get(data, 'versicherungsnehmer.nationalitaet', null),
        cfdtelefon: _.get(data, 'versicherungsnehmer.telefon', null),
        cfdmobil: _.get(data, 'versicherungsnehmer.mobil', null),
        cfdemail: _.get(data, 'versicherungsnehmer.email', null),
        cfdwohneigentum: _.get(data, 'versicherungsnehmer.wohneigentum', null),
        cfdmonatseinkommen: _.get(
          data,
          'versicherungsnehmer.nettoeinkommen',
          null
        ),
        cfdfamilienstand: _.get(
          data,
          'versicherungsnehmer.familienstand',
          null
        ),
        cfdanzkinder: _.get(data, 'versicherungsnehmer.kinder', null),

        // Adresse
        cfdstrasse: _.get(data, 'versicherungsnehmer.adresse.strasse', null),
        cfdplz: _.get(data, 'versicherungsnehmer.adresse.plz', null),
        cfdort: _.get(data, 'versicherungsnehmer.adresse.ort', null)
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .patch(urlPrefix + 'antraege/' + data.antragId, requestData)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  deleteAntrag(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(urlPrefix + 'antraege/' + id)
        .then(() => {
          resolve(id)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  //////////////////
  // Antrag Fahrzeug
  //////////////////
  storeFahrzeug(antragId, data) {
    let requestData = {
      cfdId: antragId,
      cfklaufzeit: _.get(data, 'laufzeit', null),
      cfkfahrzeugart: _.get(data, 'fahrzeugart', null),
      cfkkfzstaerkeart: null,
      cfkkfzstaerke: _.get(data, 'kfzstaerke', null),
      cfkkfzstaerkeps: null,
      cfkkfzzulassung: _.get(data, 'zulassung', null),
      cfkkfzabtagzulassung: _.get(data, 'zulassungUnbekannt', null)
        ? 'J'
        : null,
      cfkkfzversicherungsbeginn: _.get(data, 'versicherungsbeginn', null),
      cfkkfzversicherungsende: null,
      cfkkfzkmabschluss: _.get(data, 'kilometerstand', null),
      cfkkfzfahrzeugwert: _.get(data, 'fahrzeugwert', null),
      cfkkfzfahrleistung: _.get(data, 'fahrleistung', null),
      cfkkfzfinanzierung: _.get(data, 'finanzierungsart.code', null),
      cfkkfzlaufzeitfinanzierung: _.get(
        data,
        'finanzierungsart.laufzeit',
        null
      ),
      cfkkfzabstellplatz: _.get(data, 'abstellplatz', null),
      cfkkfzkennzeichen: _.get(data, 'kennzeichen', null),
      cfkkfzgebdatfahreru23: _.get(data, 'fahreru23', null),
      cfkkfzgebdatfahreru33: _.get(data, 'fahreru33', null),
      cfkzahlweise: _.get(data, 'zahlweise', null),
      cfkiban: _.get(data, 'praemienzahler.iban', null),
      cfkbic: null,
      cfkabwktoanrede: _.get(data, 'praemienzahler.anrede', null),
      cfkabwktonachname: _.get(data, 'praemienzahler.nachname', null),
      cfkabwktovorname: _.get(data, 'praemienzahler.vorname', null),
      cfkabwktostrasse: _.get(data, 'praemienzahler.adresse.strasse', null),
      cfkabwktoplz: _.get(data, 'praemienzahler.adresse.plz', null),
      cfkabwktoort: _.get(data, 'praemienzahler.adresse.ort', null),
      cfkfreitext: _.get(data, 'freitext', null),
      cfkkasko: _.get(data, 'kasko', null)
    }

    // Add Vorversicherung
    if (data.vorversicherung) {
      requestData = Object.assign(requestData, {
        cfkversicherer: _.get(data, 'vorversicherung.name', null),
        cfkversicherungsnummer: _.get(data, 'vorversicherung.nummer', null),
        cfksfklasse: _.get(data, 'vorversicherung.sf', null)
      })
    }

    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'fahrzeuge', requestData)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  updateFahrzeug({ fahrzeug, prozess }) {
    let requestData = {
      prozess: prozess,
      cfklaufzeit: _.get(fahrzeug, 'laufzeit', null),
      cfkfahrzeugart: _.get(fahrzeug, 'fahrzeugart', null),
      cfkkfzstaerke: _.get(fahrzeug, 'kfzstaerke', null),
      cfkkfzzulassung: _.get(fahrzeug, 'zulassung', null),
      cfkkfzabtagzulassung: _.get(fahrzeug, 'zulassungUnbekannt', null)
        ? 'J'
        : null,
      cfkkfzversicherungsbeginn: _.get(fahrzeug, 'versicherungsbeginn', null),
      cfkkfzkmabschluss: _.get(fahrzeug, 'kilometerstand', null),
      cfkkfzfahrzeugwert: _.get(fahrzeug, 'fahrzeugwert', null),
      cfkkfzfahrleistung: _.get(fahrzeug, 'fahrleistung', null),
      cfkkfzfinanzierung: _.get(fahrzeug, 'finanzierungsart.code', null),
      cfkkfzlaufzeitfinanzierung: _.get(
        fahrzeug,
        'finanzierungsart.laufzeit',
        null
      ),
      cfkkfzabstellplatz: _.get(fahrzeug, 'abstellplatz', null),
      cfkkfzkennzeichen: _.get(fahrzeug, 'kennzeichen', null),
      cfkkfzgebdatfahreru23: _.get(fahrzeug, 'fahreru23', null),
      cfkkfzgebdatfahreru33: _.get(fahrzeug, 'fahreru33', null),
      cfkzahlweise: _.get(fahrzeug, 'zahlweise', null),
      cfkiban: _.get(fahrzeug, 'praemienzahler.iban', null),
      cfkbic: null, // gibt's nicht
      cfkabwktoanrede: _.get(fahrzeug, 'praemienzahler.anrede', null),
      cfkabwktonachname: _.get(fahrzeug, 'praemienzahler.nachname', null),
      cfkabwktovorname: _.get(fahrzeug, 'praemienzahler.vorname', null),
      cfkabwktostrasse: _.get(fahrzeug, 'praemienzahler.adresse.strasse', null),
      cfkabwktoplz: _.get(fahrzeug, 'praemienzahler.adresse.plz', null),
      cfkabwktoort: _.get(fahrzeug, 'praemienzahler.adresse.ort', null),
      cfkfreitext: _.get(fahrzeug, 'freitext', null),
      cfkkasko: _.get(fahrzeug, 'kasko', null)
    }

    // Add Vorversicherung
    if (fahrzeug.vorversicherung) {
      requestData = Object.assign(requestData, {
        cfkversicherer: _.get(fahrzeug, 'vorversicherung.name', null),
        cfkversicherungsnummer: _.get(fahrzeug, 'vorversicherung.nummer', null),
        cfksfklasse: _.get(fahrzeug, 'vorversicherung.sf', null)
      })
    }

    return new Promise((resolve, reject) => {
      axios
        .patch(urlPrefix + 'fahrzeuge/' + fahrzeug.id, requestData)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  deleteFahrzeug(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(urlPrefix + 'fahrzeuge/' + id)
        .then(() => {
          resolve(id)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  abschliessenFahrzeug(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'fahrzeuge/' + id + '/abschliessen')
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  verlaengernFahrzeug({
    id,
    laufzeit,
    vertragsablauf,
    partnernummer,
    vertragsnummer,
    kfzkennzeichen
  }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'fahrzeuge/' + id + '/verlaengern', {
          antlaufzeit: laufzeit,
          antvertragsablauf: vertragsablauf,
          vuparnummer: partnernummer,
          vuvernummer: vertragsnummer,
          kfzkennzeichen: kfzkennzeichen
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  freigebenFahrzeug({
    id,
    partnernummer,
    vertragsnummer,
    kundenart,
    evbnummer
  }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'fahrzeuge/' + id + '/freigeben', {
          vuparnummer: partnernummer,
          vuvernummer: vertragsnummer,
          vukundenart: kundenart,
          vuevbnummer: evbnummer
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  aufhebenFahrzeug({ id, datum, grund, infos, kennzeichen, vertragsnummer }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'fahrzeuge/' + id + '/aufheben', {
          datum,
          grund,
          infos,
          kennzeichen,
          vertragsnummer
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  aufhebenPlanenFahrzeug({ id, datum, kennzeichen, vertragsnummer, freitext }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'fahrzeuge/' + id + '/aufheben-planen', {
          datum,
          kennzeichen,
          vertragsnummer,
          freitext
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  aufhebenPlanenCancelFahrzeug(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'fahrzeuge/' + id + '/aufheben-planen-cancel')
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  },

  sendenAngebot({ id, empfaenger, email }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + 'fahrzeuge/' + id + '/angebot', {
          empfaengerKDN: empfaenger === 'KUNDE' ? email : null,
          empfaengerVK: empfaenger === 'VERKAEUFER' ? email : null
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          if (err.response) {
            reject(err.response.data)
          } else {
            reject()
          }
        })
    })
  }
}
