import log from 'loglevel'
import PushApi from '@/api/push.js'

export default {
  registerPush() {
    if ('PushManager' in window) {
      log.info('PWA Push: supported')

      if (process.env.VUE_APP_VAPID_PUBLIC_KEY) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            navigator.serviceWorker.ready
              .then((registration) => {
                const options = {
                  userVisibleOnly: true,
                  applicationServerKey: this.urlBase64ToUint8Array(
                    process.env.VUE_APP_VAPID_PUBLIC_KEY
                  )
                }

                return registration.pushManager.subscribe(options)
              })
              .then((subscription) => {
                return PushApi.register({ subscription })
              })
              .catch((err) => {
                log.error(err)
              })
          }
        })
      }
    } else {
      log.info('PWA Push: not supported')
    }
  },

  deregisterPush() {
    return new Promise((resolve, reject) => {
      if ('PushManager' in window) {
        navigator.serviceWorker.ready
          .then((registration) => {
            return registration.pushManager.getSubscription()
          })
          .then((subscription) => {
            const endpoint = subscription?.endpoint

            return Promise.all([
              endpoint ? PushApi.deregister({ endpoint }) : Promise.resolve(),
              subscription.unsubscribe()
            ])
          })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            log.error(err)
            reject(err)
          })
      } else {
        resolve()
      }
    })
  },

  urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    var base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

    var rawData = window.atob(base64)
    var outputArray = new Uint8Array(rawData.length)

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }
}
