<template>
  <v-main>
    <alert-unsupported-browser />

    <v-container :fluid="fluid" :class="{ 'fill-height': fillHeight }">
      <transition name="fade-fast" mode="out-in">
        <router-view :side-sheet-data="sideSheetData" />
      </transition>
    </v-container>

    <!-- global progress -->
    <progress-dialog />
    <progress-snackbar />
  </v-main>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import ProgressDialog from '@/components/ProgressDialog.vue'
import ProgressSnackbar from '@/components/ProgressSnackbar.vue'
import { AlertUnsupportedBrowser } from '@freshsolutions/fresh-vue'

export default {
  name: 'AppContent',

  components: {
    AlertUnsupportedBrowser,
    ProgressDialog,
    ProgressSnackbar
  },

  computed: {
    ...mapGetters('layout', ['sideSheetData']),

    fillHeight() {
      return _.get(this.$route, 'meta.layout.fillHeight', false)
    },

    fluid() {
      return _.get(this.$route, 'meta.layout.fluid', false)
    }
  }
}
</script>
