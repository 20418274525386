<template>
  <v-card outlined>
    <v-card-title class="pa-0 pl-2">
      <span>{{ todo.title }}</span>
      <v-spacer />
      <todos-list-todo-actions
        :todo="todo"
        @submit:success="$emit('submit:success')"
        @submit:fail="$emit('submit:fail')"
      />
    </v-card-title>

    <v-card-text v-if="todo.description" class="py-0 px-2">
      <span class="font-italic text-pre-wrap">
        {{ todo.description }}
      </span>
    </v-card-text>

    <v-card-actions class="pr-4">
      <div class="text-caption">
        {{ todo.created_by.name }} | {{ todo.created_at | dateAndTime }}
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import TodosListTodoActions from './TodosListTodoActions'

export default {
  name: 'TodosListTodo',

  components: {
    TodosListTodoActions
  },

  props: {
    todo: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('auth', ['user'])
  }
}
</script>
