<template>
  <v-tooltip
    :key="internalStatus.icon"
    :top="tooltipPosition === 'top'"
    :bottom="tooltipPosition === 'bottom'"
    :left="tooltipPosition === 'left'"
    :right="tooltipPosition === 'right'"
    :disabled="iconOnly"
  >
    <template #activator="{ on }">
      <template v-if="internalStatus.loading">
        <v-progress-circular
          indeterminate
          :color="internalStatus.color"
          v-on="on"
        />
      </template>

      <template v-else>
        <v-icon v-bind="$attrs" v-on="on">
          {{ internalStatus.icon }}
        </v-icon>
      </template>
    </template>
    <div>
      <div>{{ internalStatus.text }}</div>
      <div v-if="internalStatus.textAdditional">
        {{ internalStatus.textAdditional }}
      </div>
      <ul v-if="internalStatus.textList">
        <li v-for="item in internalStatus.textList" :key="item">
          {{ item }}
        </li>
      </ul>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'UserStatus',

  props: {
    status: {
      type: String,
      required: false,
      default: null
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: 'left'
    },
    iconOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    internalStatus() {
      const status = this.status
      const filter = this.$options.filters.userStatus

      switch (status) {
        case 'ONLINE':
        case 'BUSY':
        case 'OFFLINE':
          return {
            icon: '$user-status-' + status,
            text: filter(status)
          }

        default:
          return {
            icon: '$user-status-DEFAULT',
            text: this.status || 'Unbekannt'
          }
      }
    }
  }
}
</script>
