import Vue from 'vue'
import moment from 'moment'

Vue.filter('timer', function (value) {
  var duration = moment.duration(value)

  return [
    duration.hours(),
    new String(duration.minutes()).padStart(2, '0'),
    new String(duration.seconds()).padStart(2, '0')
  ].join(':')
})
