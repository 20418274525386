import Vue from 'vue'

Vue.filter('freshbikeApplicationStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'SENT':
      return 'Übermittelt'
    default:
      return value
  }
})

Vue.filter('freshbikeBikeStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'SENT':
      return 'Übermittelt'
    case 'OK':
      return 'Policiert / Bestand'
    case 'CANCELED':
      return 'Gekündigt'
    default:
      return value
  }
})

Vue.filter('freshbikeBikeHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'FRESHBIKE_BIKE_CANCELED':
      return 'Fahrrad gekündigt'
    case 'FRESHBIKE_BIKE_SET_STATUS_OK':
      return 'Fahrrad policiert'
    default:
      return undefined // return default event text
  }
})

Vue.filter('freshbikeContractType', function (value) {
  if (!value) return ''

  switch (value) {
    case 'AGGREGATIVE':
      return 'Sammelvertrag'
    case 'SINGLE':
      return 'Einzelverträge'
    default:
      return value
  }
})

// Schadenmeldung / Claim
Vue.filter('freshbikeClaimHistoryEvent', function (value) {
  if (!value) return ''

  switch (value) {
    case 'FRESHBIKE_CLAIM_INPUT_VUCLAIMID_MANUAL':
      return 'Schadennummer manuell eingetragen'
    case 'FRESHBIKE_CLAIM_INPUT_VUCLAIMID_AUTOMATIC':
      return 'Schadennummer automatisch eingetragen'
    case 'FRESHBIKE_CLAIM_SENT_VU':
      return 'Schadenmeldung an VU übermittelt'
    case 'FRESHBIKE_CLAIM_SENT_FFF':
      return 'Schadenmeldung an FFF übermittelt'
    case 'FRESHBIKE_CLAIM_CLOSED':
      return 'Schadenmeldung geschlossen'
    case 'FRESHBIKE_CLAIM_GENERATE_PDF':
      return 'Schadenmeldung als PDF exportiert'
    case 'FRESHBIKE_CLAIM_CONTACT_VU_EMAIL':
      return 'Versicherer kontaktiert'
    case 'FRESHBIKE_CLAIM_RECEIVED_EMAIL':
      return 'E-Mail eingegangen'
    default:
      return undefined // return default event text
  }
})

Vue.filter('freshbikeClaimStatus', function (value) {
  if (!value) return ''

  switch (value) {
    case 'NEW':
      return 'Neu (noch nicht an VU übermittelt)'
    case 'CHECK':
      return 'An FFF übermittelt (Warten auf Prüfung)'
    case 'SENT':
      return 'An VU übermittelt (Warten auf Schadennummer)'
    case 'IN_PROGRESS':
      return 'Schaden in Bearbeitung (Schadennummer erhalten)'
    case 'CLOSED':
      return 'Schaden geschlossen'
    default:
      return value
  }
})

Vue.filter('freshbikeClaimGuiltytype', function (value) {
  if (!value) return ''

  switch (value) {
    case 'SIMPLE_THEFT':
      return 'Einfacher Diebstahl (SB 250 EUR) *1'
    case 'BURGLARY':
      return 'Einbruchdiebstahl (kein SB) *2'
    case 'ROBBERY':
      return 'Raub (kein SB) *2'
    case 'PARTS_THEFT':
      return 'Teilediebstahl (auch Akku) (SB 250 EUR oder kein SB) *1 und *2'
    case 'FIRE':
      return 'Feuer (Brand, Explosion, Implosion, Fahrzeuganprall, Rauch, Überschallknall) (kein SB)'
    case 'DAMAGE':
      return 'Böswillige Beschädigung, Streik, Aussperrung, Innere Unruhen, (kein SB)'
    default:
      return value
  }
})
