<template>
  <v-app-bar
    app
    clipped-right
    :hide-on-scroll="$vuetify.breakpoint.mobile"
    elevate-on-scroll
  >
    <template #img>
      <app-logo height="48px" class="my-2" />
    </template>

    <template v-if="$slots.extension" #extension>
      <slot name="extension" />
    </template>

    <v-app-bar-nav-icon
      @click.stop="$store.dispatch('layout/toggleNavigationDrawer')"
    />

    <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">
      {{ title }}
    </v-toolbar-title>

    <v-spacer />

    <app-environment-info :small="$vuetify.breakpoint.mobile" />
  </v-app-bar>
</template>

<script>
import _ from 'lodash'

import AppLogo from '@/layout/AppLogo'
import AppEnvironmentInfo from '@/layout/AppEnvironmentInfo'

export default {
  name: 'AppBarBase',

  components: {
    AppLogo,
    AppEnvironmentInfo
  },

  computed: {
    title() {
      return _.get(this.$route, 'meta.title', '')
    }
  }
}
</script>
