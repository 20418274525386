<template>
  <select-userstatus
    v-model="status"
    v-bind="$attrs"
    label="Ihr Status"
    :loading="internalLoading"
    :disabled="internalDisabled"
    @change="submit"
  />
</template>

<script>
import gql from 'graphql-tag'

import SelectUserstatus from '@/components/inputs/SelectUserstatus'

export default {
  name: 'EditUserstatus',

  components: {
    SelectUserstatus
  },

  apollo: {
    me: {
      query: gql`
        query status {
          me {
            id
            absence_status
          }
        }
      `
    }
  },

  data() {
    return {
      loading: false,

      me: null,
      status: null
    }
  },

  computed: {
    internalLoading() {
      return this.loading || this.$apollo.queries.me.loading
    },

    internalDisabled() {
      return this.internalLoading
    }
  },

  watch: {
    'me.absence_status': {
      handler(status) {
        this.status = status
      },
      immediate: true
    }
  },

  methods: {
    submit() {
      // status was changed by the user, we need to update it in the api
      this.loading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateMe($input: UpdateMeInput!) {
              updateMe(input: $input) {
                id
                absence_status
              }
            }
          `,
          variables: {
            input: {
              absence_status: this.status
            }
          }
        })
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
