import Vue from 'vue'

const anredeText = function (anrede) {
  switch (anrede) {
    case '1':
      return 'Herr'
    case '2':
      return 'Frau'
    case '3':
      return 'Firma'
    default:
      return ''
  }
}

Vue.filter('partner', function (value) {
  const filterName = Vue.filter('partnerName')
  const filterAddress = Vue.filter('partnerAddress')

  const formattedName = filterName(value)
  const formattedAddress = filterAddress(value)

  return formattedName + (formattedAddress ? ', ' + formattedAddress : '')
})

Vue.filter('partnerName', function (value) {
  if (!value) {
    return ''
  }
  const anrede = anredeText(value.anrede)

  if (value.fullname) {
    // This is the already formatted name from the API. We should use this, if it is present. Otherwise we will try to format it ourself.
    return value.fullname
  } else if (value.parname) {
    // This is the already formatted name from the API (Rangliste freshcarflat). We should use this, if it is present. Otherwise we will try to format it ourself.
    return value.parname
  } else if (value.name) {
    // This is the already formatted name from the API (GraphQL). We should use this, if it is present. Otherwise we will try to format it ourself.
    return value.name
  } else if (value.firmenname) {
    return (anrede ? anrede + ' ' : '') + value.firmenname
  } else if (value.nachname || value.vorname) {
    return (anrede ? anrede + ' ' : '') + value.nachname + ', ' + value.vorname
  } else {
    return '-'
  }
})

Vue.filter('partnerAddress', function (value) {
  if (!value) {
    return ''
  }

  let address = value
  let text = ''

  // if the parameter is the whole versicherungsnehmer, than there should be an "adresse" object inside it.
  if (value.adresse || value.address) {
    address = value.adresse || value.address
  }

  if (address.strasse) {
    text += address.strasse
  }

  if (address.plz) {
    if (address.strasse) {
      text += ', '
    }
    text += address.plz
  }

  if (address.ort) {
    if (address.plz) {
      text += ' '
    }
    text += address.ort
  }

  // Format: STREET, PLZ ORT
  return text ? text : '-'
})
