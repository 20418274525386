// import axios from 'axios'
import UserApi from '@/api/user.js'
import AuthApi from '@/api/auth.js'
import ExceptionUtils from '@/utils/exceptions.js'
import ServiceworkerUtils from '@/utils/serviceworker.js'
import { apolloClient } from '@/plugins/vue-apollo'

export default {
  namespaced: true,

  state: {
    token: null,
    loading: false,

    user: {},
    roles: [],
    permissions: [],
    verkaeufer: {},
    autohaus: {},

    activePartner: null
  },

  mutations: {
    setToken(state, token) {
      state.token = token
    },

    removeToken(state) {
      state.token = null

      apolloClient.resetStore()
    },

    setLoading(state, loading) {
      state.loading = loading
    },

    setUser(state, user) {
      state.user = user
    },

    setRoles(state, roles) {
      state.roles = roles
    },

    setPermissions(state, permissions) {
      state.permissions = permissions
    },

    setVerkaeufer(state, verkaeufer) {
      state.verkaeufer = verkaeufer
    },

    setAutohaus(state, autohaus) {
      state.autohaus = autohaus
    },

    setActivePartner(state, activePartner) {
      state.activePartner = activePartner
    }
  },

  actions: {
    login({ commit, state, dispatch }, { username, password, type, client }) {
      return new Promise((resolve, reject) => {
        let user = null
        AuthApi.login({ username, password, type, client })
          .then((res) => {
            commit('setLoading', true)

            commit('setToken', res.access_token)

            // Start loading theme and not wait for the result (if there is an error, we will still display everything in the default theme)
            //dispatch('theme/load', null, { root: true })

            if (res.external) {
              // login in external-portal
              user = {
                token: res.external.token,
                parnummer: res.external.parnummer,
                fullname: res.external.name
              }
            }

            ServiceworkerUtils.registerPush()

            return dispatch('loadUser')
          })
          .then(() => {
            commit('setLoading', false)

            // merge user data from api with the user data from external (external only)
            if (user) {
              user = Object.assign({}, user, state.user)
              commit('setUser', user)
            }

            resolve(true)
          })
          .catch((err) => {
            // Google Analytics
            if (err.status !== 401) {
              // Only log error if it isn't a simple "wrong password or username" error
              ExceptionUtils.logToAnalytics(err)
            }

            commit('removeToken')
            reject(err)
          })
      })
    },

    logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        ServiceworkerUtils.deregisterPush()
          .then(() => {
            return AuthApi.logout()
          })
          .then(() => {
            commit('removeToken')

            commit('setUser', {})
            commit('setRoles', [])
            commit('setPermissions', [])
            commit('setVerkaeufer', {})
            commit('setAutohaus', {})
            commit('setActivePartner', null)

            dispatch('theme/load', null, { root: true })

            resolve(true)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('removeToken')

            commit('setUser', {})
            commit('setRoles', [])
            commit('setPermissions', [])
            commit('setVerkaeufer', {})
            commit('setAutohaus', {})
            commit('setActivePartner', null)

            dispatch('theme/load', null, { root: true })

            reject(err)
          })
      })
    },

    loadUser({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const user = UserApi.loadUser()
        const roles = UserApi.loadRoles()
        const permissions = UserApi.loadPermissions()
        const verkaeufer = UserApi.loadVerkaeufer()
        const autohaus = UserApi.loadAutohaus()

        Promise.all([user, roles, permissions, verkaeufer, autohaus])
          .then((values) => {
            commit('setUser', values[0])
            commit('setRoles', values[1])
            commit('setPermissions', values[2])
            commit('setVerkaeufer', values[3])
            commit('setAutohaus', values[4])

            // carsharing
            if (
              values[0]?.email.endsWith('bgv-carsharing@freshforfinance.de')
            ) {
              commit('setActivePartner', values[0]?.id + '') // partner from user
            } else if (values[4]?.id) {
              commit('setActivePartner', values[4]?.id + '') // preferred active partner (autohaus = carsharer)
            } else {
              commit('setActivePartner', null) // no preferred active partner
            }

            dispatch('theme/load', null, { root: true })

            resolve(true)
          })
          .catch((err) => {
            // Google Analytics
            ExceptionUtils.logToAnalytics(err)

            commit('setUser', {})
            commit('setRoles', [])
            commit('setPermissions', [])
            commit('setVerkaeufer', {})
            commit('setAutohaus', {})
            commit('setActivePartner', null)

            dispatch('theme/load', null, { root: true })

            reject(err)
          })
      })
    }
  },

  getters: {
    isLoading: () => false,
    isAuthorized: (state) => !!state.token && !state.loading,
    user: (state, getters) => (getters.isAuthorized ? state.user : null),
    roles: (state, getters) => (getters.isAuthorized ? state.roles : null),
    hasRole: (state, getters) => (role) =>
      getters.isAuthorized ? (state.roles || []).includes(role) : false,
    permissions: (state, getters) =>
      getters.isAuthorized ? state.permissions : null,
    hasPermission: (state, getters) => (permission) =>
      getters.isAuthorized
        ? (state.permissions || []).includes(permission)
        : false,
    hasAllPermissions:
      (state, getters) =>
      (...permissions) => {
        if (!getters.isAuthorized) return false // not authorized
        if ((state.permissions || []).length === 0) return false // no permissions
        if ((permissions || []).length === 0) return false // no permissions to check

        return permissions.every((permission) =>
          getters['hasPermission'](permission)
        )
      },
    hasOnePermission:
      (state, getters) =>
      (...permissions) => {
        if (!getters.isAuthorized) return false // not authorized
        if ((state.permissions || []).length === 0) return false // no permissions
        if ((permissions || []).length === 0) return false // no permissions to check

        return permissions.some((permission) =>
          getters['hasPermission'](permission)
        )
      },
    verkaeufer: (state, getters) =>
      getters.isAuthorized ? state.verkaeufer : null,
    autohaus: (state, getters) =>
      getters.isAuthorized ? state.autohaus : null,
    activePartner: (state, getters) =>
      getters.isAuthorized ? state.activePartner : null
  }
}
