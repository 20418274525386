import axios from 'axios'
import ExceptionUtils from '@/utils/exceptions.js'
import ApiUtils from '@/utils/api.js'

const urlPrefix = ApiUtils.getApiUrl() + '/pwa/push'

export default {
  register({ subscription }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/register', subscription)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  },

  deregister({ endpoint }) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/deregister', { endpoint })
        .then(() => {
          resolve()
        })
        .catch((err) => {
          // Google Analytics
          ExceptionUtils.logToAnalytics(err)

          reject(err)
        })
    })
  }
}
