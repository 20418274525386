<template>
  <edit-userstatus outlined dense hide-details class="ma-2" />
</template>

<script>
import EditUserstatus from '@/components/EditUserstatus'

export default {
  name: 'AppNavigationStatus',

  components: {
    EditUserstatus
  }
}
</script>
