<template>
  <notifications-dialog>
    <template #activator="{ on, totalUnread }">
      <v-list-item dense v-on="on">
        <v-list-item-icon>
          <v-icon>$notifications</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Benachrichtigungen</v-list-item-title>
        </v-list-item-content>

        <v-chip v-if="totalUnread > 0" x-small color="info">
          {{ totalUnread }}
        </v-chip>
      </v-list-item>
    </template>
  </notifications-dialog>
</template>

<script>
import NotificationsDialog from '@/components/notifications/NotificationsDialog'

export default {
  name: 'AppNavigationNotifications',

  components: {
    NotificationsDialog
  }
}
</script>
