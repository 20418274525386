<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon>{{ noteableData.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ noteableData.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ noteableData.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="noteableData.to">
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn icon class="ma-0" :to="noteableData.to" v-on="on">
            <v-icon small>$action-show</v-icon>
          </v-btn>
        </template>
        <span>{{ noteableData.title }} anzeigen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'DataListItemNoteable',

  props: {
    noteable: {
      type: Object,
      required: true
    }
  },

  computed: {
    noteableData() {
      const type = this.noteable.type

      switch (type) {
        case 'App\\Models\\leads\\Lead':
          return {
            icon: '$lead',
            title: 'Lead',
            subtitle: this.noteable.description,
            to: {
              name: 'lead',
              params: { id: '' + this.noteable.id }
            }
          }
        case 'App\\Models\\fahrzeugmarkt\\Customer':
          return {
            icon: '$customer',
            title: 'Kunde',
            subtitle: this.noteable.description,
            to: {
              name: 'customers-customer',
              params: { id: '' + this.noteable.id }
            }
          }
        default:
          return null
      }
    }
  }
}
</script>
