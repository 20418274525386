<template>
  <v-dialog v-if="show" max-width="500">
    <template #activator="{ on }">
      <v-alert
        dense
        :type="severity"
        class="ma-0"
        style="cursor: pointer"
        v-on="on"
      >
        <span v-if="!small">{{ message.title }}</span>
      </v-alert>
    </template>

    <v-card flat>
      <v-alert :type="severity" text class="ma-0">
        <div class="font-weight-bold">{{ message.title }}</div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="message.description" v-html="message.description" />
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'AppEnvironmentInfo',

  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapState(['message']),

    show() {
      return !!(this.message && this.message.title)
    },

    severity() {
      return _.get(this.message, 'severity', 'info') ?? 'info'
    }
  }
}
</script>
